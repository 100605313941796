import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
// import { HttpClient } from '@angular/common/http';
// import { ServiceBase } from './serviceBase';
import { environment } from '../../environments/environment';

export class HelpPage {
  pageId: string;
  url: string;
  title: string;

  constructor(id: string) {
    this.pageId = id;
  }

}

export enum HelpPopupStyle {
  SmallCenter,
  LargeRight
}

export class HelpPageEvent
  extends HelpPage {
  showAsPage: boolean;
  showAsPanel: boolean;
  showAsPopup: boolean;
  popupAnchorElement: HTMLElement;
  popupStyle: HelpPopupStyle;

  constructor(id: string) {
    super(id);
  }
}

import helpIndex from '../../help/__index.json';

//@Directive()
@Injectable({
  providedIn: 'root'
})
export class HelpService {

  private index: Array<HelpPage>;

  @Output()
  page: EventEmitter<HelpPageEvent> = new EventEmitter<HelpPageEvent>();

  constructor() {
    // load the index
    // super(httpClient);

    // const headers = this.getHeaders();

    this.index = <Array<HelpPage>>helpIndex;
    // this.httpClient.get<Array<HelpPage>>(environment.helpUrl + '/__index.json',
    //   {
    //     headers: headers,
    //   }).subscribe(ix => this.index = ix);

  }

  showPage(pageId: string): void {
    const page = this.getPage(pageId);
    if (page) {
      const e = new HelpPageEvent(pageId);
      e.showAsPage = true;
      e.url = environment.helpUrl + '/' + page.url;
      e.title = page.title;
      this.page.emit(e);
    }
  }

  showPanel(pageId: string): void {
    const e = new HelpPageEvent(pageId);
    e.showAsPanel = true;
    this.page.emit(e);
  }

  showPopup(pageId: string, style: HelpPopupStyle, element: HTMLElement): void {
    const page = this.getPage(pageId);
    if (page) {
      const e = new HelpPageEvent(pageId);
      e.showAsPopup = true;
      e.popupStyle = style;
      e.popupAnchorElement = element;
      e.url = environment.helpUrl + '/' + page.url;
      e.title = page.title;
      this.page.emit(e);
    }
  }

  getPage(pageId: string): HelpPage {
    if (this.index) {
      return this.index.find(p => p.pageId === pageId);
    }
    return null;
  }

}
