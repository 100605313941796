import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { interval, Subscription } from 'rxjs';
import { LimitExceededEvent } from '../../models/LimitExceededEvent';
import { LimitsService } from '../../services/limits.service';
import { LimitExceededDialogComponent } from '../limit-exceeded-dialog/limit-exceeded-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-limit-alert',
    templateUrl: './limit-alert.component.html',
    styleUrls: ['./limit-alert.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatIconModule]
})
export class LimitAlertComponent implements OnDestroy {

  shakeSub: Subscription;
  exceededSub: Subscription;
  limitsExceeded = false;

  withShake = false;

  private event: LimitExceededEvent = null;

  constructor(
    private dialog: MatDialog,
    limitsService: LimitsService
  ) {

    this.exceededSub = limitsService.limitsExceeded.subscribe(exc => {
      if (exc) {
        this.limitsExceeded = true;
        this.event = exc;
        this.startShaking();
      }
    });

  }

  ngOnDestroy() {
    this.exceededSub.unsubscribe();
  }


  showLimits() {
    this.limitsExceeded = false;
    this.stopShaking();

    const dlg = this.dialog.open(LimitExceededDialogComponent, {
      data: this.event,
      width: '30%'
    });
  }

  private startShaking() {
    if (!this.shakeSub) {
      this.shakeSub =
        interval(1500)
          .subscribe(() => {
            this.withShake = !this.withShake;
          });
    }
  }

  private stopShaking() {
    if (this.shakeSub) {
      this.shakeSub.remove(this.shakeSub);
      this.shakeSub.unsubscribe();
      this.shakeSub = null;
    }
  }

}
