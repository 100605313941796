import { FormattedExpression } from '../server/FormattedExpression';
import { IShape } from '../server/Shapes/Shape';

// container for shapes on the clipboard

export class ShapePacket {
    shapes: Array<IShape>;
    variables: Array<FormattedExpression>;

    raw: string;

    image: File;

    constructor(sp: ShapePacket) {
        if (sp) {
            this.shapes = sp.shapes;
            this.image = sp.image;
            this.raw = sp.raw;
            this.variables = sp.variables?.map(v => new FormattedExpression(v));
        }
    }
}
