export class Limit {
    public frequency: string;
    public value: number;

    constructor(l: Limit = null) {
        if (l) {
            this.frequency = l.frequency;
            this.value = l.value;
        }
    } 
}
