import Guid from '../../../shared/guid';
import { BorderStyle } from '../BorderStyle';
import { Extent, Point } from '../Dimensions';
import { FillStyle } from '../FillStyle';
import { FormattedExpression } from '../FormattedExpression';
import { PropertyExpression } from '../PropertyExpression';
import { TextBlock } from '../TextBlock';

export interface IShape {

  origin: Point;
  extent: Extent;
  shapeName: string;
  tag: string;
  id: string;

  border: BorderStyle;
  doNotPrint: boolean;
  expressions: Array<PropertyExpression>;
  fill: FillStyle;
  keepProportional: boolean;
  rotation: number;
  text: TextBlock;
  zOrder: number;
  scale: number; // mainly for barcode scaling to correct sizes, eg: 100%.. but could be used by other shapes..
  scaleShapeHeightMM: number;
  scaleShapeWidthMM: number;
  anchorPoint: string;
  isNaturallyVisible(): boolean;
  updateWithVar(variable: FormattedExpression): void;
  isTightlyBoundShape(): boolean;
}

export class ShapeBase {
  public shapeName: string;
  public id: string;
  public extent: Extent;
  public origin: Point;
  public rotation: number = 0;
  public tag: string;
  public zOrder: number = 0;
  public anchorPoint: string;

  constructor(shape?: ShapeBase) {

    this.shapeName = 'Shape';
    this.id = Guid.create().toString();

    if (shape) {
      this.id = shape.id || this.id;
      this.rotation = shape.rotation;
      this.shapeName = shape.shapeName;
      this.tag = shape.tag;
      this.zOrder = shape.zOrder;
      this.anchorPoint = shape.anchorPoint;
      if (shape.origin) {
        this.origin = new Point(shape.origin.x, shape.origin.y);
      }

      if (shape.extent) {
        this.extent = new Extent(shape.extent.width, shape.extent.height);
      }

    }
  }
}

export class Shape<STYLETYPE> extends ShapeBase implements IShape {

  public style: STYLETYPE;

  // property implementers of IShape
  public border: BorderStyle;
  public doNotPrint: boolean;
  public expressions: Array<PropertyExpression>;
  public fill: FillStyle;
  public keepProportional: boolean;
  public text: TextBlock;
  public scale: number; // mainly for barcode scaling to correct sizeseg: 100%.. but could be used by other shapes..
  public scaleShapeHeightMM: number;
  public scaleShapeWidthMM: number;
  // end of IShape property implementers

  constructor(shape?: Shape<STYLETYPE>) {
    super(shape);

    if (shape) {

      this.style = shape.style; // gets set in the children

      if (shape.expressions) {
        this.expressions = new Array<PropertyExpression>();
        shape.expressions.forEach(x => this.expressions.push(new PropertyExpression(x)));
      }

      if (shape.border) {
        this.border = new BorderStyle(shape.border);
      }
      if (shape.fill) {
        this.fill = new FillStyle(shape.fill.color);
      }

      // all objects now have a textblock.. even pictures and geo shapes.. just because...
      // then colours, fonts, etc are stored in common place instead of some on shape and some in textformat.
      this.text = new TextBlock(shape.text);

      this.doNotPrint = shape.doNotPrint;
      this.keepProportional = shape.keepProportional;
  
      //  this.data = shape.data;   BARCODEDATA
      //   this.font = shape.font;
      //   this.fontSize = shape.fontSize;
      this.scale = shape.scale;
      this.anchorPoint = shape.anchorPoint;
      this.scaleShapeHeightMM = shape.scaleShapeHeightMM;
      this.scaleShapeWidthMM = shape.scaleShapeWidthMM;

    } else {
      this.fill = new FillStyle('rgba(0,0,0,0)');
      this.border = new BorderStyle();
      this.border.lineWidth = 0.5;
      this.border.color = '';
      this.border.cornerRadius = 0;
      this.keepProportional = false;
      //      this.fontSize = 'auto';
      //      this.textColor = 'rgba(0,0,0,1)';
      this.rotation = 0;
      this.zOrder = 0;
      this.anchorPoint = "topleft";
      this.scale = 100;
      this.scaleShapeHeightMM = 0;
      this.scaleShapeHeightMM = 0;
    }

  }

  updateWithVar(variable: FormattedExpression): void {
    if (!this.text) {
      this.text = new TextBlock();
    }
    this.text.content = `[[${variable.name}:${variable.name}]]`;
  };

  isTightlyBoundShape(): boolean {
    return true;
  };


  isNaturallyVisible(): boolean {
    return (this.border?.isVisible()) ||
      (this.text != null && this.text.content.trim().length > 0);
  }

}

