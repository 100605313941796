import { FormattedExpression } from '../FormattedExpression';
import { PropertyExpression } from '../PropertyExpression';
import { Shape } from './Shape';

// KEEP THIS IN SYNC WITH THE BACK END
export enum BARCODE_STYLE {
  EAN128 = 1,
  EAN13 = 2,
  EAN8 = 3,
  Code128Auto = 4,
  Code128A = 5,
  Code128B = 6,
  Code128C = 7,
  Code2of5 = 8,
  Code2of5I = 9,
  Code39 = 10,
  UPCA = 11,
  UPCE = 12,
  Codabar = 13,
  Code93 = 14,
  Code3of9 = 15,
  MSI = 16,
  Code11 = 17,
  CodeB = 18,
  Telepen = 19,
  PostNet = 20,
  RoyalMail = 21,
  PDF417 = 22,
  EAN13TU = 23,            // place holders for future growth
  EAN13TUC = 24,           // place holders for future growth
  EAN13TUCA = 25,          // place holders for future growth
  Code128CEx = 26,
  JAN13 = 27,
  JAN8 = 28,
  Code2of5IB = 29,
  SSCC = 30,
  Code39Mod10 = 31,
  Code128Mod10 = 32,
  DYNIX14 = 33,
  DATAMATRIX = 34,
  QRCODE = 35,
  AUTO = 100,  // means style can change
}

export class EAN128AIValue {
  code: string;
  value: string;
  variable: string;

  constructor(ai: EAN128AIValue) {
    if (ai) {
      Object.assign(this, ai);
    }
  }
}

export class BarcodeShape extends Shape<BARCODE_STYLE> {

  static ShapeType = 'barcode';

  readable: boolean;
  checksum: boolean;
  aiList: Array<EAN128AIValue>;

  constructor(barcode?: BarcodeShape) {
    super(barcode);
    this.shapeName = 'Barcode';
    this.readable = true;
    this.checksum = true;
    this.style = BARCODE_STYLE.EAN128;
    this.keepProportional = false;
    this.aiList = new Array<EAN128AIValue>();

    if (barcode) {
      this.readable = barcode.readable;
      this.checksum = barcode.checksum;
      this.style = barcode.style;
      if (barcode.aiList) {
        this.aiList = barcode.aiList.map(ai => new EAN128AIValue(ai));
      }
      this.keepProportional = barcode.keepProportional;

      // convert old data value to text and lost data
      if((barcode as any).data) {
          this.text.content = (barcode as any).data;
      }

      if(!this.text.content) {
        this.text.content = '123456789';
      }
    }
  }

  updateWithVar(variable: FormattedExpression): void {

    let pex = this.expressions.find(fex => fex.propertyName === 'text');

    if (!pex) {
      pex = new PropertyExpression();
      pex.propertyName = 'text';
      this.expressions.push(pex);
    }
    pex.variableName = variable.name;
  };
}
