import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog, DialogPosition } from '@angular/material/dialog';
import { HelpService, HelpPageEvent, HelpPopupStyle } from '../../services/help.service';
import { Subscription } from 'rxjs';
import { HelpPopupComponent } from '../help-popup/help-popup.component';

@Component({
    selector: 'app-help-panel',
    templateUrl: './help-panel.component.html',
    standalone: true
})
export class HelpPanelComponent implements OnInit, OnDestroy {

  sub: Subscription;

  constructor(
    private helpService: HelpService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.sub = this.helpService.page.subscribe(e => this.showHelp(e));
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  showHelp(e: HelpPageEvent) {
    if (e.showAsPage) {
      window.open(e.url, '_blank');
    }
    if (e.showAsPopup) {
      const w = 440;
      const ws = `${w}px`;
      const hs = `600px`;
      let pos: DialogPosition = null;
      if (e.popupStyle === HelpPopupStyle.LargeRight) {
        pos = { right: '2%' };
      }

      this.dialog.open(HelpPopupComponent,
        {
          panelClass: 'helpDlg',
          position: pos,
          width: ws,
          height: hs,
          data: e
        });
    }
  }
}

