import { TextFormat } from './TextFormat';

export class TextBlock {
    content: string;
    format: TextFormat;

    constructor(textBlock?: TextBlock) {
        if (textBlock) {
            this.content = textBlock.content;
            this.format = new TextFormat(textBlock.format);
        } else {
            this.format = new TextFormat();
            this.content = '';
        }
    }
}
