export class OrganisationFont {
    public family: string;
    public category: string;

    constructor(webFont?: any) {
        if (webFont) {
            this.family = webFont.family;
            this.category = webFont.category;
        }
    }
}
