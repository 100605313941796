import { HttpClient, HttpHeaders } from '@angular/common/http';

export class ServiceBase {
    constructor(
        protected httpClient: HttpClient) { }

    protected getHeaders(contentType?: string): HttpHeaders {
        contentType =  contentType || 'application/json';

        const headers = new HttpHeaders()
            .set('Content-Type', contentType)
            .set('Accept', 'application/json');
        return headers;
    }
}
