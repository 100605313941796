import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Cacheable, CacheBuster} from 'ts-cacheable';

import {ServiceBase} from './serviceBase';
import {environment} from '../../environments/environment';

import {User} from '../models/server/User';
import {UserInvite} from '../models/server/UserInvite';
import {PagedResponse} from '../models/server/PagedResponse';
import {Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {of, Subject} from 'rxjs';

const invitesSubject = new Subject<void>();
const usersSubject = new Subject<void>();

@Injectable({
  providedIn: 'root'
})
export class UserService extends ServiceBase {

  constructor(
    httpClient: HttpClient) {
    super(httpClient);
  }

  @CacheBuster({
    cacheBusterNotifier: usersSubject
  })
  resetCache(): Observable<null> {
    return of(null);
  }


  @Cacheable({
    cacheBusterObserver: usersSubject
  })
  getUser(): Observable<User> {
    const headers = this.getHeaders();
    return this.httpClient.get<User>(environment.userApiUrl,
      {
        headers: headers
      }).pipe(
      map(u => u ? Object.assign(new User(), u) : null)
    );
  }

  @Cacheable({
    cacheBusterObserver: usersSubject
  })
  getUsers(page: number, pageSize: number): Observable<PagedResponse<User>> {
    const headers = this.getHeaders();
    const parms = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());

    return this.httpClient.get<PagedResponse<User>>(environment.userApiUrl + '/list',
      {
        headers: headers,
        params: parms
      }).pipe(
      map(users => new PagedResponse<User>(users, User))
    );
  }

  @CacheBuster({
    cacheBusterNotifier: usersSubject
  })
  updateUser(user: User): Observable<User> {
    const headers = this.getHeaders();
    return this.httpClient.post<User>(environment.userApiUrl,
      user,
      {
        headers: headers
      }).pipe(
      map(u => u ? Object.assign(new User(), u) : null)
    );
  }

  @CacheBuster({
    cacheBusterNotifier: usersSubject
  })
  deleteUser(id: string): Observable<boolean> {
    const headers = this.getHeaders();
    return this.httpClient.delete<boolean>(`${environment.userApiUrl}/${id}`,
      {
        headers: headers
      });
  }


  @Cacheable({
    cacheBusterObserver: invitesSubject
  })
  getInvites(page: number, pageSize: number): Observable<PagedResponse<UserInvite>> {
    const headers = this.getHeaders();
    const parms = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());

    return this.httpClient.get<PagedResponse<UserInvite>>(environment.userApiUrl + '/invite/list',
      {
        headers: headers,
        params: parms
      }).pipe(
      map(users => new PagedResponse<UserInvite>(users, UserInvite))
    );
  }

  checkForInvite(): Observable<UserInvite> {
    const headers = this.getHeaders();

    return this.httpClient.get<UserInvite>(environment.userApiUrl + '/invite/check',
      {
        headers: headers
      }).pipe(
      map(u => u ? new UserInvite(u) : null)
    );
  }

  @CacheBuster({
    cacheBusterNotifier: invitesSubject
  })
  sendInvite(invite: UserInvite): Observable<UserInvite> {
    const headers = this.getHeaders();
    return this.httpClient.post<UserInvite>(environment.userApiUrl + '/invite',
      invite,
      {
        headers: headers
      }).pipe(
      map(u => u ? new UserInvite(u) : null)
    );
  }

  @CacheBuster({
    cacheBusterNotifier: invitesSubject
  })
  acceptInvite(code: string): Observable<User> {
    const headers = this.getHeaders();

    return this.httpClient.post<User>(
      environment.userApiUrl + `/invite/accept/${code}`,
      null,
      {
        headers: headers,
        params: null
      }).pipe(
      map(u => u ? new User(u) : null)
    );
  }

  @CacheBuster({
    cacheBusterNotifier: invitesSubject
  })
  deleteInvite(code: string): Observable<boolean> {
    const headers = this.getHeaders();
    const parms = new HttpParams()
      .set('id', code);

    return this.httpClient.delete<boolean>(environment.userApiUrl + `/invite/${code}`,
      {
        headers: headers,
      });
  }

  @Cacheable({
    cacheBusterObserver: usersSubject
  })
  getUserPolicies(): Observable<string[]> {
    const headers = this.getHeaders();

    return this.httpClient.get<string[]>(environment.userApiUrl + '/policies',
      {
        headers: headers
      });
  }

  @CacheBuster({
    cacheBusterNotifier: usersSubject
  })
  setUserRole(id: string, role: string): Observable<User> {
    const headers = this.getHeaders();

    const request = {ID: id, Role: role};

    return this.httpClient.post<User>(environment.userApiUrl + '/setrole',
      request,
      {
        headers: headers
      }).pipe(
      map(u => u ? Object.assign(new User(), u) : null)
    );
  }
}
