import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { WarningPanelComponent } from '../../comps/warning-panel/warning-panel.component';
import { SimpleProgressComponent } from '../../comps/simpleprogress.component';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { FlexModule } from '@angular/flex-layout/flex';
import { BGAnimationComponent } from '../../comps/bganimation/bganimation.component';
@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    standalone: true,
    imports: [
        BGAnimationComponent,
        FlexModule,
        FormsModule,
        ReactiveFormsModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        RouterLink,
        SimpleProgressComponent,
        WarningPanelComponent,
    ],
})
export class ResetPasswordComponent {

  form: UntypedFormGroup;
  error: string;
  public busy = false;
  message: string = null;
  resetDone = false;

  private email: string;
  private code: string;

  constructor(
    private fb: UntypedFormBuilder,
    route: ActivatedRoute,
    // private changeDetectorRef: ChangeDetectorRef,
    private authService: AuthenticationService) {

    this.createForm();

    route.queryParamMap.subscribe(
      params => {
        if (params.get('user')) {
          this.email = params.get('user');
          this.form.controls.email.setValue(this.email);
        }
        if (params.get('ccode')) {
          this.code = params.get('ccode');
          this.form.controls.code.setValue(this.code);
        }
      });
  }

  async getcode() {

    this.error = '';
    this.message = null;
    this.busy = true;

    // this.changeDetectorRef.detectChanges();

    if (this.form.controls.email.valid) {

      try {
        await this.authService.forgotPassword(
          this.form.controls.email.value
        ).then(result => {

          if (result) {
            this.message = 'Reset code was sent via email';
          }

        }).catch(err => {
          this.error = err.message;

          if (err.code === 'UserNotFoundException') {
            this.error = 'Email, Code or Password are invalid.';
          }
        });

      } finally {
        this.busy = false;
      }
    }
  }

  async reset() {
    this.error = '';
    this.busy = false;
    this.message = null;

    if (this.form.valid) {
      this.busy = true;

      try {
        await this.authService.forgotPasswordUpdate(
          this.form.controls.email.value,
          this.form.controls.code.value,
          this.form.controls.password.value
        ).then(result => {

          if (result) {
            this.resetDone = true;
            this.message = 'Password Reset OK. Try to Login.';
          }

        }).catch(err => {
          this.error = 'Invalid email or code entered. Check the input or get a new code.'; //err.message;
        });

      } finally {
        this.busy = false;
      }
    }
  }

  private createForm(): void {
    this.form = this.fb.group({
      email: new UntypedFormControl(this.email || '', Validators.required),
      code: new UntypedFormControl(this.code || '', Validators.required),
      password: new UntypedFormControl('', Validators.required),
      confirmpassword: new UntypedFormControl('', Validators.required)
    })
  }

}
