import { Component } from '@angular/core';
import { MatDialogRef, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import * as v from '../version';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { DialogTitleComponent } from './comps/dialog-title/dialog-title.component';

@Component({
    styles: [],
    template: `
  <app-dialog-title [title]="title" icon="info"></app-dialog-title>
  <div mat-dialog-content>
      barxui is created and maintained by HEXCOM Ltd.
  </div>
  <div mat-dialog-actions align="end">
    <button type="button" mat-raised-button color="primary" (click)="goAhead()"><mat-icon>done</mat-icon> OK</button>
  </div>
  `,
    standalone: true,
    imports: [DialogTitleComponent, MatDialogContent, MatDialogActions, MatButtonModule, MatIconModule]
})
export class AboutDialogComponent {
  title = 'About barxui';
  versionString: string;

  constructor(
    private dialogRef: MatDialogRef<AboutDialogComponent>) {

    this.dialogRef?.keydownEvents().subscribe(event => {
      if (event.key === "Escape") {
        this.dialogRef.close();
      }
    });

    this.title = `About barxui v ${v.version.version}`;
  }

  goAhead() {
    this.dialogRef.close(null);
  }
}
