import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { TitleCasePipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { DialogTitleComponent } from '../../../../comps/dialog-title/dialog-title.component';

export interface IDeleteDataSourceOptions {
  sourceName: string;
  type: 'table' | 'query';
}

export interface IDeleteDataSourceConfirmation {
  shouldDelete?: boolean;
}

@Component({
    selector: 'app-delete-data-source',
    templateUrl: './delete-data-source.component.html',
    styleUrls: ['./delete-data-source.component.scss'],
    standalone: true,
    imports: [DialogTitleComponent, MatDialogContent, MatDialogActions, MatButtonModule, MatDialogClose, MatIconModule, TitleCasePipe]
})
export class DeleteDataSourceDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<DeleteDataSourceDialogComponent, IDeleteDataSourceConfirmation>,
    @Inject(MAT_DIALOG_DATA) public data: IDeleteDataSourceOptions) {
    this.dialogRef?.keydownEvents().subscribe(event => {
      if (event.key === "Escape") {
        this.dialogRef.close();
      }
    });
  }

  confirmDelete() {
    this.dialogRef.close({ shouldDelete: true });
  }

}
