import { Injectable, ErrorHandler } from '@angular/core';
import { Problem, ValidationProblem } from '../models/server/Problem';
import { SessionExpiredDialogComponent } from '../comps/session-expired-dialog/session-expired-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from '../authentication/authentication.service';
import { NGXLogger } from 'ngx-logger';
import { Subject } from 'rxjs';

export enum ErrorType {
  NotFound,
  Warning,
  ServerError,
  ClientError,
  AuthenticationExpired,
  Validation,
  LabelNotFound,
  LabelCorrupt,
  OrgLockedForMigration
}

export class ErrorData {
  type: ErrorType;
  message: string;
  errorSource: any;
  problem: Problem;

  constructor(
    type?: ErrorType,
    message?: string,
    errorSource?: any) {

    this.type = type;
    this.message = message;
    if (errorSource instanceof Problem) {
      this.problem = errorSource;
    } else {
      this.errorSource = errorSource;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {

  error = new Subject<ErrorData>();

  constructor(
    private authService: AuthenticationService,
    private ngxLogger: NGXLogger,
    private dialog: MatDialog) { }

  private notAuthenticated(): void {
    // const ed = new ErrorData();
    // ed.type = ErrorType.AuthenticationExpired;
    // ed.message = 'Your login has expired. Login again.';
    // this.error.emit(ed);

    this.dialog.open(SessionExpiredDialogComponent, {
      disableClose: true,
      role: 'alertdialog',
      data: () => {
        this.ngxLogger.debug('Session Expired Logout');
        this.authService.logout();
      }
    });
  }

  async handleError(errorSource: any): Promise<ErrorData> {
    return this.handleErr(errorSource, 'System', true);
  }

  async handleErr(
    errorSource: any,
    context?: string,
    dontPublish?: boolean): Promise<ErrorData> {


    let dontLog = false;
    const ed = new ErrorData();

    if (typeof (errorSource) !== 'undefined' && !!errorSource) {

      context = context || 'object';

      // is this an http error?
      if (errorSource.name === 'HttpErrorResponse') {

        // try to get the body message - should be a Problem
        let problem: Problem = null;
        if (errorSource?.error?.text) {

          let text = await errorSource.error.text();
          if (text) {

            problem = new Problem(JSON.parse(text));

            ed.type = ErrorType.ServerError;
            ed.problem = problem;
            ed.message = problem.detail;
          }
        }

        if (!problem) {
          switch (errorSource.status) {
            case 0: // connection failed most likely
              ed.type = ErrorType.ServerError;
              ed.errorSource = errorSource.error;
              ed.message = 'Could not connect to server.';
              break;
            case 400: // bad request
              ed.type = ErrorType.ClientError;
              ed.errorSource = errorSource.error;
              ed.message = 'Your request failed: ' + errorSource.message;
              break;
            case 401: // auth
              this.notAuthenticated();
              return;
            // ed.type = ErrorType.AuthenticationExpired;
            // ed.errorSource = errorSource.error;
            // ed.message = 'Your login has expired. Login again.';
            // break;
            case 403: //  auth
              ed.type = ErrorType.AuthenticationExpired;
              ed.errorSource = errorSource.error;
              ed.message = 'You are not logged in.';
              break;
            case 404: // not found
              ed.type = ErrorType.NotFound;
              ed.errorSource = errorSource.error;
              ed.message = `The ${context} was not found`;
              dontLog = true;
              break;
            case 422: // validation error - datasource is modelstate from asp.net
              ed.type = ErrorType.Validation;
              ed.problem = errorSource.error;
              if (errorSource?.error.errors) {
                ed.problem = <ValidationProblem>errorSource.error.errors;
              }
              ed.message = 'A validation error occured';
              break;
            case 423: // locked for migration
              ed.type = ErrorType.OrgLockedForMigration;
              ed.problem = null;
              ed.message = 'Your organisation is temporarily locked for maintenance.  Refresh to try again later.';
              dontPublish = false;
              break;

            case 500: // internal server error
              ed.type = ErrorType.ServerError;
              if (errorSource.error) {
                ed.message = 'Oh. Something unexpected happened at the back end.  Please try again later.';
                // ed.message = `${errorSource.error.Title} : ${errorSource.error.Detail}` ;
                ed.errorSource = errorSource.error;
              } else {
                ed.message = 'An unknown fatal error occurred';
              }
              break;

            default:
              ed.type = ErrorType.Warning;
              ed.message = 'oh dear';
              ed.errorSource = '';
              break;
          }

          if (ed?.errorSource?.errors) {
            if (ed.errorSource.errors instanceof Array) {
              ed.message = ed.errorSource.errors[0];
            }
          }
        }
      } else if (errorSource instanceof Error) {
        ed.message = 'An unhandled error occured: ' + errorSource.message;
        ed.type = ErrorType.ClientError;
        ed.errorSource = errorSource;

        if ((<any>errorSource).code === -100) { // ExpressionChangedAfterItHasBeenCheckedError
          dontPublish = true;
          dontLog = true;
        }
      }

      if (!dontLog) {
        this.ngxLogger.error(ed);
      }

      if (!dontPublish) {
        this.error.next(ed);
      }
    } else {
      this.ngxLogger.error("Unknown system error!");
    }

    return ed;
  }
}
