<style>
  .main {
  margin-top: 10em;
}
</style>
<app-bganimation tagName="body" className="warehouse"></app-bganimation>
<div class="main wider" fxLayout="row" fxLayoutAlign="center center">
  <form [formGroup]="form" (ngSubmit)="login()">
    <mat-card >
      <mat-card-header>
        <mat-card-title>Sign In</mat-card-title>
        <mat-card-subtitle>Sign In to barxui using an existing sign up.</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="1em">
        </div>
        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="1em">
          <div fxLayout="column" fxLayoutAlign="start stretch">
            <mat-form-field class="wide">
              <mat-label>Email Address</mat-label>
              <input data-cy="email-input" type="email" matInput formControlName="email" required placeholder="Email">
              @if (form.get('email').hasError('email')) {
                <mat-error>Required</mat-error>
              }
              @if (form.get('email').hasError('required')) {
                <mat-error>Required</mat-error>
              }
            </mat-form-field>
            <mat-form-field class="wide">
              <mat-label>Password</mat-label>
              <input data-cy="password-input" type="password" matInput formControlName="password" placeholder="Password"
                required />
              @if (form?.controls.password.invalid && (form.dirty || form?.controls.password.touched)) {
                <div>
                  @if (form?.controls.password.errors.required) {
                    <mat-error> Password is required. </mat-error>
                  }
                </div>
              }
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <div fxLayout="column" fxLayoutGap="0.5em">
          <div>
            @if (busy) {
              <simple-progress message='Logging In'></simple-progress>
            } @else {
              <button data-cy="submit-button" type="submit" mat-raised-button color="primary"
              [disabled]="!form.valid">Sign In</button>
              <button data-cy="forgot-button" mat-stroked-button [routerLink]="'/resetpass'">Forgot Password</button>
              <!-- <button mat-stroked-button [routerLink]="'/signup'">Sign Up</button> -->
            }
            <ng-template #controls>
              <button data-cy="submit-button" type="submit" mat-raised-button color="primary"
              [disabled]="!form.valid">Sign In</button>
              <button data-cy="forgot-button" mat-stroked-button [routerLink]="'/resetpass'">Forgot Password</button>
              <!-- <button mat-stroked-button [routerLink]="'/signup'">Sign Up</button> -->
            </ng-template>
          </div>
          <div>
            @if (!!error) {
              <app-warning-panel [error]='true'>{{error}}</app-warning-panel>
            }
            @if (showConfirm) {
              <a (click)="confirm()">Confirm Sign Up</a>
            }
          </div>
        </div>
      </mat-card-actions>
    </mat-card>
  </form>
</div>
