import { Action } from './Action';
import { Resource } from './Resource';

export class Feature {
    public featureCode: string;
    public actions: Array<Action>;
    public resources: Array<Resource>;

    constructor(f: Feature = null) {
        if (f) {
            this.featureCode = f.featureCode;
            this.actions = [...f.actions];
            this.resources = [...f.resources];

            for (let r = 0; r < this.resources.length; r++) {
                this.resources[r] = new Resource(f.resources[r]);
            }
            for (let a = 0; a < this.actions.length; a++) {
                this.actions[a] = new Action(f.actions[a]);
            }
        }
    }
}
