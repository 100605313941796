import { Extent, Boundary } from './Dimensions';

// font weight type should match back end
//                         400       700      800         900        300         200
export type FontWeight = 'normal' | 'bold' | 'bolder' | 'boldest' | 'lighter' | 'lightest';

export class TextFormat {
  public color: string;
  public backgroundcolor: string;
  public font: string;
  public size: number;

  public extent: Extent;
  public padding: Boundary;

  public verticalAlignment: string;
  public horizontalAlignment: string;

  public removeBlankLines: boolean;
  public italic: boolean;
  public underline: boolean;
  public strikethrough: boolean;
  public fontWeight: FontWeight;
  public inverse: boolean;
  public superscript: boolean;
  public subscript: boolean;
  public hidden: boolean;
  public lineHeight: number;
  public charSpacing: number;
  public justify: string;
  public wordWrap: boolean;
  public wordWrapLength: number;
  public expandToFit: boolean;
  public shrinkToFit: boolean;

  constructor(text?: TextFormat) {
    if (text) {
      this.color = text.color;
      this.backgroundcolor = text.backgroundcolor;
      this.font = text.font;
      this.size = text.size;
      //   this.weight = text.weight;
      this.extent = text.extent;
      this.padding = new Boundary(text.padding);

      this.verticalAlignment = text.verticalAlignment;
      this.horizontalAlignment = text.horizontalAlignment;

      // convert old bold to font weight
      let anyFormat = <any>text;
      if (!anyFormat.fontWeight) {

        if (anyFormat.bold) {
          this.fontWeight = 'boldest';

        } else {
          this.fontWeight = 'normal';
        }
      } else {
        this.fontWeight = text.fontWeight;
      }

      this.justify = text.justify;
      this.wordWrap = text.wordWrap;
      this.wordWrapLength = text.wordWrapLength;
      this.expandToFit = text.expandToFit;
      this.shrinkToFit = text.shrinkToFit;

      this.removeBlankLines = text.removeBlankLines;
      this.italic = text.italic;
      this.underline = text.underline;
      this.strikethrough = text.strikethrough;
      this.superscript = text.superscript;
      this.subscript = text.subscript;
      this.hidden = text.hidden;

      this.inverse = text.inverse;
      this.lineHeight = text.lineHeight;
      this.charSpacing = text.charSpacing;
    } else {
      this.padding = new Boundary();
      //   this.weight = 'n';
      this.size = 12;
      this.wordWrapLength = 99;
      this.justify = 'left';
      this.horizontalAlignment = 'left';
      this.verticalAlignment = 'top';
      this.color = '#000000';
      this.lineHeight = 1; // 100%
      this.charSpacing = 1; // 100%
    }
  }

  getStyles(): string[] {
    const styles: Array<string> = [];
    if (this.underline) {
      styles.push('under');
    }
    if (this.italic) {
      styles.push('italic');
    }
    if (this.strikethrough) {
      styles.push('strike');
    }
    styles.push(this.fontWeight);

    if (this.inverse) {
      styles.push('inverse');
    }

    if (this.superscript) {
      styles.push('superscript');
    }

    if (this.subscript) {
      styles.push('subscript');
    }

    if (this.hidden) {
      styles.push('hidden');
    }

    return styles;
  }

  setStyles(styles: string[]) {
    if (styles) {
      this.underline = styles.findIndex(s => s === 'under') >= 0;
      this.italic = styles.findIndex(s => s === 'italic') >= 0;
      this.strikethrough = styles.findIndex(s => s === 'strike') >= 0;
      this.fontWeight = styles.findIndex(s => s === 'bold') >= 0 ? 'bold' : styles.findIndex(s => s === 'boldest') >= 0 ? 'boldest' : 'normal';
      this.inverse = styles.findIndex(s => s === 'inverse') >= 0;
      this.superscript = styles.findIndex(s => s === 'superscript') >= 0;
      this.subscript = styles.findIndex(s => s === 'subscript') >= 0;
      this.hidden = styles.findIndex(s => s === 'hidden') >= 0;
    }
  }
}
