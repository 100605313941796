import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import {interval, of, Subscription} from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';

import { UserInvite } from '../../models/server/UserInvite';
import { UserService } from '../../services/user.service';
import { InvitecheckDialogComponent } from './invitecheck.dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-invitecheck',
    templateUrl: './invitecheck.component.html',
    standalone: true,
    imports: [MatButtonModule, MatIconModule]
})
export class InvitecheckComponent
  implements OnInit, OnDestroy {

  inviteSub: Subscription;
  shakeSub: Subscription;

  withShake = false;

  @Input()
  pollingInterval = 300000;

  get invite() { return this._invite; }
  set invite(val: UserInvite) {
    if (this._invite !== val) {
      this._invite = val;
      if (val) {
        this.startShaking();
      } else {
        this.stopShaking();

      }
    }
  }

  private _invite: UserInvite;

  constructor(
    private dialog: MatDialog,
    private userService: UserService) { }

  ngOnInit() {
    this.inviteSub =
      interval(this.pollingInterval)
        .pipe(
          startWith(0),
          switchMap(() => this.userService.checkForInvite())

          // for testing
          // switchMap(() => of(new UserInvite(
          //   {
          //     email: 'peter',
          //     code: '123',
          //     message: ' Hello',
          //     role: 'Admin',
          //     sent: new Date(),
          //     accepted: null,
          //     organisationID: 'abc123',
          //     organisationName: 'Test Org'
          //   })))

        )
        .subscribe(res => this.invite = res);
  }

  ngOnDestroy(): void {
    if (this.inviteSub) {
      this.inviteSub.unsubscribe();
      this.inviteSub = null;
    }
  }

  showInvite(): void {
    const dlg = this.dialog.open(InvitecheckDialogComponent, {
      data: this.invite
    });

    dlg.afterClosed().subscribe((resp: string) => {
      // clear the current invite if accepted or declined
      if (resp) {
        this.invite = null;
      }
    });
  }

  private startShaking() {
    if (!this.shakeSub) {
      this.shakeSub =
        interval(1500)
          .subscribe(() => {
            this.withShake = !this.withShake;
          });
    }
  }

  private stopShaking() {
    if (this.shakeSub) {
      this.shakeSub.remove(this.shakeSub);
      this.shakeSub.unsubscribe();
      this.shakeSub = null;
    }
  }
}
