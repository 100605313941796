<style>
  .main {
  margin-top: 10em;
}
</style>
<app-bganimation tagName="body" className="warehouse"></app-bganimation>
<div class="main wider" fxLayout="row" fxLayoutAlign="center center">
  <form [formGroup]="form" (ngSubmit)="register()">
    <mat-card >
      <mat-card-header>
        <mat-card-title>Sign Up</mat-card-title>
        @if (!invited) {
          <mat-card-subtitle>Sign Up for a free trial of barxui.</mat-card-subtitle>
        }
      </mat-card-header>
      <mat-card-content>
        @if (registered) {
          <div>
            <p>Check your email to confirm your registeration.</p>
          </div>
        } @else {
          <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="1em">
            <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="1em">
              @if (invited) {
                <div>
                  Enter a password to create your login and accept the invitation to {{orgName}}.
                </div>
              }
              <!-- <mat-form-field>
              <mat-label>Name</mat-label>
              <input type="text" matInput formControlName="name" required placeholder="Name">
              <mat-error>A name is required and it must have from 3 to 200 characters.</mat-error>
            </mat-form-field> -->
            <mat-form-field class="wide">
              <mat-label>Email</mat-label>
              <input data-cy="email" type="email" matInput formControlName="email" required maxlength="100">
              @if (form.get('email').hasError('required')) {
                <mat-error>An email address is required.</mat-error>
              }
              @if (form.get('email').hasError('email')) {
                <mat-error>Not a valid email.</mat-error>
              }
            </mat-form-field>
            <mat-form-field class="wide">
              <mat-label>Password</mat-label>
              <input data-cy="password" type="password" matInput formControlName="password" maxlength=""
                class="form-control" required pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,100}"
                autocomplete="new-password" />
              @if (form?.controls.password.invalid && form?.controls.password.touched) {
                <div>
                  @if (form?.controls.password.errors.required) {
                    <mat-error> Password is required. </mat-error>
                  }
                  @if (form?.controls.password.errors.pattern) {
                    <mat-error> Must contain at least one number and one
                      uppercase and
                      lowercase
                      letter,
                    and at least 8 to 100 characters.</mat-error>
                  }
                </div>
              }
            </mat-form-field>
            <mat-form-field class="wide">
              <mat-label>Confirm Password</mat-label>
              <input data-cy="confirmpassword" type="password" matInput formControlName="confirmpassword" required
                pattern="{{ form?.controls.password.value }}" autocomplete="new-password" />
              @if (form?.controls.confirmpassword.invalid && form?.controls.confirmpassword.touched) {
                <div>
                  @if (form?.controls.confirmpassword.errors.required) {
                    <mat-error> Confirm password is required.
                    </mat-error>
                  }
                  @if (form?.controls.confirmpassword.errors.pattern) {
                    <mat-error> Password & Confirm Password do
                      not
                    match and must contain at least 8 to 100 characters.</mat-error>
                  }
                </div>
              }
            </mat-form-field>
            @if (!invited) {
              <mat-form-field class="wide">
                <mat-label>Organisation Name</mat-label>
                <input data-cy="orgname" type="text" matInput formControlName="orgname" maxlength="200">
                <mat-hint>Optional Organisation Name.</mat-hint>
              </mat-form-field>
            }
            @if (!invited) {
              <mat-form-field class="wide">
                <mat-label>Invite Code</mat-label>
                <input data-cy="invitecode" type="text" matInput formControlName="inviteCode" autocomplete="off" />
                <mat-hint>Optional Invite Code from your barxui Partner.</mat-hint>
                @if (form?.controls.inviteCode.invalid && form?.controls.inviteCode.touched) {
                  <div>
                    @if (form?.controls.inviteCode.errors.required) {
                      <mat-error> Invite Code is required.
                      </mat-error>
                    }
                    @if (form?.controls.inviteCode.errors.invalid) {
                      <mat-error> Invite Code is invalid.
                      </mat-error>
                    }
                  </div>
                }
              </mat-form-field>
            }
            <!-- <mat-form-field>
            <mat-label>Phone</mat-label>
            <input type="text" matInput formControlName="phone" placeholder="Phone">
          </mat-form-field> -->
        </div>
      </div>
    }
  </mat-card-content>
  <mat-card-actions>
    <div fxLayout="column" fxLayoutGap="0.5em">
      @if (registered) {
        <div>
          <button mat-raised-button color="primary" (click)='tryAgain()'>Try Again</button>
          <button mat-stroked-button [routerLink]="'/'">Back</button>
          <button mat-stroked-button [routerLink]="'/signin'">Sign In</button>
        </div>
      } @else {
        <div>
          @if (busy) {
            <simple-progress message='Registering'></simple-progress>
          } @else {
            <button data-cy="signup-button" mat-raised-button type="submit" color="primary"
            [disabled]="!form.valid">Sign Up</button>
            <!-- <button mat-stroked-button [routerLink]="'/signin'">Sign In</button> -->
          }
          <ng-template #controls>
            <button data-cy="signup-button" mat-raised-button type="submit" color="primary"
            [disabled]="!form.valid">Sign Up</button>
            <!-- <button mat-stroked-button [routerLink]="'/signin'">Sign In</button> -->
          </ng-template>
        </div>
        <div>
          @if (!!error) {
            <app-warning-panel [error]='true'>{{error}}</app-warning-panel>
          }
        </div>
      }
    </div>
  </mat-card-actions>
</mat-card>
</form>
</div>
