import {PrintResult} from "../PrintResult";

export class Message<T> {
  constructor(public action: string, public type: string, public data: T) {
  }
}

export class SetConnectionPayload {
  constructor(
    public bxConnectionId: string,
    public userId: string,
    public orgId: string) {

  }
}

export class SetConnectionMessage extends Message<SetConnectionPayload> {
  constructor(public payload: SetConnectionPayload) {
    super("setconnection", "SetConnection", payload);
  }
}


export class PrintResultMessage extends Message<PrintResult>  {
  constructor(public payload: PrintResult) {
    super("sendmessage", "PrintResult", payload);
  }
}
