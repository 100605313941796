import { ProductPricingPlan } from './ProductPricingPlan';

export class Product {
    id: string;
    object: string;
    active?: boolean;
    attributes: Array<string>;
    caption: string;
    created: Date;
    description: string;
    images: Array<string>;
    livemode: boolean;
    metadata: any;
    name: string;
    shippable?: boolean;
    statement_descriptor: string;
    type: string;
    unit_label: string;
    updated: Date;
    url: string;
    plans: Array<ProductPricingPlan>;

    constructor(p: Product) {
        if (p) {
            this.id = p.id;
            this.object = p.object;
            this.active = p.active;
            this.attributes = p.attributes;
            this.caption = p.caption;
            this.created = p.created;
            this.description = p.description;
            this.images = p.images;
            this.livemode = p.livemode;
            this.name = p.name;
            this.shippable = p.shippable;
            this.statement_descriptor = p.statement_descriptor;
            this.type = p.type;
            this.unit_label = p.unit_label;
            this.updated = p.updated;
            this.url = p.url;
            if (p.plans) {
                this.plans = p.plans.map(pp => new ProductPricingPlan(pp));
            }
        }
    }
}
