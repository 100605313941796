import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LoadingService, LoadingState} from "../../services/loading.service";
import {Subscription} from "rxjs";
import {filter} from "rxjs/operators";
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogClose } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { HelpIconComponent } from '../help-icon/help-icon.component';
import { MatIconModule } from '@angular/material/icon';
import { FlexModule } from '@angular/flex-layout/flex';

@Component({
    selector: 'app-dialog-title',
    templateUrl: './dialog-title.component.html',
    styleUrls: ['./dialog-title.component.scss'],
    standalone: true,
    imports: [FlexModule, MatIconModule, HelpIconComponent, MatButtonModule, MatDialogClose, MatProgressBarModule]
})
export class DialogTitleComponent implements OnInit, OnDestroy {

  @Input()
  title: string = '';

  @Input()
  subTitle: string = '';

  @Input()
  helpID: string = null;

  @Input()
  icon: string = null;

  @Input()
  noBottomMargin: boolean = false;

  private loadingSub: Subscription;

  busy: boolean = false;
  busyMessage: string = '';

  constructor(private loadingService: LoadingService) {
    this.loadingSub = this.loadingService.change
      .pipe(filter(state => state.owner === 'dialog'))
      .subscribe((val: LoadingState) => {
        if(val) {
          this.busy = val.isLoading;
          this.busyMessage = val.message;
        }
      });

  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.loadingSub?.unsubscribe();
    this.loadingSub = null;
  }

}
