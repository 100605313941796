import { Observable, MonoTypeOperatorFunction } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

/**
 * An operator to only emit when any of the specified key values have changed.
 * (See `distinctUntilKeyChanged` and `distinctUntilChanged` from `rxjs/operators`)
 * @param keys A list of keys of an object whose corresponding values are checked for changes.
 */
export function distinctUntilKeysChanged<T, K extends keyof T>(...keys: K[]): MonoTypeOperatorFunction<T> {
  return (source: Observable<T>) => source.pipe(
    distinctUntilChanged((previous, current) => keys.reduce(
      (condition, key) => condition && previous[key] == current[key], true
    ))
  );
}
