import { Injectable } from '@angular/core';
import { IDataSourceURLParams } from '../services/query';
import { Table } from './../../models/server/Table';

@Injectable({
  providedIn: 'root'
})
export class PersistentQueryStateService {

  /**
   * Maps a table ID to the last active query and associated parameters.
   */
  public store: {
    [tableId: string]: {
      query: string;
      params?: string[];
    }
  };

  persist(query: string, tableId: string, params?: any) {
    this.store[tableId] = { query };
    if (params?.length) {
      this.store[tableId].params = params;
    }
  }

  /**
   * Determines what state to enter when attempting to view a table which
   * has an associated persistent query.
   */
  public routerLink(table?: Table) {
    const willViewQuery = table?.defaultQuery || this.hasStoredQuery(table?.id) || table.id;
    // console.log('Will view query?', table?.name, willViewQuery, table.defaultQuery);
    return willViewQuery ? 'queries' : '.';
  }

  /**
   * Determines whether table has an associated query, so that it can be retrieved.
   */
  public hasStoredQuery = (tableId: string) => tableId in this.store;

  /**
   * Returns the correct state parameters for resuming the display of a query.
   * If a query is not specified, then the `table` parameter is simply set to
   * the ID of the table to display.
   * @param tableId
   */
  public stateParamsForTable(table?: Table): IDataSourceURLParams | {} {
    if (!table?.id) return {};

    if (this.hasStoredQuery(table.id)) {
      // console.log('Found stored query for table', table.name);
      const state: IDataSourceURLParams = {
        table: table.id, query: this.store[table.id].query
      };

      if (this.store[table.id].params) {
        state.params = this.store[table.id].params;
      }

      return state;
    } else {
      // There will alway be a default query, so we can just use that.
      // console.log('No stored query for table', table.name);
      const queryId = table.defaultQuery || table.id;
      return { table: table.id, query: queryId };
    }
  }

  public tryRemove(tableId: string) {
    if (this.hasStoredQuery(tableId)) {
      delete this.store[tableId];
    }
  }

  constructor() {
    this.store = {};
  }
}
