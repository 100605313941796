<style>
  iframe {
    border: none;
  }
</style>

<!--
<app-dialog-title [title]="data.title"></app-dialog-title>
 -->
<div mat-dialog-content style="padding:0;margin:0;height:94%">
  <iframe width="100%" height="100%" [src]="safeUrl"></iframe>
</div>
<div mat-dialog-actions align="end">
  <button type="button" mat-raised-button mat-dialog-close>
    <mat-icon>done</mat-icon> OK
  </button>
</div>