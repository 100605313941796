import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-warning-panel',
    templateUrl: './warning-panel.component.html',
    styleUrls: ['./warning-panel.component.scss'],
    standalone: true
})
export class WarningPanelComponent implements OnInit {

  @Input()
  error: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
