import { NgxLoggerLevel } from 'ngx-logger';

export interface IEnvironmentOptions {

  production: boolean;

  baseApiUrl: string;
  msgApiUrl: string;

  paymentApiUrl: string;
  helpUrl: string;
  startPageUrl: string;
  gaKey: string;
  barxuiProductID: string;
  expansionPackProductID: string;
  stripePublishableKey: string;
  captchaSiteKey: string;
  feLogLevel: NgxLoggerLevel;
  feServerLogLevel: NgxLoggerLevel;
  aws: object;

  isApp: boolean;
}

export class Environment implements IEnvironmentOptions {

  private _baseApiUrl: string;
  // private _siteUrl: string;

  production: boolean;
  isApp: boolean;

  msgApiUrl: string;
  paymentApiUrl: string;
  helpUrl: string;
  startPageUrl: string;
  gaKey: string;
  barxuiProductID: string;
  expansionPackProductID: string;
  stripePublishableKey: string;
  captchaSiteKey: string;


  feLogLevel = NgxLoggerLevel.DEBUG;
  feServerLogLevel = NgxLoggerLevel.ERROR;

  aws : any = {};

  get baseApiUrl(): string {
      return this._baseApiUrl;
  }

  set baseApiUrl(val: string) {
      if (val.endsWith('/')) {
          val = val.substr(0, val.length - 1);
      }
      this._baseApiUrl = val;
  }

  get apiVersionUrl(): string {
    return this._baseApiUrl + '/version';
  }

  get designApiUrl(): string {
      return this._baseApiUrl + '/api/v1/label';
  }

  get datamanApiUrl(): string {
      return this._baseApiUrl + '/api/v1/datamanager';
  }

  get settingsUrl(): string {
      return this._baseApiUrl + '/api/v1/clientSettings';
  }

  get fileApiUrl(): string {
      return this._baseApiUrl + '/api/v1/files';
  }

  get hexObjectsApiUrl(): string {
      return this._baseApiUrl + '/api/v1/hexobjects';
  }

  get feedbackApiUrl(): string {
      return this._baseApiUrl + '/api/v1/feedback';
  }

  get userApiUrl(): string {
      return this._baseApiUrl + '/api/v1/user';
  }

  get orgApiUrl(): string {
      return this._baseApiUrl + '/api/v1/organisation';
  }

  get sampleApiUrl(): string {
    return this._baseApiUrl + '/api/v1/samples';
  }

  get printerApiUrl(): string {
      return this._baseApiUrl + '/api/v1/print';
  }

  get fontApiUrl(): string {
      return this._baseApiUrl + '/api/v1/fonts';
  }

  get notificationsApiUrl(): string {
      return this._baseApiUrl + '/api/v1/notifications';
  }

  get accountApiUrl(): string {
      return this._baseApiUrl + '/api/v1/account';
  }

  get agentApiUrl(): string {
      return this._baseApiUrl + '/api/v1/agent';
  }

  get activityApiUrl(): string {
      return this._baseApiUrl + '/api/v1/activity';
  }

  get limitationApiUrl(): string {
      return this._baseApiUrl + '/api/v1/limits';
  }

  get addOnsApiUrl(): string {
      return this._baseApiUrl + '/api/v1/addons';
  }

  get serialApiUrl(): string {
      return this._baseApiUrl + '/api/v1/serial';
  }

  get feLogUrl(): string {
      return this._baseApiUrl + '/api/v1/felogs';
  }

  get cookieDomain(): string {
      return window.location.hostname;
  }

  constructor(env: IEnvironmentOptions) {
      Object.assign(this, env);

      // this._siteUrl = `${window.location.protocol}//${window.location.host}`;


  }
}
