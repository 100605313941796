import { ShippingAddress } from './ShippingAddress';
import { HexcomProductSubscription } from './HexcomProductSubscription';

export class HexcomCustomer {
    public id: string;
    public userID: string;
    public organisationID: string;
    public name: string;
    public email: string;
    public address: ShippingAddress;
    public phone: string;

    public subscriptions: Array<HexcomProductSubscription>;

    constructor(c: HexcomCustomer = null) {
        if (c) {
            Object.assign(this, c);
            this.subscriptions = new Array<HexcomProductSubscription>();
            if (c.subscriptions) {
                c.subscriptions.forEach(s => this.subscriptions.push(new HexcomProductSubscription(s)));
            }
        }
    }

    public currentSubscription(): HexcomProductSubscription {
        if (this.subscriptions && this.subscriptions.length) {
            const sub = this.subscriptions.find(s => s.status !== 'cancelled');
            return new HexcomProductSubscription(sub);
        }
        return null;
    }

}
