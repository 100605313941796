import { TaxRate } from './TaxRate';

export class SubscriptionPurchaseInformationResponse {
    public taxZone: string;
    public taxRate: TaxRate;
    public amount: number;
    public taxAmount: number;
    public discount: number;
    public couponID: string;
    public currency: string;
    public lastInvoicePDFUrl: string;
    public lastInvoiceUrl: string;
    public isTrialing: boolean;
    public periodEnd?: Date;
    public error: string;

    constructor(info: SubscriptionPurchaseInformationResponse = null) {
        if (info) {
            Object.assign(this, info);
        }
    }
}

