<app-dialog-title title="Add Samples" icon="cake_add"></app-dialog-title>
<div mat-dialog-content>
  <form [formGroup]="form">
    <div>Select the samples to add. If you have previously added the selected samples, adding again will create
      duplicates.
    </div>
    <div fxLayout="row" fxLayoutGap="1rem" fxLayoutAlign="start center">
      <mat-form-field class="wide">
        <mat-label>Samples Set</mat-label>
        <mat-select formControlName="sample">
          @for (sample of samples | async ; track sample) {
            <mat-option [value]="sample">{{sample.title}}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <p [innerHtml]="form.controls.sample.value?.description"></p>
    </div>
  </form>
</div>
<div mat-dialog-actions align="end">
  <button mat-raised-button color="primary" (click)="goAhead()" [disabled]="!form.controls.sample.value">
    <mat-icon>done</mat-icon> Add Samples
  </button>
  <button type="button" mat-raised-button [mat-dialog-close]="undefined">
    <mat-icon>close</mat-icon>
    Close
  </button>
</div>