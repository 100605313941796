import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { HelpPageEvent } from '../../services/help.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-help-popup',
    templateUrl: './help-popup.component.html',
    standalone: true,
    imports: [MatDialogContent, MatDialogActions, MatButtonModule, MatDialogClose, MatIconModule]
})
export class HelpPopupComponent {

  safeUrl: SafeResourceUrl;

  constructor(
    private dialogRef: MatDialogRef<HelpPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: HelpPageEvent,
    private sanitizer: DomSanitizer) {

    this.dialogRef?.keydownEvents().subscribe(event => {
      if (event.key === "Escape") {
        this.dialogRef.close();
      }
    });

    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.url);
  }
}
