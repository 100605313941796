import { AfterViewInit, Component, Inject, LOCALE_ID, OnInit } from '@angular/core';

import { switchMap } from 'rxjs/operators';

import { AuthenticationService } from '../authentication/authentication.service';
import { FeatureSet, FeatureSetPlan } from '../models/server/FeatureSet';
import { FeatureService } from '../services/feature.service';
import { OrganisationService } from '../services/organisation.service';
import { RouterLink } from '@angular/router';
import { FlexModule } from '@angular/flex-layout/flex';

@Component({
    selector: 'app-home-pricing',
    templateUrl: './home-pricing.component.html',
    standalone: true,
    imports: [FlexModule, RouterLink]
})
export class HomePricingComponent implements AfterViewInit {

  featureSets: FeatureSet[];
  features: Map<string, Array<string>>;
  xpFS: FeatureSet;

  private countryCode: string;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private organisationService: OrganisationService,
    public authService: AuthenticationService,
    private featureService: FeatureService) { }

  ngAfterViewInit(): void {

    this.organisationService.getLocation()
      .pipe(

        switchMap(cc => {
          this.countryCode = cc;
          return this.featureService.getFeatureSets();
        })
      )
      .subscribe(fs => {
        this.xpFS = (fs || []).find(fsets => fsets.isExpansionPack);
        this.featureSets = (fs || []).filter(f => f.userSelectable);

        this.features = new Map<string, Array<string>>();
        for (const fset of this.featureSets) {
          const feet = this.getPlanFeatures(fset);


          if (feet != null) {
            this.features.set(fset.name, feet);
          }
        }
      });
  }

  handleRedirect(url: string, extraParameter: string) {
    window.location.href = url + extraParameter;
  }

  getPlan(fs: FeatureSet): FeatureSetPlan[] {
    let plan = fs.plans.find(p => p.countryCode === this.countryCode);
    if (plan == null) {
      plan = fs.plans.find(p => p.countryCode === 'US');
    }
    return [plan];
  }

  private getPlanFeatures(fs: FeatureSet): Array<string> {
    const plans = this.getPlan(fs);
    if (plans.length) {
      if (plans[0] != null) {
        return this.featureService.genFeatureDescriptions(
          this.featureService.expandFeatureSet(fs, this.xpFS, plans[0].expansionIncluded, 0), this.locale);
      }
    }
    return null;
  }

}
