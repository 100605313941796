import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { SimpleProgressComponent } from '../simpleprogress.component';
import { DialogTitleComponent } from '../dialog-title/dialog-title.component';

@Component({
    selector: 'app-update-notification-dialog',
    templateUrl: './update-notification-dialog.component.html',
    standalone: true,
    imports: [DialogTitleComponent, MatDialogContent, MatDialogActions, SimpleProgressComponent, MatButtonModule, MatDialogClose, MatIconModule]
})
export class UpdateNotificationDialogComponent implements OnInit {

  startPageUrl: SafeResourceUrl;
  reloading = false;

  constructor(
    private dialogRef: MatDialogRef<UpdateNotificationDialogComponent>,
    private sanitizer: DomSanitizer,
  ) {
    if(this.dialogRef) {
      this.dialogRef?.keydownEvents().subscribe(event => {
        if (event.key === "Escape") {
          this.dialogRef.close();
        }
      });
    }
  }

  ngOnInit() {
    this.startPageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.helpUrl + '/releases');
  }

  async reload(ev: Event): Promise<boolean> {
    this.reloading = true;
    ev.stopPropagation();
    ev.preventDefault();
    setTimeout(() => window.location.reload());
    return false;
  }
}
