export enum DataTypeEnum {
    String = 0,
    Number = 1,
    Date = 2,
    Time = 3,
    DateTime = 4,
    Boolean = 5,
    Guid = 6,
    Object = 7,
    StringList = 8,
    ObjectList = 9
  }
