import { BorderStyle } from '../BorderStyle';
import { Point, Extent } from '../Dimensions';
import { FillStyle } from '../FillStyle';
import { TextBlock } from '../TextBlock';
import { Shape } from './Shape';

export enum ShapeComponentType {
    HEXOBJECT = 1,
    GEOMETRY = 2
}

export enum TEXT_ALIGN {
    left = 0,
    center = 1,
    right = 2
}

export class ShapeComponent {
    public border: BorderStyle;
    public extent: Extent;
    public fill: FillStyle;
    public index: number;
    public origin: Point;
    public text: TextBlock;
    public style: ShapeComponentType;
    public points: Array<Point>;
    public fontSizeGroup: number;
    public fontScaleY: number;
    public layoutLine: number;  //compononets on same line have same group ID
    public layoutOrder: number; //order of the component on the same line of text used during layout/scaling procedures.
    public calculatedWidth: number;
    public isBackgroundShape: boolean;
    public isFixedPercentComponent: boolean;
     constructor(shapeComp?: ShapeComponent) {
        if (shapeComp) {
            this.border = new BorderStyle(shapeComp.border);
            this.extent = new Extent(shapeComp.extent.width, shapeComp.extent.height);
            this.origin = new Point(shapeComp.origin.x, shapeComp.origin.y);
            this.style = shapeComp.style;
            this.text = new TextBlock(shapeComp.text);
            this.index = shapeComp.index;
            this.fill = new FillStyle(shapeComp.fill.color);
            this.fontSizeGroup = shapeComp.fontSizeGroup;
            this.fontScaleY = 1;
            this.layoutLine = shapeComp.layoutLine;
            this.layoutOrder = shapeComp.layoutOrder;
          this.isBackgroundShape = shapeComp.isBackgroundShape;
          this.isFixedPercentComponent = shapeComp.isFixedPercentComponent;
            if (shapeComp.points) {
                this.points = shapeComp.points.map(p => new Point(p.x, p.y));
            }

            this.fontSizeGroup = shapeComp.fontSizeGroup;

            this.fontScaleY = 1;
        } else {
            this.text = new TextBlock();
            this.fill = new FillStyle('');
            this.border = new BorderStyle();
            this.text = new TextBlock();
            this.index = 1;
            this.fontSizeGroup = 1;
            this.fontScaleY = 1;
            this.layoutLine = 0;
            this.layoutOrder = 0;
          this.calculatedWidth = 0;
          this.isBackgroundShape = false;
          this.isFixedPercentComponent = false;

        }
    }

    set(
        xx: number,
        yy: number,
        ww: number,
        hh: number,
        s: ShapeComponentType,
        fontSizeGroup: number,
        points?: Array<Point>
    ): ShapeComponent {
        //  this.fontSizeGroup = 2;
        this.fontSizeGroup = fontSizeGroup;
        this.origin = new Point(xx, yy);
        this.extent = new Extent(ww, hh);
        this.style = s;
        if (points) {
            this.points = points.map(p => new Point(p.x, p.y));
        }
        return this;
    }

    getTextColor(shapeColor: string) {
        if (this.text.format.color === '') {
            return shapeColor;
        } else {
            return this.text.format.color;
        }
    }

    getFillColor() {
        return this.fill.color;
    }

  getTextAlign() {
    return  this.text.format.justify;
    }

  getFont() {
    return this.text.format.font;
  }

  getFontSize() {
    return this.text.format.size * 1.33; // need the 1.3 to acheive correct Pt size on the designer for some reason.. need to sort out why
            // Benchmark was 72 pt....  adjusted this value until screen Arial 72Pt matched what Microsoft Word displayed at 72Pt Arial with a bounding rectangle op 2.5cm x 7.6cm
  }

  getFontWeight() {
    //return this.text.format.fontWeight;
    switch (this.text.format.fontWeight) {
      case 'bold':
        return 700;
      case 'bolder':
        return 800;
      case 'boldest':
        return 900;
      case 'lighter':
        return 200;
      case 'lightest':
        return 100;
    }

    return 400;

  }

  getVerticalAlign() {
    return this.text.format.verticalAlignment;
  }
  getFontStyle() {
    return this.text.format.italic ? 'italic' : 'normal';
  }
  getFontUnderline() {
    return this.text.format.underline;
  }

  getFontStrikeout() {
    return this.text.format.strikethrough;
  }


  getFontSuperscript() {
    return this.text.format.superscript;
  }

  getFontSubscript() {
    return this.text.format.subscript;
  }

  getFontHidden() {
    return this.text.format.hidden;
  }

  getFontInverse() {
    return this.text.format.inverse;
  }

  getFontColor() {
    return this.text.format.color;
  }

  getFontBackgroundColor() {
    return this.text.format.inverse ? 'black' : this.text.format.backgroundcolor;
  }

  hasText() {
    return this.text !== null && this.text.content.length > 0;
  }
    /*
    scale components dimensions in percentage to real number
    */
    sizeToShape(shape: Shape<any>): void {

        this.extent.width *= shape.extent.width;
        this.extent.height *= shape.extent.height;
        // if (this.border) {
        //     this.border.lineWidth = shape.border.lineWidth;
        // }
        if (this.points) {
            this.points.forEach(p => {
                p.x *= shape.extent.width;
                p.y *= shape.extent.height;
            });
        }
    }

}
