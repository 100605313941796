import {Injectable} from "@angular/core";
import { map, Observable, Observer, retry, RetryConfig, Subject } from "rxjs";
import {Message, SetConnectionMessage, SetConnectionPayload} from "../models/server/Messages/Messages";
import Guid from '../shared/guid';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  constructor() {
  }

  private subject: Subject<MessageEvent>;

  public messages: Subject<Message<any>>;

  public connectionChange = new Subject<boolean>();

  public static bxConnectionId = Guid.create().toString();

  private retryConfig: RetryConfig = {
    delay: 3000,
  };

  public connect(url: string, userId: string, orgId: string): Subject<MessageEvent> {
    if (!this.subject) {
      this.subject = this.create(url);
    }

    this.messages = <Subject<Message<any>>>this.subject.pipe(
      retry(this.retryConfig),
      map(
      (response: MessageEvent): Message<any> => {
        let message = JSON.parse(response.data);
        return new Message(message.action, message.type, message.data);
      }
    )
    );

    this.connectionChange.next(true);

    this.sendMessage(new SetConnectionMessage(new SetConnectionPayload(MessageService.bxConnectionId, userId, orgId)));

    return this.subject;
  }

  sendMessage(message: Message<any>) {
    this.messages.next({action: message.action, type: message.type, data: message.data});
  }

  private create(url): Subject<MessageEvent> {
    let ws = new WebSocket(url);

    let observable = new Observable((obs: Observer<MessageEvent>) => {
      ws.onmessage = obs.next.bind(obs);
      ws.onerror = obs.error.bind(obs);
      ws.onclose = obs.complete.bind(obs);
      return ws.close.bind(ws);
    });
    let observer = {
      next: (data: Object) => {
        //if (ws.readyState === WebSocket.OPEN) {

        this.waitForConnection(ws,function () {
          ws.send(JSON.stringify(data));
        }, 1000);

        //}
      }
    };
    return Subject.create(observer, observable);
  }

  waitForConnection(ws : WebSocket, callback, interval : number) {
    if (ws.readyState === 1) {
      callback();
    } else {
      var that = this;
      // optional: implement backoff for interval here
      setTimeout(function () {
        that.waitForConnection(ws, callback, interval);
      }, interval);
    }
  };

}
