import {Pipe, PipeTransform} from '@angular/core';

import moment from 'moment';

@Pipe({
    name: 'mdate',
    pure: true,
    standalone: true
})
export class MDatePipe implements PipeTransform {

    transform(value: any, format: string): any {
        if (value) {
            if(format === 'fromNow') {
                return moment(value).fromNow();
            }
            else{
                return moment(value).format(format);
            }

        }
        return value;
    }

}