<style>
  .main {
  margin-top: 10em;
}
</style>
<app-bganimation tagName="body" className="warehouse"></app-bganimation>
<div class="main wider" fxLayout="row" fxLayoutAlign="center center">
  <form [formGroup]="form" (ngSubmit)="reset()">
    <mat-card >
      <mat-card-header>
        <mat-card-title>Reset Your Password</mat-card-title>
        <mat-card-subtitle>You can reset your password if you forgot or lost it.</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="1em">
          <div fxLayout="column" fxLayoutAlign="start stretch">
            <mat-form-field class="wide">
              <mat-label>Email Address</mat-label>
              <input data-cy="reset-email-input" type="email" matInput formControlName="email" required
                placeholder="Email">
              <mat-hint>Enter the email address you signed up with.</mat-hint>
              <mat-error>An email address is required.</mat-error>
            </mat-form-field>
            <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="start center">
              <button data-cy="reset-getcode-button" mat-raised-button color="primary"
                [disabled]="!form?.controls.email.valid" (click)="getcode()">Get
              Code</button>
              <mat-form-field class="wide">
                <mat-label>Code</mat-label>
                <input data-cy="reset-code-input" type="text" matInput formControlName="code" required
                  placeholder="Code" autocomplete="off">
                <mat-hint>The code is sent to the email address above..</mat-hint>
                <mat-error>Code is required. Check your email.</mat-error>
              </mat-form-field>
            </div>
            @if (form?.controls.code.valid) {
              <mat-form-field class="wide">
                <mat-label>New Password</mat-label>
                <input data-cy="reset-password-input" type="password" matInput formControlName="password"
                  placeholder="New Password" class="form-control" required pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                  autocomplete="new-password" [disabled]="resetDone" />
                @if (form?.controls.password.invalid && form?.controls.password.touched) {
                  <div>
                    @if (form?.controls.password.errors.required) {
                      <mat-error> New Password is required. </mat-error>
                    }
                    @if (form?.controls.password.errors.pattern) {
                      <mat-error> Must contain at least one number and one
                        uppercase and
                        lowercase
                        letter,
                      and at least 8 or more characters.</mat-error>
                    }
                  </div>
                }
              </mat-form-field>
            }
            @if (form?.controls.code.valid) {
              <mat-form-field class="wide">
                <mat-label>Confirm New Password</mat-label>
                <input data-cy="reset-confirmpassword-input" type="password" matInput formControlName="confirmpassword"
                  placeholder="Confirm New Password" required pattern="{{ form?.controls.password.value }}"
                  autocomplete="new-password" />
                @if (form?.controls.confirmpassword.invalid && form?.controls.confirmpassword.touched) {
                  <div>
                    @if (form?.controls.confirmpassword.errors.required) {
                      <mat-error> Confirm New Password is required.
                      </mat-error>
                    }
                    @if (form?.controls.confirmpassword.errors.pattern) {
                      <mat-error> Password & Confirm Password do not
                      match.</mat-error>
                    }
                  </div>
                }
              </mat-form-field>
            }
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <div fxLayout="row" fxLayoutGap="0.5em">
          @if (!resetDone) {
            <button data-cy="reset-submit-button" mat-raised-button type="submit" color="primary"
            [disabled]='!form.valid'>Reset</button>
          }
          @if (resetDone) {
            <button mat-stroked-button [routerLink]="'/signin'">Login</button>
          }
          <!-- <button mat-stroked-button [routerLink]="'/signup'">Register New Login</button> -->
          @if (busy) {
            <simple-progress message='Requesting'></simple-progress>
          }
          @if (!!message) {
            <app-warning-panel data-cy="reset-submit-message" [error]='false'>{{message}}
            </app-warning-panel>
          }
          @if (!!error) {
            <app-warning-panel data-cy="reset-submit-error" [error]='true'>{{error}}</app-warning-panel>
          }
        </div>
      </mat-card-actions>
    </mat-card>
  </form>
</div>
