import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { DialogTitleComponent } from '../dialog-title/dialog-title.component';

export type LoginAction = () => void;

@Component({
    selector: 'app-session-expired-dialog',
    templateUrl: './session-expired-dialog.component.html',
    standalone: true,
    imports: [DialogTitleComponent, MatDialogContent, MatDialogActions, MatButtonModule, MatIconModule]
})
export class SessionExpiredDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<SessionExpiredDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public loginFunc: LoginAction
  ) { }

  ngOnInit() {
  }

  login(): void {
    this.dialogRef.close();
    this.loginFunc();
  }

}
