<div [class]='noBottomMargin ? "headingNoBM": "heading"'>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutGap="0.5em">
      <div class="brand" fxLayout="row" fxLayoutAlign="flex-start center">
        @if (!!icon) {
          <mat-icon>{{ icon }}</mat-icon>
        }
        @if (!!title) {
          <span class="title"> {{ title }}</span>
        }
      </div>
      <div class="tools" fxLayout="row" fxLayoutAlign="flex-start center">
        <ng-content></ng-content>
      </div>
    </div>
    <div>
      @if (!!helpID) {
        <app-help-icon helpID="{{helpID}}"></app-help-icon>
        <button mat-icon-button aria-label="close dialog" tabindex="-1" mat-dialog-close>
          <mat-icon matListIcon>close</mat-icon>
        </button>
      }
    </div>
  </div>
  @if (busy) {
    <div fxLayout="row" fxLayoutGap="0.5em" fxLayoutAlign="start center" class="progress">
      <!--  <span >{{busyMessage}}</span>-->
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  }
  @if (!!subTitle) {
    <div class="subTitle"> {{ subTitle }}</div>
  }
</div>

