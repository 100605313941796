import {EventEmitter, Injectable} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {BehaviorSubject, NEVER, Observable, of, ReplaySubject, zip} from 'rxjs';
import {filter, first, map, skipWhile, switchMap, take, tap} from 'rxjs/operators';
import {Query} from '../../models/server/Query';
import {Table} from '../../models/server/Table';
import {DataManagerService} from '../../services/datamanager.service';
import {ErrorHandlerService} from '../../services/errorhandler.service';
import {
  DeleteDataSourceDialogComponent,
  IDeleteDataSourceConfirmation,
  IDeleteDataSourceOptions
} from '../sidebars/query/delete-data-source/delete-data-source.component';
import {PersistentQueryStateService} from '../table-tab-group/persistent-query-state.service';
import {distinctUntilKeysChanged} from './helpers';
import {IDataSourceURLParams, IDataViewModel} from './query';
import {LocalStorageKeys as LocalStorageKeys} from '../../models/server/Constants';

@Injectable({
  providedIn: 'root'
})
export class ActiveDataSourceService {

  /**
   * The current source of data, either from a table or a query.
   */
  public current = new ReplaySubject<'table' | 'query'>(1);

  /**
   * The current table which is being displayed.
   */
  public table = new ReplaySubject<Table>(1);

  /**
   * A list of all tables which can be displayed, initially set to nothing until
   * values are loaded.
   */
  public tables = new ReplaySubject<Table[]>(1);

  /**
   * The current query (but not its parameters) which is being executed. The parameters,
   * if any, are set through the `activeQuery` property.
   *
   * @description This is set to `null` initially and again in the case of a transition from
   * displaying a query to subsequently displaying a table. This transition must reset
   * the active query (by setting this property to `null`), or else it will persist as
   * being currently active on the sidebar.
   */
  public query = new BehaviorSubject<Query>(null);

  /**
   * A list of queries that can be referenced and displayed on the UI.
   */
  public queries: IDataViewModel<Query>[] = [];

  /**
   * An event which fires when the active data source should be reloaded. This event is
   * checked for emissions in the data component, where the grid's reload API method is
   * directly invoked.
   */
  public reloadData = new EventEmitter();

  /**
   * The total number of rows that a given query/table has available.
   */
  public activeRowCount?: number = null;
  
  public isSearching: boolean;

  /**
   * Returns the active query object, and sets any variable parameters before
   * emitting a result. Only emits a result once the active data source is
   * changed to a query.
   */
  get activeQuery(): Observable<Query> {
    return this.query.pipe(
      switchMap((query: Query) => zip(this.current, of(query))),
      skipWhile(([source]) => source === 'table'),
      first(),
      map(([, query]) => query),
      tap((query : Query) => {
        const params = this.queries.find(q => q.id === query.id);
        if (params) {
          query.params = params;
        }
      })
    );
  }

  /**
   * If data is loading this is true. Used to control the progress bar state and
   * whether to reduce the opacity of the grid.
   */
  public isLoading: boolean = true;

  /**
   * If a user is creating a new query, this changes to true, and the accordion
   * adds an extra entry which allows for the definition of a new query.
   */
  public isDefiningNewQuery = new ReplaySubject<boolean>(1);


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dataManager: DataManagerService,
    private snackBar: MatSnackBar,
    private matDialog: MatDialog,
    private queryStore: PersistentQueryStateService,
    private ngxLogger: NGXLogger,
    private errorHandler: ErrorHandlerService) {

    // Provides notification that the state of the application changed.
    const urlState = this.route.queryParams.pipe(
      // Only care about changes that occur in the data manager
      filter(() => !!this.router.url.match(/data/)),
      // Take the ill-defined queryParams, strongly type it, and guarantee that query parameters
      // are always in an array.
      map((url: IDataSourceURLParams) => {
        const params = url.params instanceof Array ? url.params : [url.params];
        const state = {table: url.table, query: url.query, params};
        if (!url.params) {
          state.params = [];
        }
        return state;
      }),
      // Always wait for the list of tables to have loaded first.
      switchMap(() => this.tables, params => params)
    );

    // Load table data
    this.reloadTables();

    // Listen for changes to sources which could affect the table data source, i.e. a table ID.
    // Also check to see if there is an associated query, and if so, don't attempt to display
    // the table and instead wait for the query to load.
    urlState.pipe(
      // Only update whenever either the current query or table changes.
      // If the query changes from set to not set (or vice-versa), the query's initial table
      // should be displayed and so its content must be loaded.
      distinctUntilKeysChanged('query', 'table'),
      switchMap(params => zip(this.tables, of(params))),
      switchMap(([tables, params]) => {
        // Check to see if they have any stored tables
        if (tables.length === 0) {
          this.isLoading = false;
          this.reloadData.subscribe(() => {
            this.reloadTables();
            this.isLoading = false;
          });

          return NEVER;
        }
        // Only assign the table ID if it's valid
        const isImplicitReference = !tables.some(t => t.id == params.table);
        const tableId = isImplicitReference ? tables[0].id : params.table;

        // True if the table has a default query and no explicit table reference was defined
        const isImplicitQuery = isImplicitReference ? !!tables[0].defaultQuery : false;

        // Now load its content and whether there is an associated query
        return zip(this.dataManager.getTable(tableId), of(isImplicitQuery), of(!params.query));
      })
    ).subscribe(([table, isImplicitQuery, isExplicitQueryId]) => {
      // ..and emit its values (only if we're not going to reload anything)
      !isImplicitQuery && this.table.next(table);

      // If there is a query, wait until the block below emits to avoid a race condition
      // where both the table and its associated query are trying to display content.
      if (!isImplicitQuery && isExplicitQueryId) {
        // Hide the new query accordion in the event of changing tables
        this.isDefiningNewQuery.next(false);

        // Only displaying table content now
        this.current.next('table');
        this.query.next(null);

        // If the table attempted to restore an active query, then remove it from the store
        this.queryStore.tryRemove(table.id);
      }

      // The block below won't emit in the case of an implicit query being defined, but we need it to,
      // so just reload the state with the explicitly-defined parameters.
      if (isImplicitQuery) {
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: <IDataSourceURLParams>{table: table.id, query: table.defaultQuery}
        });
      }
    });

    // Listen for changes which could affect the currently displayed query, ie. a query ID
    // or its associated parameter list (for queries with variable predicates).
    urlState.pipe(
      distinctUntilKeysChanged('query', 'params'),
      // Wait for the associated table to load first
      switchMap(() => this.table.pipe(take(1)), params => params),
      // Only care if there is an actual query to load
      switchMap(({query, params}) => {
        return query ? zip(dataManager.getQuery(query), of(params)) : NEVER;
      })
    ).subscribe(([query, params]) => {
      // If there were pre-defined query parameters, then set them so the query can immediately
      // execute upon the data component requesting the query object.
      if (query.params && !this.queries.find(q => q.id === query.id)) {
        this.queries.push(query.params);
      }

      // first time in, look for filter parameter values passed from the print dialog (probably)
      let forcedParams = localStorage.getItem(LocalStorageKeys.TransientQueryParams);
      let newParams;
      if (forcedParams) {
        try {
          newParams = JSON.parse(forcedParams);
          localStorage.removeItem(LocalStorageKeys.TransientQueryParams);
        } catch {
        }
      }

      // Make sure query parameters are always bound to the query, or else parameter change
      // detection won't be able to know if the model and view model have changed.
      this.bindQueryParams(query, params, newParams);

      // Persist the query and its params so that they can quickly resume viewing if the
      // active table changed
      this.queryStore.persist(query.id, query.tableID, params);

      // Tell the data component that the query can now be loaded.
      this.query.next(query);
      this.current.next('query');
    });
  }

  /**
   * Sets variable query predicate values on a given query object. This is necessary
   * as the objects which are bound on the UI are different to models returned from the server.
   * @param query The query whose parameters should be set.
   * @param params The list of dynamic parameters to bind to the query.
   */
  bindQueryParams(query: Query, params: string[], filterParams?: any) {
    const match = this.queries.find(q => q.id == query.id);
    // May be trying to look for another table's queries
    if (!match) return;
    const mutablePredicates = match.predicates.filter(p => !p.isImmutable);

    params.forEach((urlParam, index) => {
      if (mutablePredicates[index]) {
        mutablePredicates[index].value = {current: urlParam, new: urlParam};
      }
    });

    if (filterParams) {
      for (let pred of match.predicates) {
        if (filterParams[pred.key]) {
          pred.value.new = filterParams[pred.key];
          pred.value.current = filterParams[pred.key];

          let col = query.columns.find(c => c?.name === pred.key);
          if (col) {
            col.filter = filterParams[pred.key];
          }
        }
      }
    }
  }

  /**
   * Saves the given query (represented by its view model) and optionally creates it
   * if it is a new query. If the query is currently being viewed on the grid, then
   * saving it will cause its contents to reload.
   *
   * @param query The view model whose properties define the update to the query model
   * @param params An object containing a single boolean property `shouldCreate`, which
   * if true, determines whether to create the query instead of updating an existing one.
   */
  public save(query: IDataViewModel<Query>, params?: { shouldCreate: boolean }): Observable<boolean> {
    // If the query isn't being created, then load its contents first so that its
    // parameters can be modified. Otherwise, get a new query as a child of the
    // active table.
    const querySource = !params.shouldCreate ?
      this.dataManager.getQuery(query.id) :
      this.table.pipe(take(1), map(activeTable => activeTable.toNewQuery));

    // Wait for the required data to load, and then bind the modified parameters
    // to the existing (or new) model.
    return querySource.pipe(
      tap(model => model.params = query),
      switchMap(model => this.dataManager.validateNewQuery(model)),
      switchMap(validated => this.dataManager.updateQuery(validated)),
      // If we're creating a new query, at this point we'll have its ID. In that case,
      // update its view model so it can be immediately executed.
      tap(updated => {
        if (params.shouldCreate) {
          query.id = updated.id;
          this.queries.push(query);
          this.isDefiningNewQuery.next(true);
        }
      }),
      // switchMap(updated => zip(
      //   this.query.pipe(
      //     // If the that changed was the one which is currently being viewed, reload its
      //     // data by telling the grid to display a new query.
      //     take(1), map(model => model && model.id === query.id)
      //   ),
      //   of(updated)
      // )),
      // // Only bother to reload if the query was already being viewed.
      // switchMap(([shouldRefresh, updated]) => shouldRefresh && query.isValid ? of(updated) : NEVER),
      switchMap(updated => {
        // Reload the active query since its values have changed.
        this.query.next(updated);
        this.current.next('query');
        query.isRunning = false;

        return of(true);
      }));
  }

  /**
   * Renames a table. Returns an observable which resolves to a boolean indicating
   * whether the rename was successful.
   * @param source The table to rename
   * @param name The new name of the table
   */
  public rename(source: Table, name: string) {
    return this.dataManager.renameTable(source.id, name).pipe(
      tap(success => {
        if (success) {
          source.name = name;
          this.tables.pipe(take(1)).subscribe(tables => {
            const affected = tables.find(table => table.id === source.id);
            if (!affected) {
              return;
            }
            affected.name = name;
            this.tables.next(tables);

            this.queries
          });
        }
      })
    );
  }

  /**
   * Provides a confirmation modal and then (if confirmation received) deletes the
   * corresponding query or table from the view model and from the API server.
   */
  public delete(source: IDataViewModel<Table | Query> | Table) {
    const isDeletingTable = source instanceof Table || source.dataSource instanceof Table;
    const type = isDeletingTable ? 'table' : 'query';

    // Seek confirmation before actioning the deletion
    this.matDialog.open(
      DeleteDataSourceDialogComponent,
      <MatDialogConfig<IDeleteDataSourceOptions>>{
        maxWidth: 400,
        data: {
          sourceName: source.name, type
        }
      }
    ).beforeClosed().pipe(
      // Check to see whether the user confirmed the deletion
      switchMap((options?: IDeleteDataSourceConfirmation) => {
        if (options?.shouldDelete) {
          this.isLoading = true;
          return isDeletingTable ?
            this.dataManager.deleteTable(source.id) :
            this.dataManager.deleteQuery(source.id);
        } else {
          return NEVER;
        }
      }),
      // Provide confirmation and retrieve the data model this table/query
      // was a part of.
      switchMap(didDelete => {
        this.isLoading = false;
        const SUCCESS_MESSAGE = `The ${type} “${source.name}” was successfully deleted.`;
        const ERROR_MESSAGE = `An error occurred while deleting the ${type}.`;

        this.snackBar.open(didDelete ? SUCCESS_MESSAGE : ERROR_MESSAGE, null);

        // If appropriate, return the view model so the entry can be removed.
        if (didDelete) {
          return isDeletingTable ? this.tables.pipe(take(1)) : of(this.queries);
        } else {
          return NEVER;
        }
      }),
      switchMap(dataSource => zip(this.current?.pipe(take(1)), of(dataSource)))
    ).subscribe({
      next: ([current, dataSource]) => {
        // ID of the next data source to show. (Only set in the case of deleting a table)
        let redirectId: string = null;
        // Remove the table/query from its data model.
        // Once completed, if the user was viewing a table, then show them the table at
        // index 0 of all the tables. Otherwise, if they were viewing the deleted query,
        // show them the query's table.
        if (isDeletingTable) {
          const tables = (dataSource as Table[]).filter(table => table.id !== source.id);
          this.tables.next(tables);
          // Cleanup in the case of all tables being deleted
          if (tables.length === 0) {
            this.table.next(null);
            this.activeRowCount = null;
          } else {
            redirectId = tables[0].id;
          }
        } else {
          this.queries = (dataSource as IDataViewModel<Query>[]).filter(
            query => query.id !== source.id
          );
        }
        // If they were viewing the deleted query, show them its table instead.
        // If the user was viewing a table, show them the first table in the list of tables.
        if (isDeletingTable || current === 'query' && this.query.value.id === source.id) {
          // Unset either the 'table' or 'query' query parameter from the URL to display
          // the correct application state
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: <IDataSourceURLParams>{[current]: redirectId, params: null},
            queryParamsHandling: 'merge'
          });
        }
      },
      error: err => {
        this.isLoading = false;
      },
      complete: () => this.isLoading = false
    });
  }

  /**
   * Loads the first 100 tables and causes the UI to update reflecting any changes.
   */
  public reloadTables(refreshData: boolean = false) {
    // Load the list of tables and emit this value once loaded.
    return this.dataManager.getTableList(0, 100)
      .subscribe(
        tables => {
          this.tables.next(tables ? tables.values : []);
          if(refreshData)
            this.reloadData.emit();
        }
      );
  }


}
