<app-dialog-title title="Invitation to {{invite.organisationName}}" icon="cake"></app-dialog-title>

<div mat-dialog-content>
  <mat-card>
    <mat-card-content>
      <p>
        You have been invited to join the following organisation:
        <br/>
        <span style="font-weight:bolder">{{ invite.organisationName }}</span>
      </p>
      @if (invite.message) {
        <p>
          Message from administrator:
          <br/> {{ invite.message }}
        </p>
      }
    </mat-card-content>
  </mat-card>
  <p matLine class="mat-caption">This invite was sent {{ invite.sent | mdate: 'fromNow' }}.</p>
</div>

<div mat-dialog-actions align="end">
  @if (processing) {
    <simple-progress message="Processing"></simple-progress>
  }
  @if (error) {
    <app-warning-panel>{{ error.message }}</app-warning-panel>
  }
  <button type="button" mat-raised-button mat-dialog-close>
    <mat-icon>cancel</mat-icon>
    Cancel
  </button>
  <button mat-raised-button (click)="decline()">
    <mat-icon>thumb_down</mat-icon>
    Decline
  </button>
  <button mat-raised-button color='primary' (click)="accept()">
    <mat-icon>thumb_up</mat-icon>
    Accept
  </button>
</div>
