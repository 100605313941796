import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { from } from 'rxjs';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.getToken().pipe(
      switchMap((token) => {
        return next.handle(request.clone(
          {
            setHeaders: { Authorization: `Bearer ${token}` }
          })
        );
      })
    );
  }

  private getToken() {
    return from(
      new Promise((resolve, reject) => {
        Auth.currentSession().then((session) => {
          if (!session.isValid()) {
            resolve(null);
          } else {
            resolve(session.getIdToken().getJwtToken());
          }
        })
        .catch(err=>{ return resolve(null) });
      })
    );
  }
}
