<div id="query-name" fxLayout="column" fxLayoutGap="0.25rem">
  <div fxLayout="row" fxLayoutGap="0.25rem" fxLayoutAlign="start center">
    <label for="name">Name</label>
    <input id="name" type="text" [(ngModel)]="queryName" [disabled]="query?.isSystem || query?.isDefault" tabindex="0"
      class="noMatFormControl">
  </div>
  @if (errorMessages.has('Name')) {
    <div class="errorText">{{ errorMessages.get('Name') }}.</div>
  }
</div>
<div style="margin-left:1em;margin-bottom:1em;">
  <mat-slide-toggle [(ngModel)]="allowOperator">
    Allow Operator Access
  </mat-slide-toggle>
</div>
<!-- filters *********************** -->
@if (queryColumns.data.length) {
  <mat-table [dataSource]="queryColumns">
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef>Column</mat-header-cell>
      <mat-cell *matCellDef="let column" style="padding:0 8px;">
        <select [(ngModel)]="column.key" [title]="column.key" class="noMatFormControl">
          <optgroup label="Normal Columns">
            @for (value of userColumns();track value.id) {
              <option [value]="value.id">
                {{ value.name }}
              </option>
            }
          </optgroup>
          <optgroup label="System Columns">
            @for (value of systemColumns(); track value) {
              <option [value]="value.id">
                {{ value.name }}
              </option>
            }
          </optgroup>
        </select>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="filter">
      <mat-header-cell *matHeaderCellDef>Filter Value</mat-header-cell>
      <mat-cell *matCellDef="let column" style="padding:0px;">
        <input type="text" [(ngModel)]="column.value.new" class="noMatFormControl" style="width:110px;">
        <button (click)="remove(column)" mat-icon-button style="margin-top:-10px;margin-left:-10px"
          [title]="'Remove the filter on column ‘' + column.key + '’'">
          <mat-icon>close</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <!--  <mat-footer-cell *matFooterCellDef class="mat-caption" colspan="2">-->
    <!--    Enter ? for filter value to be prompted.-->
  <!--  </mat-footer-cell>-->
  <mat-header-row *matHeaderRowDef="['name', 'filter']"></mat-header-row>
  <mat-row *matRowDef="let row; columns: ['name', 'filter']"></mat-row>
  <!--  <mat-footer-row *matFooterRowDef="['filter']"></mat-footer-row>-->
</mat-table>
}
@if (queryColumns.data.length !== allColumns.length) {
  <button mat-button id="add-column-button"
    title="Add a new column filter to this query" color="primary" (click)="newColumn()">
    <mat-icon>add_circle</mat-icon>
    Add Column Filter
  </button>
}
<!-- joins *********************** -->
<mat-card style="margin:0.25rem;" appearance="outlined">
  <mat-card-header>
    <mat-card-subtitle>Join Another Query</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div fxLayout="column" fxLayoutGap="1em" fxLayoutAlign="start space-evenly">
      <mat-slide-toggle [(ngModel)]="join">Enabled</mat-slide-toggle>
      @if (join) {
        <div fxLayout="column" fxLayoutGap="0.5rem" fxLayoutAlign="start space-evenly">
          <div fxLayout="column" fxLayoutGap="0.25rem">
            <label for="joinName">Column Prefix</label>
            <input id="joinName" type="text" [disabled]="!join" matInput [(ngModel)]="joinName" required
              class="noMatFormControl">
            <!--                        <mat-hint>A distinct prefix is required for child columns.</mat-hint>-->
            @if (errorMessages.has('JoinName')) {
              <div class="mat-caption errorText"
                [innerHtml]="errorMessages.get('JoinName')">
              </div>
            }
          </div>
          <div fxLayout="column" fxLayoutGap="0.25rem">
            <label for="joinToQueryID">Join To Query</label>
            <select id="joinToQueryID" [disabled]="!join" [(ngModel)]="joinToQueryID"
              (change)="joinQueryChanged($event)" class="noMatFormControl">
              @for (q of joinDataSources; track q) {
                <option [value]="q.query.id">
                  {{ q.label }}
                </option>
              }
            </select>
          </div>
          <div fxLayout="column" fxLayoutGap="0.25rem">
            <label for="joinParentField">Join Parent Column</label>
            <select id="joinParentField" [disabled]="!join" [(ngModel)]="joinParentField" class="noMatFormControl">
              @for (f of joinParentFields; track f) {
                <option [value]="f">
                  {{ f }}
                </option>
              }
            </select>
          </div>
          <div fxLayout="column" fxLayoutGap="0.25rem">
            <label for="joinChildField">Join Child Column</label>
            <select id="joinChildField" [disabled]="!join || !joinChildFields" [(ngModel)]="joinChildField"
              class="noMatFormControl">
              @for (f of joinChildFields; track f) {
                <option [value]="f">
                  {{ f }}
                </option>
              }
            </select>
          </div>
          <mat-slide-toggle [disabled]="!join || !joinChildFields" [(ngModel)]="joinMustMatch">Must match
          </mat-slide-toggle>
        </div>
      }
    </div>
  </mat-card-content>
</mat-card>
<ng-template #cantJoin>
  <div style="margin:1em;">
    <p>Joins not possible. This query may already be used as a child query or there are no other queries to join
    to.</p>
  </div>
</ng-template>
<!-- actions *********************** -->
<div fxLayout="row" fxLayoutGap="0.25rem" [class.is-editing]="isEditingQuery" style="padding: 4px">
  @if (isEditingQuery) {
    <button title="Discard changes made to this query" mat-stroked-button color="primary"
      (click)="finished({save: false})">
      Cancel
    </button>
  }
  <button mat-raised-button color="primary" title="Save changes to this query" [disabled]="!isQueryValid"
    (click)="finished({save: true})">
    <mat-icon>save</mat-icon>
    Save
  </button>
  @if (isEditingQuery && !query.isDefault && !query.isSystem) {
    <button require="DatamanDeleteQuery" mat-stroked-button
      color="warn" title="Delete this query" (click)="dataSource.delete(this.query)">
      <mat-icon>delete</mat-icon>
      Delete
    </button>
  }
</div>
