@if (showRegister) {
  <button data-cy="register-button" mat-raised-button color="accent" [routerLink]="['/signup']"
    fxHide.lt-sm>
    Sign Up
  </button>
}
@if (showLogin) {
  <button data-cy="signin-button" mat-button [routerLink]="['/signin']">
    <mat-icon>login</mat-icon> Sign In
  </button>
}
