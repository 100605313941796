export class Problem {
  status: string = '';
  type: string = '';
  title: string = '';
  detail: string = '';
  instance: string = '';

  constructor(problem: Problem) {
    if (problem) {
      Object.assign(this, problem);
    }
  }
}

export class ValidationProblem
  extends Problem {
  errors: { [index: string]: Array<string> } = {};
}
