<app-dialog-title title="Your Profile" icon="person"></app-dialog-title>

<div mat-dialog-content>
  <form [formGroup]="form" novalidate>
    <div fxLayout="column" fxLayoutAlign="start">
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input data-cy="uprof-name-input" type="text" matInput formControlName="name" />
        <mat-error>A name is required.</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Login</mat-label>
        <input data-cy="uprof-login-input" type="text" matInput formControlName="login" readonly />
      </mat-form-field>

      <mat-card class="bottom">
        <mat-card-header>
          <mat-card-subtitle>Change Password</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <app-change-password></app-change-password>
        </mat-card-content>
      </mat-card>

      <mat-card class="bottom">
        <mat-card-header>
          <mat-card-subtitle>Subscribe to Email Messages</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <div fxLayout="column" fxLayoutAlign="start stretch" class="wide">
            <mat-checkbox data-cy="uprof-alerts-check" formControlName="systemalerts">System alerts and status messages
            </mat-checkbox>
            <mat-checkbox data-cy="uprof-news-check" formControlName="news">Product Announcements, Tips and Tricks
            </mat-checkbox>
          </div>
        </mat-card-content>
      </mat-card>

    </div>
  </form>
</div>

<div mat-dialog-actions align="end">
  <button type="button" mat-raised-button [matDialogClose]="true">
    <mat-icon>cancel</mat-icon> Cancel
  </button>
  <button data-cy="uprof-submit-button" type="button" mat-raised-button color="primary"
    [disabled]="!form.valid || !form.dirty" (click)="goAhead()">
    <mat-icon>done</mat-icon> OK
  </button>
</div>
