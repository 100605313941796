import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ServiceBase } from './serviceBase';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService extends ServiceBase {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  postFeedback(message: string, mood: string): Observable<boolean> {
    const headers = this.getHeaders();
    const parms = new HttpParams()
      .set('message', message);

    const fbMessage = { message : message, mood: mood };

    return this.httpClient.post<boolean>(environment.feedbackApiUrl,
      fbMessage,
      {
        headers: headers,
        params: parms
      });
  }
}
