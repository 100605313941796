import { Shape } from './Shape';
import { ShapeComponent, ShapeComponentType } from './ShapeComponent';
import { Point } from '../Dimensions';

export enum GEOMETRY_STYLE {
  RECTANGLE = 1,
  CIRCLE = 2,
  ELLIPSE = 3,
  TRIANGLE = 4,
  POINTER = 5
}

export class GeometryShape extends Shape<GEOMETRY_STYLE> {

  static ShapeType = 'shape';

  constructor(shape?: GeometryShape) {
    super(shape);
    this.shapeName = 'Shape';
  }

  get isCentreOrigin(): boolean {
    return false;
    // return (
    //   +this.style === GEOMETRY_STYLE.CIRCLE ||
    //   +this.style === GEOMETRY_STYLE.ELLIPSE
    // );
  }

  makeComponents(): Array<ShapeComponent> {

    const components = new Array<ShapeComponent>();
    const newComp = new ShapeComponent().set(0, 0, 1, 1, ShapeComponentType.GEOMETRY, 1);
    // newComp.border = new BorderStyle(this.border);

    switch (this.style) {
      case GEOMETRY_STYLE.RECTANGLE: {
        break;
      }
      case GEOMETRY_STYLE.CIRCLE: {
        this.keepProportional = true;
        break;
      }
      case GEOMETRY_STYLE.ELLIPSE: {
        break;
      }
      case GEOMETRY_STYLE.TRIANGLE: {
        break;
      }
      case GEOMETRY_STYLE.POINTER: {
        const points = new Array<Point>();
        points.push(new Point(0.5, 0));
        points.push(new Point(0, 0.3));
        points.push(new Point(0.3, 0.3));
        points.push(new Point(0.3, 1));
        points.push(new Point(0.7, 1));
        points.push(new Point(0.7, 0.3));
        points.push(new Point(1, 0.3));
        points.push(new Point(0.5, 0));
        newComp.points = points;
        break;
      }
    }

    // newComp.sizeToShape(this);
    components.push(newComp);

    return components;
  }

  updateStyle(newStyle?: GEOMETRY_STYLE): void {

    // convert origin between centre and top-left
    if (newStyle) {
      // if (this.isCentreOrigin) {
      //   if (+newStyle !== GEOMETRY_STYLE.CIRCLE && +newStyle !== GEOMETRY_STYLE.ELLIPSE) {
      //     this.origin.x = this.origin.x - this.extent.width / 2;
      //     this.origin.y = this.origin.y - this.extent.height / 2;
      //   }
      // } else {
      //   if (+newStyle === GEOMETRY_STYLE.CIRCLE || +newStyle === GEOMETRY_STYLE.ELLIPSE) {
      //     this.origin.x = this.origin.x + this.extent.width / 2;
      //     this.origin.y = this.origin.y + this.extent.height / 2;
      //   }
      // }
      this.style = +newStyle;
      this.keepProportional = (this.style === GEOMETRY_STYLE.CIRCLE);
    }
  }


}
