<form [formGroup]="form">
  <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="1em">
    <div fxLayout="column" fxLayoutAlign="start stretch">
      <mat-form-field class="wide">
        <mat-label>Current Password</mat-label>
        <input data-cy="pwd-current-input" type="password" matInput formControlName="password"
          placeholder="Current Password" class="form-control" required autocomplete="new-password" />
        @if (form?.controls.password.invalid && (form.dirty || form?.controls.password.touched)) {
          <div>
            @if (form?.controls.password.errors.required) {
              <mat-error> Password is required. </mat-error>
            }
          </div>
        }
      </mat-form-field>
      <mat-form-field class="wide">
        <mat-label>New Password</mat-label>
        <input data-cy="pwd-new-input" type="password" matInput formControlName="newpassword" placeholder="New Password"
          required pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" autocomplete="new-password" />
        @if (form?.controls.newpassword.invalid && (form.dirty || form?.controls.newpassword.touched)) {
          <div>
            @if (form?.controls.newpassword.errors.required) {
              <mat-error> New Password is required. </mat-error>
            }
            @if (form?.controls.newpassword.errors.pattern) {
              <mat-error> Must contain at least one number and one
                uppercase and
                lowercase
                letter,
              and at least 8 or more characters.</mat-error>
            }
          </div>
        }
      </mat-form-field>
    </div>
  </div>
</form>
<div fxLayout="column" fxLayoutGap="0.5em">
  <div>
    @if (busy) {
      <simple-progress message='Updating'></simple-progress>
    } @else {
      <button data-cy="pwd-submit-button" mat-stroked-button [disabled]="!form.valid" (click)="update()">Update</button>
      <span>{{message}}</span>
    }
    <ng-template #controls>
      <button data-cy="pwd-submit-button" mat-stroked-button [disabled]="!form.valid" (click)="update()">Update</button>
      <span>{{message}}</span>
    </ng-template>
  </div>
  <div>
    @if (!!error) {
      <app-warning-panel [error]='true'>{{error}}</app-warning-panel>
    }
  </div>
</div>