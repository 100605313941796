import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import { environment } from '../../environments/environment';
import { LabelHeader } from '../models/server/Label';
import { PagedResponse } from '../models/server/PagedResponse';
import { Query } from '../models/server/Query';
import { Table, TableRow } from '../models/server/Table';
import { ServiceBase } from './serviceBase';
import {RowSelection} from "../models/server/PrintRequest";
import {QuerySelected} from "../models/server/QuerySelected";
import {QuerySelection} from "../models/view/query-selection";

@Injectable({
  providedIn: 'root'
})
export class DataManagerService
  extends ServiceBase {

  // observer when any tables are updated
  tablesUpdated = new Subject<Table[]>();

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  validateNewTable(table: Table): Observable<Table> {
    const headers = this.getHeaders();
    return this.httpClient.post<Table>(environment.datamanApiUrl + '/tables/validate',
      table, { headers }
    ).pipe(
      map(t => new Table(t))
    );
  }

  validateNewQuery(query: Query): Observable<Query> {
    const headers = this.getHeaders();
    return this.httpClient.post<Query>(environment.datamanApiUrl + '/query/validate',
      query, { headers }
    ).pipe(
      map(q => new Query(q))
    );
  }

  processUploadedFile(
    id: string,
    firstRowIsHeaders: boolean,
    importAllSheets: boolean,
    tableName: string,
    replaceExisting: boolean): Observable<Table[]> {

    const headers = this.getHeaders();
    const params = new HttpParams()
      .set('id', id)
      .set('firstRowIsHeaders', `${firstRowIsHeaders}`)
      .set('importAllSheets', `${importAllSheets}`)
      .set('tableNameOverride', tableName)
      .set('replaceExisting', `${replaceExisting}`);

    return this.httpClient.get<Table[]>(
      environment.datamanApiUrl + '/tables/import',
      {
        headers, params,
        responseType: 'json'
      }).pipe(
        map((tbls: Table[]) => {
          if (tbls) {
            let newTables = tbls.map(tbl => {
              const nuTbl = new Table(tbl);
              return nuTbl;
            });

            this.tablesUpdated.next(newTables);
            return newTables;
          }
          return null;
        })
      );
  }

  renameTable(id: string, newName: string): Observable<boolean> {
    const headers = this.getHeaders();
    const params = new HttpParams()
      .set('id', id)
      .set('newName', newName);

    return this.httpClient.post<boolean>(environment.datamanApiUrl + '/tables/rename',
      null,
      { headers, params }
    ).pipe(
      tap(x => {
        this.tablesUpdated.next(null);
        return x;
      })
    );
  }

  updateTable(table: Table): Observable<Table> {
    const headers = this.getHeaders();
    return this.httpClient.post<Table>(environment.datamanApiUrl + '/tables',
      table,
      { headers }
    ).pipe(
      map(t => {
        const tbl = new Table(t);
        this.tablesUpdated.next([tbl]);
        return tbl;
      })
    );
  }

  deleteTable(tableID: string): Observable<boolean> {
    const headers = this.getHeaders();
    const params = new HttpParams()
      .set('id', tableID);

    return this.httpClient.delete<boolean>(
      environment.datamanApiUrl + '/tables',
      { headers, params }
    ).pipe(
      tap(x => {
        this.tablesUpdated.next(null);
        return x;
      })
    );
  }

  getTable(tableID: string): Observable<Table> {
    const headers = this.getHeaders();
    const params = new HttpParams()
      .set('id', tableID);

    return this.httpClient.get<Table>(
      environment.datamanApiUrl + '/tables',
      { headers, params }
    ).pipe(
      map(t => {
        return new Table(t);
      })
    );
  }

  getTableList(page: number, pageSize: number): Observable<PagedResponse<Table>> {
    const headers = this.getHeaders();
    const params = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());

    return this.httpClient.get<PagedResponse<Table>>(
      environment.datamanApiUrl + '/tables/list',
      { headers, params }
    )
    // .pipe(
    //   map(a => {
    //     a.page = 0;
    //     a.pageSize = 0;
    //     a.totalItems = 0;
    //     a.totalPages = 0;
    //     a.values = [];
    //     return a;
    //   })
    // );
  }

  getTableData(id: string, page: number, pageSize: number, searchInput: string = null): Observable<PagedResponse<TableRow>> {

    const headers = this.getHeaders();
    let params = new HttpParams()
      .set('id', id)
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());

    if (searchInput) {
      params = params.set('search', searchInput);
    }

    return this.httpClient.get<PagedResponse<TableRow>>(
      environment.datamanApiUrl + '/tables/data',
      { headers, params }
    );

  }

  listTableRows(id: string, startRow: number, endRow: number): Observable<TableRow[]> {

    const headers = this.getHeaders();
    const params = new HttpParams()
      .set('id', id)
      .set('startRow', startRow.toString())
      .set('endRow', endRow.toString());

    return this.httpClient.get<TableRow[]>(
      environment.datamanApiUrl + '/tables/rows',
      { headers, params }
    );
  }

  deleteRows(tableID: string, rowidsToDelete: string[], excludedRowIds: string[] = null): Observable<TableRow> {

    const deleteAll = rowidsToDelete?.length === 0;

    const headers = this.getHeaders();
    const params = new HttpParams()
      .set('tableid', tableID)
      .set('all', deleteAll.toString());

    return this.httpClient.delete<TableRow>(
      environment.datamanApiUrl + '/tables/rows',
      { headers, params, body: deleteAll ? excludedRowIds : rowidsToDelete }
    ).pipe(
      map(res => new TableRow(res))
    );
  }


  getTableLabels(id: string): Observable<Array<LabelHeader>> {

    const headers = this.getHeaders();
    const params = new HttpParams()
      .set('id', id);

    return this.httpClient.get<Array<LabelHeader>>(
      environment.datamanApiUrl + '/tables/labels',
      { headers, params }
    ).pipe(
      map(labels => (labels || []).map(l => new LabelHeader(l)))
    );
  }

  getQuery(queryID: string): Observable<Query> {
    const headers = this.getHeaders();
    const params = new HttpParams()
      .set('id', queryID);

    return this.httpClient.get<Query>(
      environment.datamanApiUrl + '/query',
      { headers, params }
    ).pipe(
      map(query => new Query(query))
    );
  }

  getQueryListAllTables(page: number, pageSize: number): Observable<PagedResponse<Query>> {
    const headers = this.getHeaders();
    const params = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());

    return this.httpClient.get<PagedResponse<Query>>(
      environment.datamanApiUrl + '/query/list',
      { headers, params }
    ).pipe(
      map(resp => new PagedResponse<Query>(resp, Query))
    );
  }

  getQueryList(tableID: string, page: number, pageSize: number): Observable<PagedResponse<Query>> {
    const headers = this.getHeaders();
    const params = new HttpParams()
      .set('id', tableID)
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());

    return this.httpClient.get<PagedResponse<Query>>(
      environment.datamanApiUrl + '/query/list',
      { headers, params }
    ).pipe(
      map(queries => {
        const p = new PagedResponse(queries, Query);
        p.values = p.values.sort((q1: Query, q2: Query) =>
          q1.isDefault ? -1 : (q2.isDefault ? 1 : q1.name.localeCompare(q2.name))
        );
        return p;
      }),
    );
  }

  getQueryLabels(queryID: string): Observable<Array<LabelHeader>> {
    const headers = this.getHeaders();
    const params = new HttpParams()
      .set('id', queryID);

    return this.httpClient.get<Array<LabelHeader>>(
      environment.datamanApiUrl + '/query/labels',
      { headers, params }
    );
  }

  getAllQueries(): Observable<Query[]> {
    const headers = this.getHeaders();

    return this.httpClient.get<Query[]>(
      environment.datamanApiUrl + '/query/listall',
      {
        headers: headers
      });
  }

  updateQuery(query: Query): Observable<Query> {
    const headers = this.getHeaders();
    return this.httpClient.post<Query>(
      environment.datamanApiUrl + '/query',
      query, { headers }
    ).pipe(
      map(query => new Query(query))
    );
  }

  deleteQuery(id: string): Observable<boolean> {
    const headers = this.getHeaders();
    const parms = new HttpParams()
      .set('id', id);

    return this.httpClient.delete<boolean>(
      environment.datamanApiUrl + '/query',
      {
        headers: headers,
        params: parms
      });
  }

  countRecords(query: Query): Observable<number> {
    const headers = this.getHeaders();
    return this.httpClient.post<number>(
      environment.datamanApiUrl + '/query/count',
      query, { headers });
  }

  executeQuery(query: Query, page: number, pageSize: number, searchInput: string = null): Observable<PagedResponse<TableRow>> {
    const headers = this.getHeaders();
    let params = new HttpParams()
      // .set('id', query.id)
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());

    if (searchInput) {
      params = params.set('search', searchInput);
    }

    return this.httpClient.post<PagedResponse<any>>(
      environment.datamanApiUrl + '/query/execute',
      query, { headers, params });
    // ).pipe(
    //   map(response =>
    //     <PagedResponse<TableRow>>{
    //       page: response.page,
    //       pageSize: response.pageSize,
    //       totalItems: response.totalItems,
    //       totalPages: response.totalPages,
    //       values: response.values.map(column => <TableRow>{
    //         id: column.__id,
    //         tableID: query.tableID,
    //         key: column.__key,
    //         created: column.__created,
    //         values: column
    //       })
    //     }
    //   )
    // );
  }

  execute(source: Query | Table, page: number, pageSize: number, searchInput: string = null): Observable<PagedResponse<TableRow>> {
    return source instanceof Table ?
      this.getTableData(source.id, page, pageSize, searchInput) :
      this.executeQuery(source, page, pageSize, searchInput);
  }

  selectRows(query : Query, querySelection: QuerySelection, page: number, pageSize: number, searchInput: string = null): Observable<PagedResponse<TableRow>> {
    const headers = this.getHeaders();
    const rs = new RowSelection();
    const rowPrintState = querySelection.selectedRowState.value;

    let params = new HttpParams()
      // .set('id', query.id)
      .set('page', page.toString())
      .set('pageSize', pageSize.toString());

    if (searchInput) {
      params = params.set('search', searchInput);
    }

    if (rowPrintState !== 'none') {
      if(rowPrintState === 'some') {
        rs.selectedRows = querySelection.selectedRows.filter(
          // FYI: +true === 1, +false === 0
          sr => sr.copies > 0
        );
        rs.printAllRowsByDefault = false;
      } else {
        rs.unselectedRows = querySelection.unselectedRowIds;
        rs.printAllRowsByDefault = rs.unselectedRows.length > 0;
      }
    // } else if (querySelection.highlightedRowId) {
    //   // use the currently highlighted (but unselected) row as 1 copy
    //   rs.selectedRows = [{id: querySelection.highlightedRowId, copies: 1}];
    }

    let qs = new QuerySelected(query, rs, searchInput);

    return this.httpClient.post<PagedResponse<any>>(
      environment.datamanApiUrl + '/query/selectrows',
       qs, { headers, params });
  }

  addRows(id: string, rows: TableRow[]): Observable<TableRow[]> {
    const headers = this.getHeaders();
    const params = new HttpParams()
      .set('tableId', id.toString());

    return this.httpClient.post<TableRow[]>(
      environment.datamanApiUrl + '/tables/data',
      rows, { headers, params }
    ).pipe(
      map(response => response.map(row => new TableRow(row)))
    );
  }

  copyRows(id: string, rowIDs: string[]): Observable<TableRow[]> {
    const headers = this.getHeaders();
    const params = new HttpParams()
      .set('tableId', id.toString());

    return this.httpClient.post<TableRow[]>(
      environment.datamanApiUrl + '/tables/data/copy',
      rowIDs, { headers, params }
    ).pipe(
      map(response => response.map(row => new TableRow(row)))
    );
  }


  updateRow(row: TableRow): Observable<TableRow> {
    const headers = this.getHeaders();

    return this.httpClient.put<TableRow>(
      environment.datamanApiUrl + '/tables/data',
      row, { headers }
    ).pipe(
      map(response => new TableRow(response))
    );
  }

  deleteQueryRows(query: Query): Observable<boolean> {
    const headers = this.getHeaders();
    return this.httpClient.post<boolean>(
      environment.datamanApiUrl + '/query/deleteRows',
      query, { headers }
    );
  }

  createLabelGroupTable(tableName: string): Observable<Table> {
    const headers = this.getHeaders();
    const params = new HttpParams()
      .set('tableName', tableName);

    return this.httpClient.post<Table>(
      environment.datamanApiUrl + '/tables/labelGroup',
      null,
      { headers, params }
    ).pipe(
      map(response => new Table(response))
    );
  }

  exportData(tableId: string, queryId: string): Observable<Blob> {
    const exportUrl = environment.datamanApiUrl + '/export';
    let parms = new HttpParams()
      .set('tableId', tableId);

    if (queryId) {
      parms = parms.set('queryId', queryId);
    }

    return this.httpClient
      .get(exportUrl,
        {
          params: parms,
          responseType: 'blob'
        });
    //.pipe(
    //      catchError((err, ob) => of(null)),
    //      tap(x => {
    //        return x;
    //      })
    //    );
  }
}
