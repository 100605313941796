import { User } from './User';
import { UserInvite } from './UserInvite';
import { OrganisationFont } from './OrganisationFont';
import { Address } from './Address';
import { FormattedExpression } from './FormattedExpression';

export class Organisation {

  public id: string;
  public name: string;
  public address: Address;
  public phone: string;
  public email: string;
  public webSite: string;
  public cultureCode: string;
  public subscriberID: string;
  public fonts: OrganisationFont[];
  public countryCode: string;
  public billByInvoice: boolean;
  public createDate: Date;
  public deactivateDate: Date;
  public deactivateReason: string;
  public deleteDate: Date;
  public deleteReason: string;
  public trialEnds?: Date;

  public users: User[];
  public invitedUsers: UserInvite[];
  public expressions: FormattedExpression[];

  public get isActivated(): boolean {
    return !!this.countryCode;
  }

  public get isDeactivated(): boolean {
    return !!this.deactivateDate;
  }

  public get isTrialExpired(): boolean {
    if(this.trialEnds) {
      let tomorrow = new Date(Date.now());
      tomorrow.setDate(tomorrow.getDate() + 1);

      if (Date.parse(this.trialEnds.toString()) < tomorrow.valueOf()) {
        return true;
      }
    }
    return false;
  }

  constructor(org?: Organisation) {
    this.address = new Address();
    this.update(org);
  }

  update(org?: Organisation): Organisation {
    if (org) {
      this.id = org.id;
      this.name = org.name;
      this.address = new Address(org.address);
      this.phone = org.phone;
      this.email = org.email;
      this.webSite = org.webSite;
      this.cultureCode = org.cultureCode;
      this.subscriberID = org.subscriberID;
      this.fonts =  org.fonts?.map(fnt => new OrganisationFont(fnt));
      this.countryCode = org.countryCode;
      this.billByInvoice = org.billByInvoice;
      this.createDate = org.createDate;
      this.deactivateDate = org.deactivateDate;
      this.deactivateReason = org.deactivateReason;
      this.deleteDate = org.deleteDate;
      this.deleteReason = org.deleteReason;
      this.expressions = org.expressions?.map(exp => new FormattedExpression(exp));
      this.trialEnds = org.trialEnds;
    }
    return this;
  }
}
