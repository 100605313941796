import { Extent, Point, Boundary } from './Dimensions';
import { FillStyle } from './FillStyle';
import { BorderStyle } from './BorderStyle';
import { GeometryShape, GEOMETRY_STYLE } from './Shapes/GeometryShape';
import { TextBlock } from './TextBlock';
import { ImageShape } from './Shapes/ImageShape';
import { BarcodeShape, BARCODE_STYLE } from './Shapes/BarcodeShape';
import { LineShape } from './Shapes/LineShape';
import { PriceShape, PRICE_STYLE } from './Shapes/PriceShape';
import { ShapeBase, IShape } from './Shapes/Shape';
import { GridShape, GRID_STYLE } from './Shapes/GridShape';
import { TextShape } from './Shapes/TextShape';
import { NutritionPanelShape, NUTRITIONAL_STYLE, NutritionPanelItem } from './Shapes/NutritionPanelShape';
import { ListShape, LIST_STYLE } from './Shapes/ListShape';
import { ShapeGroup } from './Shapes/ShapeGroup';
import { FormattedExpression } from './FormattedExpression';

export class ShapeFactory {

  public static createDefaultShape(shapeName: string, cx: number, cy: number, units: string, properties: any = null): IShape {

    let defWidth: number, defHeight: number, defRadius: number,
      defLineWeight: number, defTextWidth: number, defTextHeight: number;

    if (!shapeName) {
      return null;
    }

    if (units === 'mm') {
      defWidth = 20;
      defTextWidth = 25;
      defHeight = 10;
      defTextHeight = 5;
      defRadius = 15;
      defLineWeight = 0.3;
    } else {
      defWidth = 1;
      defTextWidth = 1;
      defHeight = 0.75;
      defTextHeight = 1 / 8;
      defRadius = 0.35;
      defLineWeight = 1 / 32;
    }

    const defFont = 'Arial';
    const black = 'rgb(0,0,0)';
    const white = 'rgb(255,255,255)';
    const transparent = 'rgba(0,0,0,0)';
    const someText = 'Double click me';
    const topLeftPoint = new Point(cx + 5, cy + 5); // new Point(defWidth * 1.5, defHeight * 1.5);

    const defBorder = new BorderStyle();
    defBorder.lineWidth = defLineWeight;
    defBorder.color = black;

    const noBorder = new BorderStyle();
    noBorder.lineWidth = 0;
    noBorder.color = black;

    switch (shapeName.toLowerCase()) {
      case TextShape.ShapeType:
        const t = new TextShape();
        t.extent = new Extent(defTextWidth, defTextHeight);
        t.origin = topLeftPoint;
        t.fill = new FillStyle(transparent);
        t.border = noBorder;
        t.text = new TextBlock();
        t.text.content = someText;
        t.text.format.font = defFont;
        t.text.format.size = 10;
        t.text.format.color = black;
        t.text.format.padding = new Boundary();
        t.text.format.horizontalAlignment = 'left';
        t.text.format.justify = 'left';
        t.text.format.wordWrap = true;
        t.text.format.wordWrapLength = 9999;
        t.text.format.shrinkToFit = false;
        t.text.format.expandToFit = false;

        if (properties) {
          Object.assign(t, properties);
        }

        return t;

      case LineShape.ShapeType:
        const line = new LineShape();
        line.text = new TextBlock();
        line.origin = topLeftPoint;
        line.length = defTextWidth;
        line.border = defBorder; // border is used for line thickness
        line.fill = new FillStyle(black);
        line.text = new TextBlock();

        line.text.content = '';
        line.text.format.font = defFont;
        line.text.format.size = 10;
        line.text.format.color = black;
        line.text.format.padding = new Boundary();
        line.text.format.justify = 'left';
        line.text.format.wordWrap = false;
        line.text.format.shrinkToFit = true;
        line.text.format.expandToFit = true;

        if (properties) {
          Object.assign(line, properties);
        }

        return line;

      case GeometryShape.ShapeType:
        const r = new GeometryShape();
        r.extent = new Extent(defWidth, defHeight);
        r.origin = topLeftPoint;
        r.fill = new FillStyle(transparent);
        r.border = defBorder;
        r.text = new TextBlock(null);
        r.style = GEOMETRY_STYLE.RECTANGLE;
        r.text.format.color = black;
        r.text = new TextBlock();

        r.text.content = '';
        r.text.format.font = defFont;
        r.text.format.size = 10;
        r.text.format.color = black;
        r.text.format.padding = new Boundary();
        r.text.format.justify = 'left';
        r.text.format.wordWrap = false;
        r.text.format.shrinkToFit = true;
        r.text.format.expandToFit = true;

        if (properties) {
          Object.assign(r, properties);
        }

        return r;

      case ImageShape.ShapeType:
        const i = new ImageShape();
        // i.imageFileID = ExternalFile.defaultImageId;
        // i.imageFileName = 'Moaner';
        i.keepProportional = true;
        i.extent = new Extent(defWidth, defHeight);
        i.origin = topLeftPoint;
        // i.line = defBorder ;
        // i.border = defBorder;
        i.border = null;
        i.colorThreshold = 255/2;
        // i.opacity = 1;

        i.text = new TextBlock();
        i.text.content = '';
        i.text.format.font = defFont;
        i.text.format.size = 10;
        i.text.format.color = black;
        i.text.format.padding = new Boundary();
        i.text.format.justify = 'left';
        i.text.format.wordWrap = false;
        i.text.format.shrinkToFit = true;
        i.text.format.expandToFit = true;

        if (properties) {
          Object.assign(i, properties);
        }


        return i;

      case BarcodeShape.ShapeType:
        const bc = new BarcodeShape();
        bc.extent = new Extent(defWidth * 3, defHeight);
        bc.origin = topLeftPoint;
        bc.style = BARCODE_STYLE.AUTO;
        //   bc.data = '1234567890';BARCODEDATA
        bc.fill = new FillStyle(transparent);

        bc.text = new TextBlock();
        bc.text.content = '1234567890';
        bc.text.format.font = 'OCRB';
        bc.text.format.size = 10;
        bc.text.format.color = black;
        bc.text.format.padding = new Boundary();
        bc.text.format.justify = 'center';
        bc.text.format.wordWrap = false;
        bc.text.format.shrinkToFit = false;
        bc.text.format.expandToFit = false;

        if (properties) {
          Object.assign(bc, properties);
        }

        return bc;

      case NutritionPanelShape.ShapeType:
        const np = new NutritionPanelShape();
        np.extent = new Extent(defWidth * 2, defHeight * 3);
        np.origin = topLeftPoint;
        np.border = defBorder;
        np.style = NUTRITIONAL_STYLE.STANDARD;
        np.serves = 5;
        np.serveSize = 33;
        np.units = 'g';
        np.decimalplaces = 2;
        np.heading = 'NUTRITION INFORMATION';
        np.serveSizeHeading = 'Serve Size: ';
        np.standardServeSize = 100;
        np.servesPerPackHeading = 'Servings Per Pack: ';
        np.perServeHeading = 'Per Serve';
        np.perStandardSizeHeading = 'Per';
        np.serves = 10;
        np.text = new TextBlock();
        np.items = [
          new NutritionPanelItem(1, 'Energy', '1.2', '', 'kj', '0', ''),
          new NutritionPanelItem(2, 'Protein', '11.2', '', 'g', '0', ''),
          new NutritionPanelItem(3, 'Gluten', '0', '', 'mg', '0', ''),
          new NutritionPanelItem(4, 'Fat', '12.6', '', 'g', '0', ''),
          new NutritionPanelItem(5, 'Fat - Saturated', '3.5', '', 'g', '0', ''),
          new NutritionPanelItem(6, 'Carbohydrate', '19.4', '', 'g', '0', ''),
          new NutritionPanelItem(7, 'Carbohydrate - Sugars', '2.8', '', 'g', '0', ''),
          new NutritionPanelItem(8, 'Dietary Fibre', '2.4', '', 'g', '0', ''),
          new NutritionPanelItem(9, 'Sodium', '500', '', 'mg', '0', '')
        ];

        np.fill = new FillStyle(white);

        np.text = new TextBlock();
        np.text.content = someText;
        np.text.format.font = defFont;
        np.text.format.size = 10;
        np.text.format.color = black;
        np.text.format.padding = new Boundary();
        np.text.format.justify = 'center';
        np.text.format.wordWrap = false;
        np.text.format.shrinkToFit = true;
        np.text.format.expandToFit = true;

        if (properties) {
          Object.assign(np, properties);
        }

        return np;

      case ListShape.ShapeType:
        const l = new ListShape();
        l.extent = new Extent(defWidth, defHeight * 3);
        l.origin = topLeftPoint;
        l.style = LIST_STYLE.NO_BULLET;
        l.items = ['Apples', 'Bananas', 'Oranges'];
        l.fill = new FillStyle(transparent);
        l.text = new TextBlock();

        l.text.content = '';
        l.text.format.font = defFont;
        l.text.format.size = 10;
        l.text.format.color = black;
        l.text.format.padding = new Boundary();
        l.text.format.justify = 'left';
        l.text.format.wordWrap = true;
        l.text.format.wordWrapLength = 99;
        l.text.format.shrinkToFit = true;
        l.text.format.expandToFit = true;

        if (properties) {
          Object.assign(l, properties);
        }

        return l;

      case PriceShape.ShapeType:
        const p = new PriceShape();
        p.extent = new Extent(defWidth, defHeight);
        p.border = noBorder;
        p.origin = topLeftPoint;
        p.style = PRICE_STYLE.PRICESTYLE_4;
        p.fill = new FillStyle(transparent);

        p.text = new TextBlock();
        p.text.content = '$19.95;each';
        p.text.format.font = defFont;
        p.text.format.size = 10;
        p.text.format.color = black;
        p.text.format.padding = new Boundary();
        p.text.format.justify = 'left';
        p.text.format.wordWrap = false;
        p.text.format.shrinkToFit = true;
        p.text.format.expandToFit = true;

        if (properties) {
          Object.assign(p, properties);
        }

        return p;

      case GridShape.ShapeType:
        const g = new GridShape();
        g.extent = new Extent(defWidth * 2, defHeight * 2);
        g.origin = topLeftPoint;
        g.border = defBorder;
        g.style = GRID_STYLE.NORMAL;
        g.cells = [['One', 'Two'], ['Three', 'Four']];
        g.rows = 2;
        g.columns = 2;
        g.progressPercentage = 0;
        g.fill = new FillStyle(transparent);
        g.text = new TextBlock();
        g.text.content = '';
        g.text.format.font = defFont;
        g.text.format.size = 10;
        g.text.format.color = black;
        g.text.format.padding = new Boundary();
        g.text.format.justify = 'left';
        g.text.format.wordWrap = false;
        g.text.format.shrinkToFit = true;
        g.text.format.expandToFit = true;

        if (properties) {
          Object.assign(g, properties);
        }

        return g;
    }
    return null;
  }

  public static createShapeForVar(variable: FormattedExpression, cx: number, cy: number, units: string, properties: any): IShape {

    const shape = this.createDefaultShape(TextShape.ShapeType, cx, cy, units, properties);
    shape.text.content = `[[${variable.name}:${variable.name}]]`;
    return shape;
  }

  public static cloneShape(shape: ShapeBase): IShape {
    if (shape && shape.shapeName) {
      switch (shape.shapeName.toLowerCase()) {
        case ShapeGroup.ShapeName:

          const gs = shape as ShapeGroup;
          if (gs?.shapes) {
            gs.shapes = gs.shapes.map(s => this.cloneShape(s));
          }

          return new ShapeGroup(gs);

        case GeometryShape.ShapeType:
          return new GeometryShape(<GeometryShape>shape);
        case ImageShape.ShapeType:
          return new ImageShape(<ImageShape>shape);
        case TextShape.ShapeType:
          return new TextShape(<TextShape>shape);
        case NutritionPanelShape.ShapeType:
          return new NutritionPanelShape(<NutritionPanelShape>shape);
        case BarcodeShape.ShapeType:
          return new BarcodeShape(<BarcodeShape>shape);
        case ListShape.ShapeType:
          return new ListShape(<ListShape>shape);
        case GridShape.ShapeType:
          return new GridShape(<GridShape>shape);
        case PriceShape.ShapeType:
          return new PriceShape(<PriceShape>shape);
        case LineShape.ShapeType:
          return new LineShape(<LineShape>shape);

      }
    }
    return null;
  }
}
