<app-dialog-title title="barxui Update" icon="system_update_alt"></app-dialog-title>

<div mat-dialog-content>
  <p>It appears you do not have the latest version of barxui in your browser.</p>
  <p>You should refresh your browser now by clicking the Reload button below.<br/>You may need to manually clear your
    browser caches if this doesn't work.</p>
  <p>You can see a summary of recent changes to barxui <a [href]="startPageUrl" target="blank">here</a>.</p>
</div>

<div mat-dialog-actions align="end">
  @if (reloading) {
    <simple-progress message='Reloading'></simple-progress>
  }
  <button type="button" mat-raised-button mat-dialog-close>
    <mat-icon>cancel</mat-icon>
    Cancel
  </button>
  @if (!reloading) {
    <button mat-raised-button color='primary' (click)="reload($event)">
      <mat-icon>system_update_alt</mat-icon>
      Reload
    </button>
  }
</div>
