import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import {of, Subscription} from 'rxjs';
import {AuthenticationService} from '../authentication/authentication.service';
import {LocalStorageKeys} from "../models/server/Constants";
import {OrganisationService} from "../services/organisation.service";
import {switchMap} from "rxjs/operators";
import { HomePricingComponent } from '../home-pricing/home-pricing.component';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { FlexModule } from '@angular/flex-layout/flex';
import { HomeStartComponent } from '../home-start/home-start.component';

@Component({
    selector: 'home-component',
    templateUrl: 'home.component.html',
    styleUrls: ['./home.component.scss'],
    standalone: true,
    imports: [HomeStartComponent, FlexModule, ExtendedModule, HomePricingComponent]
})
export class HomeComponent implements OnInit, OnDestroy {

  private authSub: Subscription;

  isAuthenticated: boolean;
  orgIsActivated: boolean = false;

  constructor(
    public authService: AuthenticationService,
    private orgService: OrganisationService
  ) {

  }

  ngOnInit() {

    this.authSub = this.authService.isAuthenticated.pipe(
      switchMap(isAuthenticated => {
        this.isAuthenticated = isAuthenticated;
        if(!this.isAuthenticated) {
          return of(null);
        }
        return this.orgService.getOrganisation();
      }),
    )
    .subscribe(org => {
      this.orgIsActivated = org?.isActivated;
    });
    localStorage.removeItem(LocalStorageKeys.LastPage);
  }

  ngOnDestroy() {
    if (this.authSub) {
      this.authSub.unsubscribe();
      this.authSub = null;
    }
  }
}
