@if (showStatus) {
  <mat-chip-listbox routerLink="/settings/organisation" selectable="false">
    @switch (status) {
      @case ('TRIALING') {
        <mat-chip-option color="accent" highlighted="true">
          Trial Ends {{ organisation.trialEnds | date }}
        </mat-chip-option>
      }
      @case ('TRIALENDED') {
        <mat-chip-option color="warn" highlighted="true">
          Trial Expired {{ organisation.trialEnds | date }}
        </mat-chip-option>
      }
      @case ('SUBTRIALING') {
        <mat-chip-option color="accent" highlighted="true">
          Subscription Starts {{ currentSub.start | date }}
        </mat-chip-option>
      }
      @case ('EXPIRED') {
        <mat-chip-option color="warn" highlighted="true">
          Subscription Expired {{ currentSub.endedAt | date }}
        </mat-chip-option>
      }
      @case ('OVERDUE') {
        <mat-chip-option color="warn" highlighted="true">
          Subscription Overdue
        </mat-chip-option>
      }
      @case ('CANCELLED') {
        <mat-chip-option color="primary" highlighted="true">
          Subscription Cancelled
        </mat-chip-option>
      }
      @case ('DEACTIVATED') {
        <mat-chip-option color="warn" highlighted="true" (click)="showDeactivation()"
                         [matTooltip]="statusMessage">
          Subscription Deactivated
        </mat-chip-option>
      }
      @case ('NONE') {
        <mat-chip-option color="warn" highlighted="true">
          Not Activated
        </mat-chip-option>
      }
    }
  </mat-chip-listbox>
}
