import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
    selector: 'app-bganimation',
    template: '',
    standalone: true
})
export class BGAnimationComponent implements OnInit, OnDestroy {


  @Input()
  tagName: string;

  @Input()
  tagId: string;

  @Input()
  className: string;

  constructor() { }

  ngOnInit(): void {

    if(!!this.className) {
      if(!!this.tagName) {
        let elements = document.getElementsByTagName(this.tagName);
        for(let i = 0; i < elements.length; i++) {
          elements.item(i).classList.add(this.className);
        }
      } else if(!!this.tagId) {
        document.getElementById(this.tagId).classList.add(this.className);
      }
    }
  }

  ngOnDestroy() : void {
    if(!!this.className) {
      if(!!this.tagName) {
        let elements = document.getElementsByTagName(this.tagName);
        for(let i = 0; i < elements.length; i++) {
          elements.item(i).classList.remove(this.className);
        }
      } else if(!!this.tagId) {
        document.getElementById(this.tagId).classList.remove(this.className);
      }
    }
  }
}
