import { environment } from '../../../../environments/environment';

export class HexcomProductSubscriptionItem {
    public productID: string;
    public planID: string;
    public quantity: number;
    public trialEnd?: Date;
    public trialStart?: Date;

    constructor(item: HexcomProductSubscriptionItem = null) {
        if (item) {
            Object.assign(this, item);
        }
    }
}

export class HexcomProductSubscription {

    public customerID: string;
    public planID: string;
    public id: string;
    public start?: Date;
    public endedAt?: Date;
    public currentPeriodStart?: Date;
    public currentPeriodEnd?: Date;
    public created?: Date;
    public canceledAt?: Date;
    public cancelAtPeriodEnd: boolean;
    public status: string;
    public items: Array<HexcomProductSubscriptionItem>;
    public taxZone: string;
    public couponID: string;

    get trialEnd(): Date {
        if (this.items) {
            return this.items[0].trialEnd;
        }
        return null;
    }

    get trialStart(): Date {
        if (this.items) {
            return this.items[0].trialStart;
        }
        return null;
    }

    get expansionIncluded(): number {
        const xp = this.items.find(i => i.productID === environment.expansionPackProductID && this.status !== 'cancelled');
        if (xp) {
            return xp.quantity;
        }
        return 0;
    }

    constructor(sub: HexcomProductSubscription = null) {
        if (sub) {
            Object.assign(this, sub);
            this.items = new Array<HexcomProductSubscriptionItem>();
            if (sub.items) {
                sub.items.forEach(i => this.items.push(new HexcomProductSubscriptionItem(i)));
            }
        }
    }

    
    hasActivePlan(planID: string): boolean {
        return this.items.findIndex(i => i.planID === planID && this.status !== 'cancelled') >= 0;
    }

    hasAnyActivePlan(productID: string): boolean {
        return this.items.findIndex(i => i.productID === productID && this.status !== 'cancelled') >= 0;
    }
}
