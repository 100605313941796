<style>
  .txt {
    display: inline-block;
    width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>

<div class="panel" fxLayout="row" fxLayoutAlign="flex-start center">
  @if (uploading) {
    <div>
      <simple-progress [message]="'Uploading ' + progressPercentage + '%'"></simple-progress>
    </div>
  } @else {
    <div style="width: 100%"
         fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="0.25rem"
         fileDND [fileType]='fileType' fileInputId='fileElem'
         (filesDropped)="filesDropped($event)" (filesError)="handleError($event)" [maxFileSize]="MAX_FILE_SIZE">
      @if (error) {
        <span style="font-weight: bold">{{ error }}</span>
      } @else {
        <span class="txt" (click)="fileElem.click()">{{ dropCaption }}</span>
      }
      <button mat-raised-button (click)="fileElem.click()" [disabled]="disabled">Select File</button>
    </div>
  }
  <input type="file" #fileElem [accept]="acceptableFiles" [multiple]="false" (change)="filesPicked($event)" hidden
         [disabled]="disabled">
</div>

