<style>
  .main {
  margin-top: 10em;
}
</style>
<app-bganimation tagName="body" className="warehouse"></app-bganimation>
<div class="main wider" fxLayout="row" fxLayoutAlign="center center">
  <form [formGroup]="form" (ngSubmit)="confirm()">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Confirm Your Login</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>Check your email for the confirmation code.</p>
        @if (!showInputs) {
          <p>
            <button data-cy="manualinput-button" mat-raised-button (click)="showInputs=true">Or click
              here to enter the code manually.
            </button>
          </p>
        }
        @if (showInputs) {
          <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="1em">
            <div fxLayout="column" fxLayoutAlign="start stretch">
              <mat-form-field class="wide">
                <mat-label>Email</mat-label>
                <input type="text" data-cy="email-input" matInput formControlName="email" required placeholder="Email">
                <mat-error>Required</mat-error>
              </mat-form-field>
              <mat-form-field class="wide">
                <mat-label>Confirmation Code</mat-label>
                <input type="text" data-cy="confirm-input" matInput formControlName="code" required placeholder="Code">
                <mat-error>Required</mat-error>
              </mat-form-field>
            </div>
          </div>
        }
      </mat-card-content>
      <mat-card-actions>
        <div fxLayout="column" fxLayoutGap="0.5em">
          <div>
            @if (!!busy) {
              <simple-progress [message]='message'></simple-progress>
            } @else {
              @if (showInputs) {
                <button data-cy="confirm-button" mat-raised-button type="submit" color="primary"
                  [disabled]="!form.valid">Confirm
                </button>
              }
              <button data-cy="resend-button" mat-raised-button color="primary" (click)="resend()">Resend</button>
            }
          </div>
          <div>
            @if (!!error) {
              <app-warning-panel id="error" [error]='true'>{{error}}</app-warning-panel>
            }
          </div>
        </div>
      </mat-card-actions>
    </mat-card>
  </form>
</div>
