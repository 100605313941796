import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBar} from "@angular/material/snack-bar";
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';

@Component({
    selector: 'app-htmlsnackbar-content',
    templateUrl: './htmlsnackbar-content.component.html',
    styleUrls: ['./htmlsnackbar-content.component.scss'],
    standalone: true,
    imports: [FlexModule, MatButtonModule]
})
export class HTMLSnackbarContentComponent {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,
              public snackBar: MatSnackBar) {
  }

}
