import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';

import { Cacheable, CacheBuster } from 'ts-cacheable';

import { Observable, of, Subject } from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { CountryCode } from '../models/server/CountryCode';
import { HexcomCustomer } from '../models/server/Payment/HexcomCustomer';
import { Product } from '../models/server/Payment/Product';
import { SubscriptionChangeRequest } from '../models/server/Payment/SubscriptionChangeRequest';
import { SubscriptionChangeResponse } from '../models/server/Payment/SubscriptionChangeResponse';
import { SubscriptionPurchaseInformationResponse } from '../models/server/Payment/SubscriptionPurchaseInformationResponse';
import { SubscriptionPurchaseRequest } from '../models/server/Payment/SubscriptionPurchaseRequest';
import { SubscriptionPurchaseResponse } from '../models/server/Payment/SubscriptionPurchaseResponse';
import { ServiceBase } from './serviceBase';
import {Organisation} from "../models/server/Organisation";
import {OrganisationService} from "./organisation.service";

const customerSubject = new Subject<void>();

@Injectable({
  providedIn: 'root'
})
export class PaymentService extends ServiceBase {

  onCustomerFetch: EventEmitter<HexcomCustomer> = new EventEmitter<HexcomCustomer>();

  constructor(
    httpClient: HttpClient,
    private orgService: OrganisationService) {
    super(httpClient);
  }

  @Cacheable()
  getProduct(): Observable<Product> {
    const headers = this.getHeaders();
    const parms = new HttpParams()
      .set('id', environment.barxuiProductID);

    return this.httpClient.get<Product>(environment.paymentApiUrl + '/product',
      {
        headers: headers,
        params: parms
      }).pipe(
        map(p => new Product(p))
      );
  }

  @Cacheable({
    cacheBusterObserver: customerSubject
  })
  getCustomer(): Observable<HexcomCustomer> {
    const headers = this.getHeaders();
    return this.httpClient.get<HexcomCustomer>(environment.paymentApiUrl + '/customer',
      {
        headers: headers
      })
      .pipe(
        catchError((err, caught) => {
          return of(new HexcomCustomer());
        }),
        map(c => {
          const customer = new HexcomCustomer(c);
          this.onCustomerFetch.emit(customer);
          return customer;
        }));
  }

  @Cacheable()
  getEditionCountryCodes(): Observable<Array<CountryCode>> {
    const headers = this.getHeaders();
    const parms = new HttpParams()
      .set('allCountries', 'false');

    return this.httpClient.get<Array<CountryCode>>(environment.paymentApiUrl + '/countrycodes',
      {
        headers: headers,
        params: parms
      });
  }


  @Cacheable()
  getAllCountryCodes(): Observable<Array<CountryCode>> {
    const headers = this.getHeaders();
    const parms = new HttpParams()
      .set('allCountries', 'true');

    return this.httpClient.get<Array<CountryCode>>(environment.paymentApiUrl + '/countrycodes',
      {
        headers: headers,
        params: parms
      }).pipe(
        map(ccs => {

          const sorted = ccs.map(cc => new CountryCode(cc));
          // add a divider
          // sorted.unshift(new CountryCode({ code: '--', name: '' }));
          let index = sorted.findIndex(cc => cc.code === 'NZ');
          if (index >= 0) {
            const nz = sorted.splice(index, 1);
            sorted.unshift(nz[0]);
          }
          index = sorted.findIndex(cc => cc.code === 'AU');
          if (index >= 0) {
            const au = sorted.splice(index, 1);
            sorted.unshift(au[0]);
          }

          return sorted;

        })
      );
  }

  getSubscriptionInfo(request: SubscriptionPurchaseRequest): Observable<SubscriptionPurchaseInformationResponse> {
    const headers = this.getHeaders();

    return this.httpClient.post<SubscriptionPurchaseInformationResponse>(environment.paymentApiUrl + '/purchase/subscriptioninfo',
      request,
      {
        headers: headers,
      }).pipe(
        map(info => new SubscriptionPurchaseInformationResponse(info))
      );
  }

  @CacheBuster({
    cacheBusterNotifier: customerSubject
  })
  processSubscription(request: SubscriptionPurchaseRequest): Observable<SubscriptionPurchaseResponse> {
    const headers = this.getHeaders();
    let subresp : SubscriptionPurchaseResponse;

    return this.httpClient.post<SubscriptionPurchaseResponse>(environment.paymentApiUrl + '/purchase/subscription',
      request,
      {
        headers: headers,
      }).pipe(
        switchMap(resp => {

          subresp = resp;
          // clear the trial date. This should really be done on the backend
          return this.orgService.clearTrialDate();
        }),
        switchMap(ok => {
          return of(subresp);
        })
    );
  }

  sendChangeRequest(changeRequest: SubscriptionChangeRequest): Observable<SubscriptionChangeResponse> {
    const headers = this.getHeaders();

    return this.httpClient.post<SubscriptionChangeResponse>(environment.paymentApiUrl + '/purchase/changesubscription',
      changeRequest,
      {
        headers: headers,
      });
  }
}
