<app-dialog-title title="Session Expired" icon="lock"></app-dialog-title>

<div mat-dialog-content>
  <section>
    <p class='mat-body'>Your session has expired. Please login again to continue.</p>
  </section>
</div>
<div mat-dialog-actions align="end">
  <button mat-raised-button color="primary" (click)="login()">
    <mat-icon>exit_to_app</mat-icon> Login
  </button>
</div>