import { Query, QueryColumnFilter } from './Query';
import { DataTypeEnum } from './DataType';
import Guid from '../../shared/guid';
import { User } from './User';

export interface IColumn {
  name: string;
  id?: string;
  dataType?: DataTypeEnum;
  format?: string;
  isChild: boolean;
  index: number;
}

export class TableColumn implements IColumn {
  // IColumn
  name: string;
  id: string;
  dataType: DataTypeEnum;
  format: string;
  isChild: boolean;

  index: number;
  defaultValue: string;

  constructor(column: TableColumn = null) {
    if (column) {
      Object.assign(this, column);
    }
  }
}

export class TableRow {
  id: string;
  key: string;
  created: Date;
  values: any;
  tableID: string;
  updatedbyid: string;
  updatedbylogin: string;

  static Copy(row: TableRow): TableRow {

    const newRow = new TableRow(row);
    newRow.id = Guid.create().toString();
    newRow.values.__id = newRow.id;
    newRow.created = new Date();
    newRow.values.__created = newRow.created;
    return newRow;
  }

  constructor(row: TableRow = null) {
    if (row) {
      Object.assign(this, row);
    } else {
      this.id = Guid.create().toString();
      this.created = new Date();
      this.key = '';
      this.values = {
        __id: this.id,
        __created: this.created,
        __key: this.key,
        __updatedById: this.updatedbyid,
        __updatedbylogin: this.updatedbylogin
      };
    }
  }
}

export class TableImportRequest {
  currentTable: string;
  firstRowIsHeader: boolean;
  importAllSheets: boolean;
  file: File;
}

export class Table {
  id: string;
  name: string;
  organisationID: string;
  columns: TableColumn[];
  hidden: boolean;
  addOnID: string;
  ownedByAddOn: boolean;
  addOnSource: string;
  defaultQuery: string;
  allowView: boolean;
  allowUpdate: boolean;

  // not from the api
  rows: TableRow[];

  constructor(table: Table = null) {
    if (table) {
      Object.assign(this, table);
      if (table.columns) {
        this.columns = table.columns.map(c => new TableColumn(c));
        this.addSystemColumns();
      }
      if (table.rows) {
        this.rows = table.rows.map(r => new TableRow(r));
      }
    }
  }

  createRow(user: User): TableRow {
    const row = new TableRow();
    row.tableID = this.id;

    for (const col of this.columns) {
      if (col.defaultValue) {
        let val = col.defaultValue.trim();
        if (val.startsWith('$')) {
          val = val.toLowerCase();

          switch (val) {
            case '$user':
              val = user.login;
              break;
          }
        }
        row.values[col.name] = val;
      }
    }

    return row;
  }

  private addSystemColumns(): void {
    if (this.columns.findIndex(qcf => qcf.name === '__created') < 0) {
      this.columns.push(new TableColumn({
        id: null, name: '__created', index: 0, dataType: DataTypeEnum.DateTime, defaultValue: null, format: null, isChild: false
      }));
    }
    if (this.columns.findIndex(qcf => qcf.name === '__updatedbylogin') < 0) {
      this.columns.push(new TableColumn({
        id: null, name: '__updatedbylogin', index: 0, dataType: DataTypeEnum.String, defaultValue: null, format: null, isChild: false
      }));
    }
    // if (this.columns.findIndex(qcf => qcf.name === '__updatedById') <= 0) {
    //   this.columns.push(new TableColumn({
    //     id : null, name: '__updatedById', sort: 0, dataType: DataTypeEnum.Guid, defaultValue: null
    //   }));
    // }
  }

  get toNewQuery(): Query {
    const q = new Query();
    q.tableID = this.id;
    q.isSystem = false; //this.ownedByAddOn;
    q.allowUpdate = true; //this.allowUpdate;

    q.columns = this.columns.map(
      (col, index) => new QueryColumnFilter({
        name: col.name,
        filter: '',
        index,
        sortOrder: 0,
        visible: true,
        dataType: col.dataType,
        tableColumnID: col.id,
        format: col.format,
        isChild: false
      })
    );
    return q;
  }
}
