import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {Auth, Hub} from 'aws-amplify';
import {NGXLogger} from 'ngx-logger';
import {BehaviorSubject, from, merge, of, ReplaySubject} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {SessionExpiredDialogComponent} from '../comps/session-expired-dialog/session-expired-dialog.component';
import {OrganisationService} from '../services/organisation.service';
import {UserService} from '../services/user.service';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  /**
   * Changes whenever the current authentication status of the user changes.
   */
  public isAuthenticated = new BehaviorSubject<boolean>(
    false
  );

  private cognitoUser: any;

  /**
   * Set upon successful authentication to the list of permissions the user has. Defaults to a list
   * of no permissions.
   */
  public policies = new ReplaySubject<string[]>(1);

  private _policies: string[];

  /**
   * Determines whether a user has a particular permission. This is vulnerable to a race condition,
   * prefer asynchronously checking permissions as above.
   */
  public hasPolicy = (perm: string) => this._policies?.includes(perm);

  constructor(
    private userService: UserService,
    private orgService: OrganisationService,
    private ngxLogger: NGXLogger,
    private dialog: MatDialog,
    private router: Router) {

    // Either www.barxui.com or barxui.com based on what the browser requested
    const urlOrigin = window.location.origin;

    Hub.listen('auth', data => this.authEvent(data));

    // Listen for successful authentication attempts and load their associated permissions
    this.isAuthenticated.pipe(
      switchMap(authed => {
        return authed ? this.userService.getUserPolicies() : of(new Array<string>());
      })
    ).subscribe(policies => {
      this._policies = policies;
      // this.ngxLogger.debug('Got policies', this._policies);
      return this.policies.next(policies);
    });

    Auth.currentAuthenticatedUser().then(user => {
      // console.log('got user at startup');
      // console.log(user);
      this.cognitoUser = user;
      this.isAuthenticated.next(true);
    }).catch(err => {
      this.cognitoUser = null;
      this.isAuthenticated.next(false);
      // console.log('No user');
    });
  }


  /**
   * Get a JWT Token for the current session
   */
  getToken() {
    return from(
      new Promise((resolve, reject) => {
        Auth.currentSession().then((session) => {
          if (!session.isValid()) {
            resolve(null);
          } else {
            resolve(session.getIdToken().getJwtToken());
          }
        })
          .catch(err => {
            return resolve(null)
          });
      })
    );
  }

  async login(email: string, password: string, resumeStateUrl: string = this.router.url): Promise<any> {

    try {
      const result = await Auth.signIn({
        username: email,
        password
      });
      merge(this.orgService.resetCache(), this.userService.resetCache()).subscribe(_ => {
      });

      this.cognitoUser = result;
      return result;
    } catch (err) {
      throw err;
    }

  }

  async register(
    // name: string,
    email: string,
    password: string,
    // phone: string
    inviteCode: string,
    orgname: string
  ): Promise<any | void> {

    return await Auth.signUp({
      username: email,
      password,
      attributes: {
        "custom:inviteCode": inviteCode,
        "custom:orgname": orgname
      }
    }).then(result => {
      merge(this.orgService.resetCache(), this.userService.resetCache()).subscribe(_ => {
      });

      this.cognitoUser = result.user;
      return result.user;

    }).catch(err => {
      throw err;
    });
  }

  async resendConfirmation(email: string): Promise<boolean> {
    try {
      const result = await Auth.resendSignUp(email);
      return true;
    } catch (err) {
      throw err;
    }
  }

  async confirm(
    email: string,
    code: string): Promise<boolean> {

    try {
      await Auth.confirmSignUp(email, code);
      return true;
    } catch (err) {
      throw err;
    }
  }

  logout(): void {
    Auth.signOut()
      .then(_ => {
        this.cognitoUser = null;

        merge(this.orgService.resetCache(), this.userService.resetCache()).subscribe(_ => {
          this.router.navigateByUrl('/').then(_ =>
            // force a reload solves a couple of issues
            window.location.replace(environment.aws.oauth.redirectSignOut));
        });
      })
      .catch(err => console.log(err));
  }

  async changePassword(oldpassword: string, newpassword: string): Promise<boolean> {

    try {

      let user = await Auth.currentUserPoolUser({bypassCache: false});
      await Auth.changePassword(user, oldpassword, newpassword);
      return true;
    } catch (err) {
      throw err;
    }
  }

  async forgotPassword(email: string): Promise<boolean> {

    try {
      const result = await Auth.forgotPassword(email);
      return true;
    } catch (err) {
      throw err;
    }
  }

  async forgotPasswordUpdate(email: string, code: string, password: string): Promise<boolean> {

    try {
      const result = await Auth.forgotPasswordSubmit(email, code, password);
      return true;
    } catch (err) {
      throw err;
    }
  }

  private authEvent(data: any): void {
    // console.log(data.payload.event);
    this.ngxLogger.debug(`AuthEvent: ${data.payload.event}`);

    this.ngxLogger.debug(`AuthEvent: ${data.payload.event}`, data);

    switch (data.payload.event) {
      case 'signIn':
        this.isAuthenticated.next(true);
        break;
      case 'signUp':
        break;
      case 'signOut':
        this.isAuthenticated.next(false);
        break;
      case 'signIn_failure':
        this.isAuthenticated.next(false);
        break;
      case 'tokenRefresh':
        break;
      case 'tokenRefresh_failure':
        this.isAuthenticated.next(false);

        this.dialog.open(SessionExpiredDialogComponent, {
          disableClose: true,
          role: 'alertdialog',
          data: () => {
            this.ngxLogger.debug('Session Expired Logout');
            this.logout();
          }
        });
        break;
      case 'configured':

        // Auth.currentAuthenticatedUser().then(user => {
        //   if (user) {
        //     console.log('got user in event');
        //     console.log(user);
        //     this.user = user;
        //     this.isAuthenticated.next(true);
        //   }
        // })

        break;
    }
  }
}
