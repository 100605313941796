import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {map, switchMap} from 'rxjs/operators';
import {UserService} from '../services/user.service';
import {AuthenticationService} from './authentication.service';
import {OrganisationService} from "../services/organisation.service";
import {of} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthorizationGuardService  {

  constructor(
    private authService: AuthenticationService,
    private userService: UserService,
    private organisationService: OrganisationService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.isAuthenticated.pipe(
      // tap(authenticated => !authenticated && this.authService.login(state.url)),
      switchMap((authed) => {
        if (!authed) {
          return of([]);
        }
        return this.userService.getUserPolicies();
      }),
      map(policies => {
        const requiresPermission = !!route.data.permission;

        // // Log if error
        // if (requiresPermission && !policies.includes(route.data.permission)) {
        //   console.error('Attempted to load route', route, 'without required permission', route.data.permission);
        //   console.error('User has these permissions:', policies);
        // }

        return requiresPermission ? policies.includes(route.data.permission) : true;
      }),
      switchMap(can => {

          if (!can) {
            return of(can);
          }

          if (route.data.isActiveOrganisation) {
            return this.organisationService.getOrganisation().pipe(
              switchMap(org => {
                return of(!org.isDeactivated && !org.isTrialExpired);
              })
            )
          }

          return of(can);
        }
      )
    );
  }

}
