import { Label } from './Label';
import { Query } from './Query';
import { Printer } from './Printer';
import { PaperSize } from './PaperSize';
import Guid from "../../shared/guid";
import {MessageService} from "../../services/message.service";

export interface RowSelectionPrint {
  id: string;
  copies: number;
}

export class RowSelection {
  printAllRowsByDefault: boolean; // we print everhthing except unselectedRows - copies = 1 unless set in selectedRows
  selectedRows: Array<RowSelectionPrint>; // print just these rows, or all rows with this number of copies if printAllRowsByDefault
  unselectedRows: Array<string>; // just the id - never print these rows
  rangeFrom?: number;
  rangeTo?: number;

  constructor(rowSelection?: RowSelection) {
    if (rowSelection) {
      Object.assign(this, rowSelection);
    } else {
      this.selectedRows = new Array<RowSelectionPrint>();
      this.unselectedRows = new Array<string>();
      this.printAllRowsByDefault = true;
    }
  }

  get hasSelectedRows(): boolean {
    return this.selectedRows && this.selectedRows.length > 0;
  }

  get hasUnSelectedRows(): boolean {
    return this.unselectedRows && this.unselectedRows.length > 0;
  }
}

export interface PrintRequestBaseOptions {
  parameters: any;
  copies: number;
  columnFirst: boolean;
  printCropMarks: boolean;
  flip: boolean;
  mirror: boolean;
  inverse: boolean;
  dataValues: any;
  answers: Map<string, any>;
  rowSelection: RowSelection;
  test: boolean;
}

export interface PrintRequestOptions extends PrintRequestBaseOptions {
  label: Label;
  query: Query;
  searchFilter: string;
  printer: Printer;
  paperSize: PaperSize;
}


export class PrintRequestBase {

  public bxConnectionId : string = MessageService.bxConnectionId;

  public id: string = Guid.create().toString();

  public parameters: any;
  public copies: number;
  public columnFirst: boolean;
  public printCropMarks: boolean;
  public flip: boolean;
  public mirror: boolean;
  public inverse: boolean;
  public dataValues: any;
  public answers: any;
  public rowSelection: RowSelection;
  public test: boolean = false;

  constructor(options?: PrintRequestBaseOptions) {
    this.rowSelection = new RowSelection();

    if (options) {
      this.parameters = options.parameters;
      this.copies = options.copies;
      this.columnFirst = options?.columnFirst;
      this.printCropMarks = options?.printCropMarks;
      this.flip = options?.flip;
      this.mirror = options?.mirror;
      this.inverse = options?.inverse;
      this.dataValues = options?.dataValues;
      this.answers = options?.answers;
      this.rowSelection = options?.rowSelection || this.rowSelection;
      this.test = options?.test;
    }
  }
}


export class PrintRequest
  extends PrintRequestBase {

  public label: Label;
  public query: Query;
  public searchFilter: string;
  public printer: Printer;
  public paperSize: PaperSize;
  public timeZoneOffset: number;

  constructor(
    options?: PrintRequestOptions) {
    super(options);

    // ffs - why is this inverted?  effing committees...
    // http://ecma262-5.com/ELS5_HTML.htm#Section_15.9.5.26
    this.timeZoneOffset = (new Date()).getTimezoneOffset() * -1;

    if (options) {
      this.label = options.label;
      this.query = options.query;
      this.searchFilter = options.searchFilter;
      this.printer = options.printer;
      this.paperSize = options.paperSize;
    }
  }

  static fromBase(prBase: PrintRequestBase): PrintRequest {
    if (prBase) {
      return new PrintRequest({
        label: null, query: null, searchFilter: null, printer: null, paperSize: null,
        parameters: prBase.parameters, copies: prBase.copies,
        columnFirst: prBase.columnFirst, printCropMarks: prBase.printCropMarks, flip: prBase.flip,
        mirror: prBase.mirror, inverse: prBase.inverse, dataValues: prBase.dataValues, answers: prBase.answers,
        rowSelection: prBase.rowSelection, test: prBase.test
      });
    } else {
      return new PrintRequest(null);
    }
  }

  asBase(): PrintRequestBase {
    return new PrintRequestBase({
      parameters: this.parameters, copies: this.copies,
      columnFirst: this.columnFirst, printCropMarks: this.printCropMarks, flip: this.flip,
      mirror: this.mirror, inverse: this.inverse, dataValues: this.dataValues, answers: this.answers,
      rowSelection: this.rowSelection, test: this.test
    });
  }

  makeFileName(extension: string): any {
    if (this.parameters && this.parameters.outputFilename) {
      return this.parameters.outputFilename;
    }
    return `${this.label.name}.${extension}`;
  }
}
