import { formatNumber } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cacheable } from 'ts-cacheable';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Action } from '../models/server/Action';
import { Feature } from '../models/server/Feature';
import { FeatureSet, FeatureSetPlan } from '../models/server/FeatureSet';
import { Organisation } from '../models/server/Organisation';
import { Resource } from '../models/server/Resource';
import { OrganisationService } from './organisation.service';
import { PaymentService } from './payment.service';
import { ServiceBase } from './serviceBase';

@Injectable({
    providedIn: 'root'
})
export class FeatureService
    extends ServiceBase {

    constructor(
        httpClient: HttpClient,
        private orgService: OrganisationService,
        private paymentService: PaymentService) {
        super(httpClient);
    }


    expandFeatureSet(sourceFeatureSet: FeatureSet, xpFS: FeatureSet, includedQty: number, xpQty: number): FeatureSet {
        // deep clones it so we can change the values of actions and resources
        const newFeatureSet = new FeatureSet(sourceFeatureSet);

        // add xp features not part of the fs
        for (const xpFeature of xpFS.features) {
            const qty = xpQty + includedQty;

            let newFeature = newFeatureSet.features.find(ff => ff.featureCode === xpFeature.featureCode);

            if (!newFeature && qty > 0) {
                newFeature = new Feature(xpFeature);
                newFeature.actions = null;
                newFeature.resources = null;
                newFeatureSet.features.push(newFeature);
            }

            if (newFeature) {
                if (xpFeature.resources) {
                    for (const xpResource of xpFeature.resources) {
                        let newResource = new Resource(xpResource);

                        if (!newFeature.resources) {
                            newFeature.resources = [newResource];
                        } else {
                            const i = newFeature.resources.findIndex(rrr => rrr.resourceCode === xpResource.resourceCode);
                            if (i >= 0) {
                                newResource = newFeature.resources[i];
                            } else {
                                newFeature.resources.push(newResource);
                            }
                        }

                        newResource.limit.value = xpResource.limit.value * qty;
                    }
                }

                if (xpFeature.actions) {
                    for (const xpAction of xpFeature.actions) {
                        let newAction = new Action(xpAction);

                        if (!newFeature.actions) {
                            newFeature.actions = [newAction];
                        } else {
                            const i = newFeature.actions.findIndex(aaa => aaa.actionCode === xpAction.actionCode);
                            if (i >= 0) {
                                newAction = newFeature.actions[i];
                            } else {
                                newFeature.actions.push(newAction);
                            }
                        }

                        newAction.limit.value = xpAction.limit.value * qty;
                    }
                }
            }
        }

        return newFeatureSet;
    }


    @Cacheable()
    getFeatureSets(): Observable<Array<FeatureSet>> {
        const headers = this.getHeaders();

        return this.httpClient.get<Array<FeatureSet>>(environment.accountApiUrl + '/features',
            {
                headers: headers
            }).pipe(
                map(fss => {
                    // return null;
                    if (fss) {
                        const newFss = new Array<FeatureSet>();
                        fss.forEach(fs => newFss.push(Object.assign(new FeatureSet(), fs)));
                        return newFss;
                    }
                    return null;
                })
            );
    }


    genFeatureDescriptions(fs: FeatureSet, locale: string): Array<string> {
        const desc = new Array<string>();

        if (fs.features) {
            fs.features.forEach(f => {
                if (f.resources) {
                    f.resources.forEach(r => {

                        switch (f.featureCode) {

                            case 'ConcurrentUsers': {
                                switch (r.resourceCode) {
                                    case 'Count': {
                                        desc.push(`${formatNumber(r.limit.value, locale, '1.0')} concurrent user` + (r.limit.value > 1 ? 's' : ''));
                                        break;
                                    }
                                }
                                break;
                            }

                            case 'Labels': {
                                switch (r.resourceCode) {
                                    case 'Count': {
                                        desc.push(`${formatNumber(r.limit.value, locale, '1.0')} label` + (r.limit.value > 1 ? 's' : ''));
                                        break;
                                    }
                                }
                                break;
                            }

                            case 'Files': {
                                switch (r.resourceCode) {
                                    case 'Count': {
                                        desc.push(`${formatNumber(r.limit.value, locale, '1.0')} image file` + (r.limit.value > 1 ? 's' : ''));
                                        break;
                                    }
                                    case 'Size': {
                                        desc.push(`${formatNumber(r.limit.value / 1024 / 1024, locale, '1.0')}MB in files`);
                                        break;
                                    }
                                }
                                break;
                            }

                            case 'DataTables': {
                                switch (r.resourceCode) {
                                    case 'Count': {
                                        desc.push(`${formatNumber(r.limit.value, locale, '1.0')} data table` + (r.limit.value > 1 ? 's' : ''));
                                        break;
                                    }
                                    case 'Size': {
                                        desc.push(`${formatNumber(r.limit.value, locale, '1.0')} data rows`);
                                        break;
                                    }
                                }
                                break;
                            }

                            case 'Agents': {
                                switch (r.resourceCode) {
                                    case 'Count': {
                                        desc.push(`${formatNumber(r.limit.value, locale, '1.0')} barxui Agent` +
                                            (r.limit.value > 1 ? 's' : ''));
                                        break;
                                    }
                                }
                                break;
                            }

                            case 'AddOns': {
                                switch (r.resourceCode) {
                                    case 'Count': {
                                        desc.push(`${formatNumber(r.limit.value, locale, '1.0')} AddOn` +
                                            (r.limit.value > 1 ? 's' : ''));
                                        break;
                                    }
                                }
                                break;
                            }


                            case 'Printers': {
                                switch (r.resourceCode) {
                                    case 'Count': {
                                        desc.push(`${formatNumber(r.limit.value, locale, '1.0')} barxui Agent printer` +
                                            (r.limit.value > 1 ? 's' : ''));
                                        break;
                                    }
                                }
                                break;
                            }

                            case 'Sites': {
                                switch (r.resourceCode) {
                                    case 'Count': {
                                        desc.push(`${formatNumber(r.limit.value, locale, '1.0')} Site license` +
                                            (r.limit.value > 1 ? 's' : ''));
                                        break;
                                    }
                                }
                                break;
                            }

                            case 'Consoles': {
                                switch (r.resourceCode) {
                                    case 'Count': {
                                        desc.push(`Access ${formatNumber(r.limit.value, locale, '1.0')} console` + (r.limit.value > 1 ? 's' : ''));
                                        break;
                                    }
                                }
                                break;
                            }
                        }
                    });
                }

                if (f.actions) {
                    f.actions.forEach(a => {

                        switch (a.actionCode) {
                            case 'Labels:Print:PrintLabels': {
                                desc.push(`${formatNumber(a.limit.value, locale, '1.0')} print requests/${a.limit.frequency.toLowerCase()}`);
                                break;
                            }
                        }
                    });
                }
            });
        }
        return desc;
    }

    @Cacheable()
    planHasFeature(featureCode: string): Observable<boolean> {

        let org: Organisation = null;
        let xpFs: FeatureSet;
        let planID;
        let xpPlanID;
        let xpQty;

        // get the org, which has a plan id
        return this.orgService.getOrganisation()
            .pipe(
                // get the customer subscription
                switchMap((o: Organisation) => {
                    if (o) {
                        org = o;

                        return this.paymentService.getCustomer();
                    } else {
                        return of(null);
                    }
                }),
                // get all the featuresets
                switchMap(hc => {
                    if (hc) {
                        if (hc.subscriptions && hc.subscriptions.length) {

                            const prodSub = hc.subscriptions[0].items.find(s => s.productID === environment.barxuiProductID);
                            const xpSub = hc.subscriptions[0].items.find(s => s.productID === environment.expansionPackProductID);

                            planID = prodSub.planID;
                            if (xpSub) {
                                xpPlanID = xpSub.planID;
                                xpQty = xpSub.quantity;
                            }

                        }

                        return this.getFeatureSets();

                    } else {
                        return of(null);
                    }
                }),
                // get the featureset that contains the orgs plan
                switchMap((fss: FeatureSet[]) => {
                        if (fss) {
                            xpFs = fss.filter((fs: FeatureSet) => fs.isExpansionPack)[0];
                            return of(fss.filter((fs: FeatureSet) =>
                                fs.plans.findIndex((p: FeatureSetPlan) => p.planID === planID) >= 0)[0]);
                        } else {
                            return of(null);
                        }
                    }),
                // expand the plan
                switchMap((fs: FeatureSet) => {
                    if (fs && xpFs) {
                        return of(this.expandFeatureSet(fs, xpFs, fs.isFree ? 0 : 1, xpQty));
                    } else {
                        return of(null);
                    }
                }),
                // see if the expanded plan has the resource
                map((expFs: FeatureSet) => {
                    if (expFs) {
                        return expFs.features.findIndex(f => f.featureCode === featureCode) >= 0;
                    } else {
                        return false;
                    }
                })
            );
    }

}
