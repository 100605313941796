import { Shape } from './Shape';

export enum GRID_STYLE {
    NONE,
    NORMAL,
    VERTICALONLY,
    HORIZONTALONLY,
    VERTICALHEADINGTABLE,
    HORIZONTALHEADINGTABLE,
    VERTICALPROGRESS,
    HORIZONTALPROGRESS,
    CUSTOM
}

export class GridShape extends Shape<GRID_STYLE> {

    static ShapeType = 'grid';
    
    public rows: number;
    public columns: number;
    public cells: Array<Array<string>>;
    public includeGap: boolean;
    public progressPercentage: number;

    constructor(grid?: GridShape) {
        super(grid);

        this.shapeName = 'Grid';
        this.style = GRID_STYLE.NORMAL;
        this.cells = new Array<Array<string>>();
        this.progressPercentage = 0;
        this.rows = 2;
        this.columns = 2;
        this.keepProportional = true;
        this.includeGap = true;

        if (grid) {
            this.style = grid.style;
            this.columns = grid.columns;
            this.rows = grid.rows;
            this.progressPercentage = grid.progressPercentage;
            this.includeGap = grid.includeGap;

            if (grid.cells) {
                grid.cells.forEach(row => {
                    const rowArr = new Array<string>();
                    row.forEach(col => {
                        rowArr.push(col);
                    });
                    this.cells.push(rowArr);
                });
            }
        }
    }
}


