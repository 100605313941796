import { Shape } from './Shape';

export class TextShape extends Shape<any> {

  static ShapeType = 'text';

  growWidth: boolean;
  growHeight: boolean;

  constructor(shape?: TextShape) {
    super(shape);
    this.shapeName = 'Text';
    if (shape) {
      this.growHeight = shape.growWidth;
      this.growWidth = shape.growWidth;
    }
  }

  isTightlyBoundShape(): boolean {
    return false;
  };

}
