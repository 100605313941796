import {Injectable, inject} from '@angular/core';
import {ServiceBase} from './serviceBase';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Limitation} from '../models/server/Limitation';
import {environment} from '../../environments/environment';
import {LimitExceededEvent} from '../models/LimitExceededEvent';
import {BehaviorSubject, of} from 'rxjs';
import {PaymentService} from './payment.service';
import {switchMap, map, catchError, tap} from 'rxjs/operators';
import moment from 'moment';
import {LocalStorageKeys} from '../models/server/Constants';
import {MatDialog} from '@angular/material/dialog';
import {LimitExceededDialogComponent} from '../comps/limit-exceeded-dialog/limit-exceeded-dialog.component';
import {AuthenticationService} from '../authentication/authentication.service';
import {NGXLogger} from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class LimitsService extends ServiceBase {

  limitsExceeded: BehaviorSubject<LimitExceededEvent> = new BehaviorSubject(null);

  private canShowDialog = true;

  #paymentService = inject(PaymentService);
  #ngxLogger = inject(NGXLogger);
  // #authService = inject(AuthenticationService);

  constructor(
    httpClient: HttpClient,
    private dialog: MatDialog
  ) {
    super(httpClient);
  }

  // should cache?  probably but...
  // could be tricky to invalidate any cache - so many things would need to do that
  getLimits(planID: string = null, xpPlanID: string = null, xpQty: number = 0): Observable<Array<Limitation>> {
    if (planID) {
      return this._getLimits(planID, xpPlanID, xpQty);
    } else {

      return this.#paymentService.getCustomer()
        .pipe(
          catchError(err => {
            this.#ngxLogger.error(`Could not get customer details`, err);
            return of(null);
          }),
          switchMap(hc => {

            if (hc) {
              if (hc.subscriptions && hc.subscriptions.length) {

                const prodSub = hc.subscriptions[0].items.find(s => s.productID === environment.barxuiProductID);
                const xpSub = hc.subscriptions[0].items.find(s => s.productID === environment.expansionPackProductID);

                planID = prodSub.planID;
                if (xpSub) {
                  xpPlanID = xpSub.planID;
                  xpQty = xpSub.quantity;
                }

              }
              return this._getLimits(planID, xpPlanID, xpQty);
            } else {
              return of(null);
            }
          }));
    }
  }

  private _getLimits(planID: string, xpPlanID: string, xpQty: number): Observable<Array<Limitation>> {
    const headers = this.getHeaders();

    let parms = null;
    if (planID) {
      parms = new HttpParams()
        .set('planID', planID)
        .set('xpPlanID', xpPlanID)
        .set('xpQty', xpQty.toString());
    }

    return this.httpClient.get<Array<Limitation>>(
      environment.limitationApiUrl,
      {
        headers: headers,
        params: parms,
      }
    ).pipe(
      map(limits => limits?.map(limit => new Limitation(limit)))
    );
  }

  checkResourceLimit(resourceCode: string = null, showMessage: boolean = false, wait: boolean = false): Observable<LimitExceededEvent> {

    const ev = new LimitExceededEvent([]);
    ev.confirmed = true;
    return of(ev);

    /** TURN OFF LIMITS
    return this.getLimits().pipe(
      switchMap(limits => {

        if (limits) {

          const limited = limits.filter(l => (!resourceCode || l.code === resourceCode) && l.exceeded);
          if (limited && limited.length) {

            if (!resourceCode) {
              // when checking all (on timer) dont show the popup if they already confirmed it recently
              let lastConfirmed: any = localStorage.getItem(LocalStorageKeys.ConfirmLimitReached);
              if (!!lastConfirmed) {
                lastConfirmed = moment(parseInt(lastConfirmed, 10));
                const diff = moment().diff(lastConfirmed, 'minute');
                if (diff < (60 * 12)) { // they can confirm again in 12 hours
                  showMessage = false;
                }
              }
            }

            const ev = new LimitExceededEvent(limited);
            ev.exceeded = limited;

            if (showMessage && this.canShowDialog) {
              this.canShowDialog = false; // prevent re-entrency

              const dlg = this.dialog.open(LimitExceededDialogComponent, {
                data: ev,
                width: '30%'
              });

              return dlg.afterClosed().pipe(
                tap(() => this.canShowDialog = true)
              );

            } else {

              this.limitsExceeded.next(ev);
            }

            // const bs = this.bottomSheet.open(LimitExceededSheetComponent, {
            //   data: { event: ev, showOk: wait },
            //   disableClose: wait,
            //   closeOnNavigation: !wait
            // });

            // return bs.afterDismissed();

            // const dlg = this.dialog.open(LimitExceededDialogComponent, {
            //   data: { event: ev, showOk: wait },
            //   disableClose: wait,
            //   closeOnNavigation: !wait,
            //   width: '30%'
            // });

            // return dlg.afterClosed();

            //return ev;
            // }
          }
        }

        // no limits? not possible ? lets assume so and be kind to people
        return of(null);
      }),
      map((ret: LimitExceededEvent) => {
          if (ret?.confirmed) {
            localStorage.setItem(LocalStorageKeys.ConfirmLimitReached, Date.now().toString());
          }
          return ret;
        }
      )
    );
    */
  }
}
