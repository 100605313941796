import {
  PromptedQuestionType,
  PromptedQuestion,
  PromptedBoolQuestion,
  PromptedQuestionConstants,
  PromptedChoiceQuestion,
  PromptedDateTimeQuestion,
  PromptedListQuestion,
  PromptedNumberQuestion,
  PromptedStringQuestion, PromptedTextQuestion
} from './PromptedQuestion';

export enum ExpressionDataSourceType {
  Data = 0,
  DateTime = 5,
  LabelInfo = 10,
  OrganisationInfo = 15,
  Question = 20,
  Serial = 25,
  Sequence = 30,
  Constant = 888,
  Function = 999
}

export enum TransformationType {
  Casing = 10,
  DateFormat = 15,
  NumberFormat = 16,
  Padding = 20,
  Prefix = 30,
  Slice = 40,
  Substitute = 50
}

export class ExpressionDataSource {
  type: ExpressionDataSourceType;
  source: string;
  params: Map<string, any>;

  constructor(eds?: ExpressionDataSource) {
    this.params = new Map<string, any>();
    if (eds) {
      this.type = eds.type;
      this.source = eds.source;
      if (eds.params) {
        const keys = Object.keys(eds.params);
        keys.forEach((key: string) => {
          this.params[key] = eds.params[key];
        });
      }
    }
  }

  toString() : string {
    switch (this.type) {
      case ExpressionDataSourceType.Data:
        return `Data Field ${this.source}`;
      case ExpressionDataSourceType.DateTime:
        return `Current Date / Time`;
      case ExpressionDataSourceType.Function:
        return `Expression`;
      case ExpressionDataSourceType.LabelInfo:
        return `Label Field ${this.source}`;
      case ExpressionDataSourceType.OrganisationInfo:
        return this.source;
      case ExpressionDataSourceType.Question:
        if (this.params && this.params[PromptedQuestionConstants.Param_QuestionType]) {
          let dt = this.params[PromptedQuestionConstants.Param_QuestionType];
          switch (+dt) {
            case PromptedQuestionType.Bool:
              dt = 'Yes/No';
              break;
            case PromptedQuestionType.Choice:
              dt = 'Fixed List';
              break;
            case PromptedQuestionType.List:
              dt = 'Query List';
              break;
            case PromptedQuestionType.Date:
              dt = 'Date';
              break;
            case PromptedQuestionType.Number:
              dt = 'Number';
              break;
            case PromptedQuestionType.String:
              dt = 'Characters';
              break;
            case PromptedQuestionType.Text:
              dt = 'Text';
              break;
          }
          return `Prompt for ${dt}`;
        }
        return 'Unknown Question Type';

      case ExpressionDataSourceType.Sequence:
        return `Sequence From ${this.params['start']} to ${this.params['end']} by ${this.params['increment']}`;
      case ExpressionDataSourceType.Serial:
        return `Serial ${this.source}`;
      case ExpressionDataSourceType.Constant:
        return `Fixed Value "${this.source || ''}"`;
    }
    return '';
  }
}

export class Transformation {
  type: TransformationType;
  name: string;
  params: Map<string, any>;

  constructor(transform?: Transformation) {
    this.params = new Map<string, any>();
    if (transform) {
      this.type = transform.type;
      this.name = transform.name;
      if (transform.params) {
        const keys = Object.keys(transform.params);
        keys.forEach((key: string) => {
          this.params[key] = transform.params[key];
        });
      }
    }
  }
}

export class FormattedExpression {

  name: string;
  isGlobal: boolean;
  fromGlobal: boolean;
  dataSource: ExpressionDataSource;
  transforms: Array<Transformation>;

  constructor(fex?: FormattedExpression) {
    this.dataSource = new ExpressionDataSource();
    if (fex) {
      this.name = fex.name;
      this.isGlobal = fex.isGlobal;
      this.fromGlobal = fex.fromGlobal;
      this.dataSource = new ExpressionDataSource(fex.dataSource);
      this.transforms = new Array<Transformation>();
      if (fex.transforms) {
        fex.transforms.forEach(t => {
          this.transforms.push(new Transformation(t));
        });
      }
    }
  }

  parse(value: string): void {
    this.dataSource = new ExpressionDataSource();
    this.dataSource.type = ExpressionDataSourceType.Function;
    this.dataSource.source = value;
    this.dataSource.params = null;
    this.transforms = null;
  }

  asQuestion(): PromptedQuestion {
    let ret: PromptedQuestion = null;
    if (this.dataSource.type === ExpressionDataSourceType.Question) {
      const qt = +this.dataSource.params[PromptedQuestionConstants.Param_QuestionType];
      switch (qt) {
        case PromptedQuestionType.Bool:
          ret = new PromptedBoolQuestion(this.dataSource.params);
          ret.name = this.name;
          break;
        case PromptedQuestionType.Choice:
          ret = new PromptedChoiceQuestion(this.dataSource.params);
          ret.name = this.name;
          break;
        case PromptedQuestionType.Date:
          ret = new PromptedDateTimeQuestion(this.dataSource.params);
          ret.name = this.name;
          break;
        case PromptedQuestionType.List:
          ret = new PromptedListQuestion(this.dataSource.params);
          ret.name = this.name;
          break;
        case PromptedQuestionType.Number:
          ret = new PromptedNumberQuestion(this.dataSource.params);
          ret.name = this.name;
          break;
        case PromptedQuestionType.String:
          ret = new PromptedStringQuestion(this.dataSource.params);
          ret.name = this.name;
          break;
        case PromptedQuestionType.Text:
          ret = new PromptedTextQuestion(this.dataSource.params);
          ret.name = this.name;
          break;
      }
    }
    return ret;
  }

  toString(): string {
    return this.dataSource.toString();
  }
}

export enum StringCasingMode {
  None = 0,
  Lower = 1,
  Upper = 2,
  Title = 3
}


export class DataSourceType {
  sourceType: ExpressionDataSourceType;
  name: string;
  icon: string;
  disable: boolean;
}

export function GetDataSourceTypes(hasQuery: boolean): DataSourceType[] {
  const ret = [
    {
      sourceType: ExpressionDataSourceType.Constant, name: 'Fixed Value',
      icon: 'format_quote', disable: false
    },
    {
      sourceType: ExpressionDataSourceType.DateTime, name: 'Date / Time',
      icon: 'calendar_today', disable: false
    },
    {
      sourceType: ExpressionDataSourceType.Sequence, name: 'Sequence Number',
      icon: 'format_list_numbered', disable: false
    },
    // {
    //   sourceType: ExpressionDataSourceType.LabelInfo, name: 'Label Info', value: '',
    //   icon: 'label', disable: true
    // },
    {
      sourceType: ExpressionDataSourceType.Question, name: 'Prompted Question',
      icon: 'question_answer', disable: false
    },
    {
      sourceType: ExpressionDataSourceType.Serial, name: 'Serial Number',
      icon: 'power_input', disable: false
    },
    {
      sourceType: ExpressionDataSourceType.OrganisationInfo, name: 'Org. Info',
      icon: 'business', disable: false
    },
    {
      sourceType: ExpressionDataSourceType.Function, name: 'Expression',
      icon: 'code', disable: false
    }
  ];

  if (hasQuery) {
    ret.unshift({
      sourceType: ExpressionDataSourceType.Data, name: 'Data Field',
      icon: 'view_list', disable: false
    });
  }

  return ret;

}
