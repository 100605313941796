import { PointOrigin } from './PointOrigin';

export interface PrinterDefaults {
  id: string;
  columnFirst: boolean;
  copies: number;
  cropMarks: boolean;
  flip: boolean;
  mirror: boolean;
  inverse: boolean;
}

export class Settings {

  public origin: PointOrigin;
  public printerDefaults: PrinterDefaults;

  constructor(settings?: Settings) {
    if (settings) {
      this.origin = settings.origin;
      this.printerDefaults = settings.printerDefaults;
      if(!this.printerDefaults) {
        this.printerDefaults = {
          id: "",
          copies: 1,
          columnFirst: false,
          cropMarks: false,
          flip: false,
          mirror: false,
          inverse: false
        };

        // convert old label settings for defaults
        let anySettings = (settings as any);
        if(anySettings.defaultPrinter)
          this.printerDefaults.id = anySettings.defaultPrinter;
        if(anySettings.defaultCopies)
          this.printerDefaults.copies = +anySettings.defaultCopies;
      }
    }
  }
}
