import { enableProdMode, ErrorHandler, LOCALE_ID, Injector, importProvidersFrom } from '@angular/core';
import { Amplify } from 'aws-amplify';

import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { AppRoutingModule } from './app/app.routes';
import { NgxStripeModule } from 'ngx-stripe';
import { LoggerModule } from 'ngx-logger';
import { FlexLayoutModule } from '@angular/flex-layout';
// import {MaterialModule} from './app/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { provideAnimations } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { AuthInterceptor } from './app/auth.interceptor';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { ErrorHandlerService } from './app/services/errorhandler.service';
import { PendingChangesGuard } from './app/services/canDeactivateGuard';
import { GlobalCacheConfig, LocalStorageStrategy } from "ts-cacheable";

Amplify.configure(environment.aws);

if (environment.production) {
  enableProdMode();
}

// GlobalCacheConfig.storageStrategy = LocalStorageStrategy; // store in memory - localstorage keeps filling up
GlobalCacheConfig.slidingExpiration = true;
GlobalCacheConfig.globalCacheKey = 'barxuiCache';
GlobalCacheConfig.maxAge = 1000 * 60 * 60 * 24; // 1 day
GlobalCacheConfig.maxCacheCount = 65;

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      CommonModule,
      ReactiveFormsModule,
      // MaterialModule,
      FlexLayoutModule,
      LoggerModule.forRoot({
        level: environment.feLogLevel,
        serverLogLevel: environment.feServerLogLevel,
        serverLoggingUrl: environment.feLogUrl,
        enableSourceMaps: !environment.production,
        httpResponseType: 'json'
      }),
      NgxStripeModule.forRoot(environment.stripePublishableKey),
      AppRoutingModule,

      // ,
      // ServiceWorkerModule.register('ngsw-worker.js', {
      //   enabled: !isDevMode(),
      //   // Register the ServiceWorker as soon as the application is stable
      //   // or after 30 seconds (whichever comes first).
      //   registrationStrategy: 'registerWhenStable:30000'
      // })
    ),
    PendingChangesGuard,
    {
      provide: ErrorHandler, useClass: ErrorHandlerService
    },
    {
      provide: LOCALE_ID, useValue: 'en-GB'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      deps: [Injector],
      multi: true
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations()
  ]
})
  .catch(err => console.log(err));
