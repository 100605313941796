import {Injectable} from '@angular/core';
import {LocalStorageKeys} from '../models/server/Constants';
import {ParamMap, Params} from "@angular/router";
import {Subscription} from "rxjs";

export enum RecentActivityKind {
  Label,
  Table,
  Console
}

export interface RecentActivity {
  label: string;
  kind: RecentActivityKind,
  url: string;
  params: Params;
}

// keep a stack of the recent activity
@Injectable({
  providedIn: 'root'
})
export class RecentActivityService {

  stackSize = 7;  // why not

  private recentActivity: Array<RecentActivity>;
  private qpm: Subscription;

  constructor(
  ) {
    // try to load from localstorage
    this.clear();
    this.load();
  }

  public clear() {
    this.recentActivity = new Array<RecentActivity>();
  }

  public list(): Array<RecentActivity> {
    return this.recentActivity;
  }

  public add(recentAct: RecentActivity): void {

    // remove dupes
    while (true) {
      let i = this.recentActivity.findIndex((v, index) => v.label === recentAct.label && v.kind === recentAct.kind);
      if (i >= 0) {
        this.recentActivity.splice(i, 1);
      } else {
        break;
      }
    }

    this.recentActivity.unshift(recentAct);

    if (this.recentActivity.length > this.stackSize) {
      this.recentActivity.length = this.stackSize;
    }
    localStorage.setItem(LocalStorageKeys.LastPage, recentAct.url);
    this.save();
  }

  private load(): void {
    var j = localStorage.getItem(LocalStorageKeys.RecentActivity);
    if (j) {
      Object.assign(this.recentActivity, JSON.parse(j));
    }
  }

  private save(): void {
    localStorage.setItem(LocalStorageKeys.RecentActivity, JSON.stringify(this.recentActivity));
  }

}
