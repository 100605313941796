import { Organisation } from './Organisation';

export class User {
  public id: string;
  public organisationID: string;
  public organisation: Organisation;
  public name: string;
  public login: string;
  public email: string;
  public email_verified: boolean;
  public preferred_username: string;
  public sub: string;
  public role: string;

  public sendSystemAlerts: boolean;
  public sendNewsAndTips: boolean;

  constructor(u: User = null) {
    if (u) {
      this.id = u.id;
      this.organisationID = u.organisationID;
      this.organisation = u.organisation;
      this.name = u.name;
      this.login = u.login;
      this.email = u.email;
      this.email_verified = u.email_verified;
      this.preferred_username = u.preferred_username;
      this.sub = u.sub;
      this.role = u.role;
    }
  }

  get isSubscriber(): boolean {
    return this.role === Roles.Subscriber;
  }

  get isAdmin(): boolean {
    return this.role === Roles.Admin || this.role === Roles.God || this.role === Roles.Angel;
  }

  get isAgent(): boolean {
    return this.role === Roles.Agent;
  }

}


export class Roles {
  static Subscriber = 'Subscriber';
  static Admin = 'Admin';
  static Agent = 'Agent';
  // static PowerUser = 'PowerUser';
  static Designer = 'Designer';
  static Operator = 'Operator';
  static User = 'User';

  static Angel = 'Angel';
  static God = 'God';
}

export class Policies {

  static SuperAPI = "SuperAPI";
  static AgentUpdate = "AgentUpdate";
  static DesignView = "DesignView";
  static DesignUpdate = "DesignUpdate";
  static DesignPrint = "DesignPrint";
  static DesignExportImport = "DesignExportImport";
  static DatamanView = "DatamanView";
  static DatamanCreateTable = "DatamanCreateTable";
  static DatamanUpdateData = "DatamanUpdateData";
  static DatamanUploadTable = "DatamanUploadTable";
  static DatamanDeleteTable = "DatamanDeleteTable";
  static DatamanViewQuery = "DatamanViewQuery";
  static DatamanUpdateQuery = "DatamanUpdateQuery";
  static DatamanExecuteQuery = "DatamanExecuteQuery";
  static DatamanDeleteQuery = "DatamanDeleteQuery";
  static ConsoleUse = "ConsoleUse";
  static ConsoleSelect = "ConsoleSelect";
  static OrganisationView = "OrganisationView";
  static OrganisationUpdate = "OrganisationUpdate";
  static OrganisationFonts = "OrganisationFonts";
  static UserView = "UserView";
  static UsersMyDetails = "UsersMyDetails";
  static UsersUpdate = "UsersUpdate";
  static UsersInvite = "UsersInvite";
  static UsersSetPermissions = "UsersSetPermissions";
  static UsersChangeSubscriber = "UsersChangeSubscriber";
  static AccountView = "AccountView";
  static AccountChangePlan = "AccountChangePlan";
  static AccountChangePayment = "AccountChangePayment";
  static ManagePrinters = "ManagePrinters";
  static ManageAgents = "ManageAgents";
  static ManageAddOns = "ManageAddOns";
}
