import { Component, Output, EventEmitter, Input, AfterViewInit, OnDestroy } from '@angular/core';

import moment from 'moment';
import { PaymentService } from '../../services/payment.service';
import { HexcomProductSubscription } from '../../models/server/Payment/HexcomProductSubscription';
import { HexcomCustomer } from '../../models/server/Payment/HexcomCustomer';
import { Organisation } from '../../models/server/Organisation';
import { User } from '../../models/server/User';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrganisationService } from '../../services/organisation.service';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { MatChipsModule } from '@angular/material/chips';

@Component({
    selector: 'app-plan-expiry',
    templateUrl: './plan-expiry.component.html',
    standalone: true,
    imports: [MatChipsModule, RouterLink, MatTooltipModule, DatePipe]
})
export class PlanExpiryComponent implements AfterViewInit, OnDestroy {

  status: string;
  currentSub: HexcomProductSubscription;
  customer: HexcomCustomer;
  showStatus: boolean;
  trialEnd: Date;
  statusMessage: string;

  @Input()
  organisation: Organisation;
  @Input()
  user: User;

  private paymentSub: Subscription;
  private orgSub: Subscription;

  constructor(
    private snackBar: MatSnackBar,
    private paymentService: PaymentService,
    private organisationService: OrganisationService
  ) {

    this.orgSub = this.organisationService.orgUpdate.subscribe(o => {
      this.organisation = o;
      this.updateView();
    });
  }

  ngOnDestroy(): void {
    this.orgSub.unsubscribe();
    this.paymentSub.unsubscribe();
  }

  ngAfterViewInit() {
    this.paymentService.onCustomerFetch
      .subscribe((c: HexcomCustomer) => {
        this.currentSub = c.currentSubscription();
        this.updateView();
      });

    // force the first call
    this.paymentSub = this.paymentService.getCustomer().subscribe();
  }

  updateView() {
    if (this.organisation?.isDeactivated) {
      this.status = 'DEACTIVATED';
      this.statusMessage = this.organisation.deactivateReason;
    } else if (this.currentSub) {
      switch (this.currentSub.status) {
        case 'active':
          { this.status = 'OK'; break; }
        case 'trialing':
          { this.status = 'SUBTRIALING'; break; }
        case 'past_due':
          { this.status = 'OVERDUE'; break; }
        case 'unpaid':
          { this.status = 'EXPIRED'; break; }
        case 'cancelled':
          { this.status = 'CANCELLED'; break; }
      }
    } else if (this.organisation.trialEnds) {

      if(this.organisation.isTrialExpired) {
        this.status = 'TRIALENDED';
      }
      else
      {
        this.status = 'TRIALING';
      }
    } else {
      this.status = 'NONE';
    }

    if (this.user && this.organisation) {
      this.showStatus = this.user?.isSubscriber || this.status === 'FREE' || this.status === 'DEACTIVATED';
    }
  }

  showDeactivation(): void {
    if (this.organisation.isDeactivated) {
      this.snackBar.open(
        `Your organisation was deactivated ${moment(this.organisation.deactivateDate).format('ll')}: ${this.organisation.deactivateReason}`, 'Organisation Deactivated');
    }
  }
}
