import {AfterViewInit, Component, OnInit} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserService } from '../../../services/user.service';
import { ErrorHandlerService } from '../../../services/errorhandler.service';
import { User } from '../../../models/server/User';
import { MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ChangePasswordComponent } from '../../../authentication/change-password/change-password.component';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexModule } from '@angular/flex-layout/flex';
import { DialogTitleComponent } from '../../../comps/dialog-title/dialog-title.component';


@Component({
    selector: 'user-profile',
    templateUrl: './userprofiledialog.component.html',
    styleUrls: ['./userprofiledialog.component.scss'],
    standalone: true,
    imports: [DialogTitleComponent, MatDialogContent, FormsModule, ReactiveFormsModule, FlexModule, MatFormFieldModule, MatInputModule, MatCardModule, ChangePasswordComponent, MatCheckboxModule, MatDialogActions, MatButtonModule, MatDialogClose, MatIconModule]
})
export class UserProfileDialogComponent
  implements OnInit, AfterViewInit {

  userRecord: User = new User();
  form: UntypedFormGroup;
  loading = null;

  constructor(
    private dialogRef: MatDialogRef<UserProfileDialogComponent>,
    public userService: UserService,
    private errorHandler: ErrorHandlerService,
    public fb: UntypedFormBuilder) {

    this.dialogRef?.keydownEvents().subscribe(event => {
      if (event.key === "Escape") {
        this.dialogRef.close();
      }
    });

    this.createForm();
    this.loading = 'Loading';

  }

  ngOnInit(): void {

  }

  ngAfterViewInit() : void {
    this.userService.getUser().subscribe(u => {
      this.userRecord = u;
      this.updateForm();
      this.loading = null;
    });
  }

  createForm(): void {
    let orgName = '';
    if (this.userRecord.organisation) {
      orgName = this.userRecord.organisation.name;
    }

    this.form = this.fb.group({
      'login': [this.userRecord.login],
      'name': [this.userRecord.name, Validators.required],
      'organisation': [orgName],
      'systemalerts': [this.userRecord.sendSystemAlerts],
      'news': [this.userRecord.sendNewsAndTips]
    });
  }

  updateForm(): void {
    this.form.controls.name.setValue(this.userRecord.name);
    this.form.controls.login.setValue(this.userRecord.login);
    this.form.controls.systemalerts.setValue(this.userRecord.sendSystemAlerts);
    this.form.controls.news.setValue(this.userRecord.sendNewsAndTips);
    this.form.markAsPristine();
  }

  updateModel(): void {
    this.userRecord.name = this.form.controls.name.value;
    this.userRecord.sendNewsAndTips = this.form.controls.news.value;
    this.userRecord.sendSystemAlerts = this.form.controls.systemalerts.value;
  }

  goAhead() {
    if (this.form.valid) {
      this.loading = 'Saving';
      this.updateModel();
      this.userService.updateUser(this.userRecord).subscribe(o => {
        this.userRecord = o;
        this.updateForm();
        this.loading = null;
        this.dialogRef.close(this.userRecord);
      }, async err => {
        this.loading = null;
        await this.errorHandler.handleErr(err);
      });
    }
  }
}
