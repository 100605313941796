export class Resources {
  static AddOns = 'AddOns';
  static Labels = 'Labels';
  static Files = 'Files';
  static Agents = 'Agents';
  static Printers = 'Printers';
  static Users = 'Users';
  static Tables = 'DataTables';
}

export class Actions {

}


export class LocalStorageKeys {
  static ReopenAddOn = 'AddOns_Continue';
  static LastPage = 'LastPage';
  static ShowHomeAtStart = 'ShowHomeAtStart';

  static DesignManager_DefaultTemplateId = 'DesignManager_DefaultTemplateId';
  static DesignManager_ReturnToLabel = "DesignManager_returnToLabel";
  static DesignManager_Opt_Order = 'DesignManager_order';
  static DesignManager_Opt_Desc = 'DesignManager_desc';
  static DesignManager_Opt_GridOn = 'DesignManager_grid_on';
  static DesignManager_Opt_GridSize = 'DesignManager_grid_size';
  static DesignManager_Opt_ShowPreviews = 'DesignManager_showPreviews';
  static DesignManager_Opt_ViewMode = 'DesignManager_viewMode';

  static LibraryConfig= 'LibraryConfig';

  //static DefaultPrinter = 'Printer_Default';
  static PrinterParams = 'Printer_';
  static LabelDraft = 'Label_';

  static ConfirmLimitReached = 'Limits_Confirm';

  static TransientQueryParams = 'QueryParams';

  static PropertyGroupDefaults = 'PropertyGroups';
  static RecentActivity = 'RecentActivity';
  static SavePrinterOptions= "SavePrinterOptions"

}

export class OrganisationIDs {
  static SystemOrgID = '00000000-0000-0000-0000-000000000001';
}
