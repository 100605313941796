import { Boundary, Extent } from './Dimensions';
// import { PaperSize } from './PaperSize';
import Guid from '../../shared/guid';
import { ColorList } from '../../comps/color-list/color-list';
import {Unit_Of_Measure} from "../../services/unitConversion.service";

export class Media {

  public name: string;
  public defaultMeasure: Unit_Of_Measure = 'mm';
  public height: number;
  public width: number;
  public columns: number;
  public rows: number;
  public isContinuous: boolean;
  public outerMargins: Boundary = new Boundary(null);
  public innerMargins: Boundary = new Boundary(null);
  public landscape: boolean;
  public id: string;
  public isSystem: boolean;
  public color: string;

  static defaultNewMedia(): Media {
    const m = new Media();
    Object.assign(m,
      {
        columns: 1,
        defaultMeasure: 'mm',
        height: 100,
        id: Guid.create().toString(),
        innerMargins: new Boundary(),
        isContinuous: false,
        isSystem: true,
        landscape: false,
        name: '',
        outerMargins: new Boundary(),
        rows: 1,
        width: 150,
        color: ColorList.colors.get("White")
      });

    return m;
  }

  get labelSize(): Extent {
    const w = ((this.width - this.outerMargins.left - this.outerMargins.right) / this.columns)
      - this.innerMargins.left - this.innerMargins.right;
    const h = ((this.height - this.outerMargins.top - this.outerMargins.bottom) / this.rows)
      - this.innerMargins.top - this.innerMargins.bottom;
    return new Extent(w, h);
  }


  constructor(media?: Media ) {
    this.update(media);
  }

  update(media: Media) {
    if (media) {
      this.name = media.name;
      this.defaultMeasure = media.defaultMeasure;
      this.height = media.height;
      this.width = media.width;
      this.columns = media.columns;
      this.rows = media.rows;
      this.isContinuous = media.isContinuous;
      this.landscape = media.landscape;
      this.id = media.id;

      this.outerMargins = new Boundary(media.outerMargins);
      this.innerMargins = new Boundary(media.innerMargins);

      this.isSystem = media.isSystem;
      this.color = media.color || ColorList.colors.get("White");
    }
  }


  // updatePaperSize(ps: PaperSize) {
  //   if (ps) {
  //     this.height = ps.height;
  //     this.width = ps.width;
  //     this.landscape = ps.landscape;
  //     this.rows = ps.down;
  //     this.columns = ps.across;
  //     this.outerMargins = new Boundary();
  //     this.outerMargins.left = ps.pageLeft;
  //     this.outerMargins.top = ps.pageTop;
  //     this.outerMargins.right = ps.pageRight;
  //     this.outerMargins.bottom = ps.pageBottom;
  //     this.innerMargins.left = ps.labelLeft;
  //     this.innerMargins.top = ps.labelTop;
  //     this.innerMargins.right = ps.labelRight;
  //     this.innerMargins.bottom = ps.labelBottom;
  //   }
  // }
}
