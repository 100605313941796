import { NgxLoggerLevel } from 'ngx-logger';
import { Environment } from './environmentbase';

export const environment = new Environment({
  production: true,
  isApp: true,
  baseApiUrl: 'https://api.test.barxui.com/',
  msgApiUrl: 'wss://6dvcepq31i.execute-api.ap-southeast-2.amazonaws.com/test',
  paymentApiUrl: 'https://api.test.hexcom.com/payment/v1',
  helpUrl: 'https://help.barxui.com',
  startPageUrl: 'https://help.barxui.com',
  barxuiProductID: 'prod_Dv6CRX06jExNDn',
  expansionPackProductID: 'prod_FfLjHFg8hsKXn5',
  gaKey: '',
  stripePublishableKey: 'pk_test_9o3hbLabuyJJyZ1IpRHo7b7V',
  captchaSiteKey: '',

  feLogLevel: NgxLoggerLevel.DEBUG,
  feServerLogLevel : NgxLoggerLevel.ERROR,

  aws: {
    aws_project_region: "ap-southeast-2",
    aws_cognito_region: "ap-southeast-2",
    aws_user_pools_id: "ap-southeast-2_7mOCHWFNu",
    aws_user_pools_web_client_id: "1euagd1i9pt0eei3m2algivaj5",
    cookieStorage: {
      domain: '.test.barxui.com',
    },
    oauth: {
      domain: "auth.test.barxui.com",
      scope: [
        "email",
        "openid",
        "profile",
        "aws.cognito.signin.user.admin",
        "barxui.com/api.v1",
        "hexcom.com/payment.v1"
      ],
      redirectSignIn: "https://test.app.barxui.com/",
      redirectSignOut: "https://www.test.barxui.com/",
      responseType: "implicit"
    },
    federationTarget: "COGNITO_USER_POOLS"
  }
});
