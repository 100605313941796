export class Address {
    public line1: string;
    public line2: string;
    public line3: string;
    public suburb: string;
    public city: string;
    public region: string;
    public postCode: string;
    public country: string;

    constructor(address?: Address) {
        if (address) {
            this.line1 = address.line1;
            this.line2 = address.line2;
            this.line3 = address.line3;
            this.suburb = address.suburb;
            this.city = address.city;
            this.region = address.region;
            this.postCode = address.postCode;
            this.country = address.country;
        }
    }
}
