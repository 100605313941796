import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { ShapePacket } from '../models/view/ShapePacket';

// centralise all events from the event hub component to allow subscribers
@Injectable({
  providedIn: 'root'
})
export class EventHubService {


  PasteImage = new ReplaySubject<File>(1, 1);  // only send them once when subscribers are already listening.
  PasteShape = new ReplaySubject<ShapePacket>(1, 1);

  constructor() { }

  // handle paste of image or serialised shape.  Nothing else yet
  handlePaste(event: ClipboardEvent): void {

    // pasted an image?
    let pastedImage = this.getPastedImage(event);
    if (pastedImage) {
      this.PasteImage.next(pastedImage);
    } else {
      // pasted text of some kind
      for (let ii = 0; ii < event.clipboardData?.items?.length; ii++) {
        const item: DataTransferItem = event.clipboardData.items[ii];
        if (item.kind === 'string' && item.type === 'text/plain') {
          // is the text something we put there?
          item.getAsString(s => {
            let packet: ShapePacket;
            try {
              packet = new ShapePacket(JSON.parse(s));
            } catch { }

            if (packet && packet.shapes) {
              this.PasteShape.next(packet);
            } else {
              // not shapes, just raw text
              this.PasteShape.next(new ShapePacket({ raw: s, image: null, shapes: null, variables: null }));
            }
          });
        }
      }
    }
  }


  private getPastedImage(event: ClipboardEvent): File | null {
    if (
      event.clipboardData &&
      event.clipboardData.files &&
      event.clipboardData.files.length &&
      this.isImageFile(event.clipboardData.files[0])
    ) {
      return (event.clipboardData.files[0]);
    }
    return (null);
  }

  private isImageFile(file: File): boolean {
    return (file.type.search(/^image\//i) === 0);
  }

}
