import { Limit } from './Limit';

export class Resource {
    public resourceCode: string;
    public limit: Limit;

    constructor(r: Resource = null) {
        if (r) {
            this.resourceCode = r.resourceCode;
            this.limit = new Limit(r.limit);
        }

    }
} 
