<app-dialog-title title="Delete {{data.type | titlecase}}" icon="delete"></app-dialog-title>

<div mat-dialog-content>
  <p>Are you sure you want to permanently delete the {{ data.type }} <span>{{ data.sourceName }}</span>?</p>
  @if (data.type === 'table') {
    <p>This will also delete all of its associated queries.</p>
  }
  <p>Any associated labels may not be printable after deletion.</p>
</div>
<div mat-dialog-actions align="end">
  <button type="button" mat-raised-button mat-dialog-close>
    <mat-icon>cancel</mat-icon>
    Cancel
  </button>
  <button mat-raised-button color="primary" (click)="confirmDelete()">
    <mat-icon>delete</mat-icon>
    Delete {{ data.type | titlecase }}
  </button>
</div>
