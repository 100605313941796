import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { SampleOrg } from '../models/server/SampleOrg';
import Guid from '../shared/guid';
import { ServiceBase } from './serviceBase';

@Injectable({
  providedIn: 'root'
})
export class SampleService extends ServiceBase {

  constructor(
    httpClient: HttpClient) {
    super(httpClient);
  }

  getSamples(): Observable<SampleOrg[]> {
    const headers = this.getHeaders();
    return this.httpClient.get<SampleOrg[]>(environment.sampleApiUrl,
      {
        headers: headers
      });
  }


  addSamples(sampleOrgId: Guid): Observable<boolean> {
    const headers = this.getHeaders();
    return this.httpClient.post<boolean>(environment.sampleApiUrl + `/${sampleOrgId}`,
      null,
      {
        headers: headers
      });
  }


}
