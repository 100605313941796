import { Component, OnDestroy } from '@angular/core';
import {MatSnackBar, MatSnackBarModule} from '@angular/material/snack-bar';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { ErrorData, ErrorHandlerService, ErrorType } from './services/errorhandler.service';

@Component({
    selector: 'error-handler',
    templateUrl: './errorhandler.component.html',
    imports: [MatSnackBarModule],
    standalone: true,
})
export class ErrorHandlerComponent implements OnDestroy {

  private catchError: Subscription;
  //private snackConfig = { duration: 5000, panelClass: ['mat-toolbar', 'mat-acccent'] };
  public static snackConfigErr = { duration: 5000, panelClass: ['mat-toolbar', 'mat-warn'] };
  public static snackConfigErrSticky = { duration: 15 * 60000, panelClass: ['mat-toolbar', 'mat-warn'] };

  constructor(
    errorService: ErrorHandlerService,
    private snackBar: MatSnackBar,
    private logger: NGXLogger) {
    this.catchError = errorService.error.subscribe(ed => this.handleError(ed));
  }

  ngOnDestroy() {
    this.catchError.unsubscribe();
  }

  private handleError(ed: ErrorData) {

    const msg = ed.problem?.title || ed.message;

    switch (ed.type) {
      case ErrorType.AuthenticationExpired: // 401
        this.logger.info('auth expired', ed.message);
        this.snackBar.open(msg, 'Error!', ErrorHandlerComponent.snackConfigErr);
        break;

      case ErrorType.Warning:
        this.logger.warn(msg, ed.errorSource);
        break;

      case ErrorType.ServerError:
        this.logger.error(msg, ed.errorSource);
        this.snackBar.open(msg, 'Error!', ErrorHandlerComponent.snackConfigErr);
        break;

      case ErrorType.ClientError:
        this.logger.error(msg, ed.errorSource);
        this.snackBar.open(msg, 'Error!', ErrorHandlerComponent.snackConfigErr);
        break;

      case ErrorType.NotFound:
      case ErrorType.LabelNotFound:
        this.snackBar.open(msg, 'Error!', ErrorHandlerComponent.snackConfigErr);
        break;

      case ErrorType.LabelCorrupt:
        this.snackBar.open(msg, 'Error!', ErrorHandlerComponent.snackConfigErr);
        break;

      case ErrorType.Validation:
        this.snackBar.open(msg, 'Error!', ErrorHandlerComponent.snackConfigErr);
        break;

      case ErrorType.OrgLockedForMigration:
        this.snackBar.open(msg, 'Error!', ErrorHandlerComponent.snackConfigErrSticky);
        break;

    }
  }
}
