<app-dialog-title title="Limit Exceeded" icon="warning"></app-dialog-title>
<div mat-dialog-content>
  <!--<p class='mat-body'>Your organisation has exceeded the limits of your current subscription plan:</p>-->
  <p>
    Thanks for making great use of barxui. Your current
    subscription plan has served you well but has now <strong>exceeded its limits</strong>.
  </p>
  <ul>
    @for (l of data.exceeded; track l) {
      <li>{{ l.exceededMessage }}</li>
    }
  </ul>
  @if (!user.isSubscriber) {
    <div>
      You need to upgrade your subscription to increase the resources available to you. Please contact the subscriber
      or
      admin of barxui for your organisation and ask them to upgrade the barxui plan.
    </div>
  } @else {
    <div>
      You will need to upgrade your subscription to increase the resources available to you. Check the options
      available on the Organisation Subscription Settings.
    </div>
  }
</div>
<div mat-dialog-actions align="end">
  @if (user.isSubscriber) {
    <button mat-raised-button color="primary" mat-dialog-close
      routerLink="/settings/organisation">
      <mat-icon>subscriptions</mat-icon>
      Subscription
    </button>
  }
  <button mat-raised-button (click)="goAhead()">
    <mat-icon>done</mat-icon>
    OK
  </button>
</div>
