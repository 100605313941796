import {Query} from "./Query";
import {RowSelection} from "./PrintRequest";

export class QuerySelected {

  constructor(
    public query : Query,
    public rowSelection : RowSelection,
    public search : string){}
}
