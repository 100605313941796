// export enum PrintResultType {
//   started = 0,
//   labelComplete,
//   fileSizeExceeded,
//   printLimitReached,
//   serialNumberExceeded,
//   sequenceNumberExceeded,
//   invalidOutput,
//   expressionError,
//   expressionWarning,
//   completed = 98,
//   unknownError = 99,
// }

export class PrintResultStatus {
  message: string;
  count: number;
}

export class PrintResult {
  id: string;
  agentName: string;
  printerName: string;
  labelName: string;
  labelsPrinted: number;
  status: any;
  error: string;
  partialResult: boolean;

  constructor(pr : PrintResult = null) {
    if(pr) {
      this.id = pr.id;
      this.labelsPrinted = pr.labelsPrinted;
      this.status = pr.status;
      this.error = pr.error;
      this.partialResult = pr.partialResult;
      this.agentName = pr.agentName;
      this.printerName = pr.printerName;
      this.labelName = pr.labelName;
    }
  }

  shouldNotify(): boolean {
    return (
      this.status?.FileSizeExceeded?.length > 0 ||
      this.status?.PrintLimitReached?.length > 0 ||
      this.status?.SerialNumberExceeded?.length > 0 ||
      this.status?.SequenceNumberExceeded?.length > 0 ||
      this.status?.ExpressionError?.length > 0 || 
      this.status?.ExpressionWarning?.length > 0
    );
  }

  summary(): string {
    if(this.error) {
      //return this.error;
      return `Print of ${this.labelName}` 
      + (this.agentName ? ` on agent ${this.agentName}` : '')
      + ' failed.';
    }

    if(!this.status.LabelComplete) {
      return `Started Printing ${this.labelName}` 
      + (this.agentName ? ` on agent ${this.agentName}` : '')
      + (this.printerName ? ` to ${this.printerName}` : '');
    }

    return `Printed ${this.labelsPrinted} of ${this.labelName}` 
      + (this.agentName ? ` on agent ${this.agentName}` : '')
      + (this.printerName ? ` to ${this.printerName}` : '');
  }
}
