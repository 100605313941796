import { Component } from '@angular/core';
import { EventHubService } from '../../services/event-hub.service';

// Capture window events centrally

@Component({
    selector: 'app-event-hub',
    template: '',
    host: {
        "(window:paste)": "eventHubService.handlePaste( $event )"
    },
    standalone: true,
})
export class EventHubComponent  {

  constructor(
    private eventHubService: EventHubService) { }


}
