import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cacheable, CacheBuster } from 'ts-cacheable';
import { Observable, of, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CultureName } from '../models/server/CultureName';
import { Organisation } from '../models/server/Organisation';
import { ServiceBase } from './serviceBase';

const orgSubject = new Subject<void>();

@Injectable({
  providedIn: 'root'
})
export class OrganisationService extends ServiceBase {

  orgUpdate = new Subject<Organisation>();

  constructor(
    httpClient: HttpClient) {
    super(httpClient);
  }

  @CacheBuster({
    cacheBusterNotifier: orgSubject
  })
  resetCache(): Observable<null> {
    return of(null);
  }

  createOrganisation(emailAddress: string): Observable<Organisation> {
    const headers = this.getHeaders();
    return this.httpClient.post<Organisation>(environment.orgApiUrl,
      emailAddress,
      {
        headers: headers
      }).pipe(
      map(o => new Organisation(o)),
      tap(org => this.orgUpdate.next(org))
    );
  }

  @Cacheable({
    cacheBusterObserver: orgSubject
  })
  getOrganisation(): Observable<Organisation> {
    const headers = this.getHeaders();
    return this.httpClient.get<Organisation>(environment.orgApiUrl,
      {
        headers: headers
      }).pipe(
        map(o => new Organisation(o)),
        // tap(org => this.orgUpdate.next(org))
      );
  }

  @Cacheable({
    cacheBusterObserver: orgSubject
  })
  getOrganisationFlags(): Observable<string[]> {
    const headers = this.getHeaders();
    return this.httpClient.get<string[]>(environment.orgApiUrl + '/flags',
      {
        headers: headers
      });
  }

  @CacheBuster({
    cacheBusterNotifier: orgSubject
  })
  updateOrganisation(org: Organisation): Observable<Organisation> {
    const headers = this.getHeaders();
    return this.httpClient.post<Organisation>(environment.orgApiUrl,
      org,
      {
        headers: headers
      }).pipe(
        map(o => new Organisation(o)),
        tap(org => this.orgUpdate.next(org))
      );
  }

  @CacheBuster({
    cacheBusterNotifier: orgSubject
  })
  activateOrganisation(org: Organisation) {
    const headers = this.getHeaders();
    return this.httpClient.post<Organisation>(environment.orgApiUrl + '/activate',
      org,
      {
        headers: headers
      }).pipe(
        map(o => new Organisation(o)),
        tap(org => this.orgUpdate.next(org))
      );
  }

  getLocation(): Observable<string> {
    return this.httpClient.get<string>(environment.orgApiUrl + '/country');
  }

  @Cacheable()
  getCultureList(country: string): Observable<CultureName[]> {
    const headers = this.getHeaders();

    return this.httpClient.get<CultureName[]>(environment.orgApiUrl + `/cultures/${country}`,
      {
        headers: headers
      }).pipe(
        map(cs => {
          return cs.map(c => new CultureName(c.code, c.name));
        })
      );
  }

  clearTrialDate() : Observable<boolean> {
    const headers = this.getHeaders();
    return this.httpClient.post<boolean>(environment.orgApiUrl + `/clearTrial`,
      {
        headers: headers
      }
    );
  }
}
