<style>
  a.option {
  float: left;
  margin: 2.5%;
  border: solid 0.3em;
  background-color: #3f51b5;
  box-shadow: 5px 10px 8px #888888;
  color:white;
  text-align: center;
  width: 230px;
  font-weight: bold;
  text-transform: uppercase;
  box-sizing: border-box;
  text-decoration: none;
}
a.option h3 {
font-size: 2em;
font-weight: bold;
margin-top: 1em;
}
a.option p {
color: yellowgreen;
margin-top: 1em;
margin-bottom: 1.5em;
font-size: 1.5em;
}
a.option:hover {
color: #fff;
background: #3f51b5;
}
</style>
<div data-cy="home-price-component" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="0.5em"
  fxLayoutAlign="space-evenly center" fxLayoutAlign.lt-md="start center">
  @for (fs of featureSets; track fs) {
    <a class="option" [routerLink]="'/signup'">
      <h3>{{fs.name}}</h3>
      @for (plan of getPlan(fs); track plan) {
        <div data-cy="home-price-panel">
          {{plan.priceHint}}
        </div>
      }
      <p>Try now</p>
    </a>
  }
</div>
