import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { WarningPanelComponent } from '../../comps/warning-panel/warning-panel.component';
import { MatButtonModule } from '@angular/material/button';
import { SimpleProgressComponent } from '../../comps/simpleprogress.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexModule } from '@angular/flex-layout/flex';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, FlexModule, MatFormFieldModule, MatInputModule, SimpleProgressComponent, MatButtonModule, WarningPanelComponent]
})
export class ChangePasswordComponent implements OnInit {

  form: UntypedFormGroup;
  error: string;
  message: string;
  busy: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private authService: AuthenticationService) { }

  ngOnInit(): void {

    this.createForm();
  }

  update(): void {

    this.error = '';
    this.busy = false;
    this.message = '';

    if (this.form.valid) {
      this.busy = true;

      try {
        this.authService.changePassword(
          this.form.controls.password.value,
          this.form.controls.newpassword.value
        ).then(result => {

          if (result) {
            this.form.controls.password.setValue('');
            this.form.controls.newpassword.setValue('');
            this.form.markAsUntouched();
            this.message = 'Password updated.';
          }

        }).catch(err => {
          this.error = err.message;
        });

      } finally {
        this.busy = false;
      }
    }
  }

  private createForm(): void {
    this.form = this.fb.group({
      password: new UntypedFormControl('', Validators.required),
      newpassword: new UntypedFormControl('', Validators.required)
    })
  }
}
