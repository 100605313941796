export class PropertyExpression {
    propertyName: string;
    variableName: string;

  constructor(pex?: PropertyExpression) {

    if (pex) {
      Object.assign(this, pex);
    }
  }
}
