import {AuthenticationService} from './authentication.service';
import {Directive, Input, ElementRef, OnInit} from '@angular/core';
import {switchMap} from "rxjs/operators";
import {UserService} from "../services/user.service";
import {of} from "rxjs";

@Directive({
    selector: '[require]',
    standalone: true
})
export class AuthorisationDirective implements OnInit {

  // require can be list of permissions - any 1 can be true
  @Input('require')
  public requirePermission?: string;

  constructor(
    private authService: AuthenticationService,
    private userService: UserService,
    private element: ElementRef<HTMLElement>) {
  }

  ngOnInit() {
    let isAuthed = false;
    this.authService.isAuthenticated.pipe(
      switchMap((authed: boolean) => {
        isAuthed = authed;
        if (isAuthed) {
          return this.userService.getUserPolicies();
        } else {
          return of([]);
        }
      })
    ).subscribe(permissions => {
      const required = (this.requirePermission || '').split(';');
      let shouldHide = false;
      required.forEach(rp => {
        shouldHide = shouldHide || !permissions.includes(rp);
      });
      if (shouldHide) {
        this.element.nativeElement.style.display = 'none';
      } else {
        if(this.element.nativeElement.style?.display === 'none') {
          this.element.nativeElement.style.display = '';
        }
      }
    });
  }
}
