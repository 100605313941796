import { Limit } from './Limit';

export class Limitation {
    code: string;
    type: string;
    limit: Limit;
    percentageUsed: number;
    exceeded: number;
    exceededMessage: string;

    constructor(limit: Limitation = null) {
        if (limit) {
            this.code = limit.code;
            this.type = limit.type;
            this.limit = limit.limit;
            this.percentageUsed = limit.percentageUsed;
            this.exceeded = limit.exceeded;
            this.exceededMessage = limit.exceededMessage;
        }
    }
}
