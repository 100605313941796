import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceBase } from './serviceBase';

@Injectable({
  providedIn: 'root'
})
export class NewsService extends ServiceBase {

  constructor(
    httpClient: HttpClient) {
    super(httpClient);
  }

  getNews(): Observable<string> {
    return this.httpClient.get('/assets/home/news/news.html', { responseType: "text" });
  }
}
