import { PenStyle } from './PenStyle';

export class BorderStyle {
  public lineWidth: number;
  public color: string;
  public cornerRadius: number;
  public lineStyle: Array<number>;


  constructor(border?: BorderStyle) {
    if (border) {
      this.color = border.color;
      this.lineWidth = border.lineWidth;
      this.cornerRadius = border.cornerRadius;
      this.lineStyle = border.lineStyle;
    }
  }

  isVisible(): boolean {
    return !!this.color && this.lineWidth > 0.2;
  }
}
