import { ShapeBase, Shape, IShape } from './Shape';
import { ShapeComponent } from './ShapeComponent';
import { TextBlock } from '../TextBlock';
import { FillStyle } from '../FillStyle';
import { PropertyExpression } from '../PropertyExpression';
import { BorderStyle } from '../BorderStyle';
import { FormattedExpression } from '../FormattedExpression';


export class ShapeGroup extends ShapeBase implements IShape {

  static ShapeName = 'group';

  public shapes: IShape[] = new Array<IShape>();  // could be shapebase[] ??

  constructor(group?: ShapeGroup) {
    super(group);

    this.shapeName = 'Group';

    if (group?.shapes) {
      this.shapes = group.shapes; //.map(s => new );
    }
  }

  // IShape implementations - should be unused by ShapeGroup
  border: BorderStyle;
  //    data: string;    //BARCODEDATA
  doNotPrint: boolean;
  expressions: PropertyExpression[];
  fill: FillStyle;
  font: string;
  fontSize: string;
  keepProportional: boolean;
  rotation: number;
  text: TextBlock;
  textColor: string;
  zOrder: number;
  scale: number;
  scaleShapeHeightMM: number;
  scaleShapeWidthMM: number;
  anchorPoint: string;
  justify: string;
 

  isNaturallyVisible(): boolean {
    throw new Error('Method not implemented.');
    // end IShape implementations

  }

  isTightlyBoundShape(): boolean {
    throw new Error('Method not implemented.');
  };

  updateWithVar(variable: FormattedExpression): void {

  }

}
