export enum PromptedQuestionType {
  Number = 1,
  String = 2,
  Bool = 3,
  Date = 4,
  Choice = 5,
  List = 6,
  Text = 7
}

export class PromptedQuestionConstants {
  static readonly Param_QuestionType = 'questionType';
  static readonly Param_DefaultValue = 'defaultValue';
  static readonly Param_Required = 'required';
  static readonly Param_Prompt = 'prompt';

  static readonly Param_Choice_Items = 'items';
  static readonly Param_Choice_UseSelect = 'useSelect';

  static readonly Param_List_QueryID = 'queryID';
  static readonly Param_List_DisplayColumn = 'displayColumn';
  static readonly Param_List_ValueColumn = 'valueColumn';

  static readonly Param_Number_Min = 'min';
  static readonly Param_Number_Max = 'max';

  static readonly Param_String_Pattern = 'pattern';
  static readonly Param_String_MaxLength = 'maxLength';

  // static readonly Param_Text_MaxLines = 'maxLines';

  static readonly Param_Date_Offset = 'offset';
  static readonly Param_Date_Units = 'units';
  static readonly Param_Date_OffsetHours = 'offsetHours';
}

export class PromptedQuestion {
  name: string;
  dataType: PromptedQuestionType;
  prompt: string;
  required: boolean;
  defaultValue: any;

  constructor(params?: Map<string, any>) {
    if (params) {
      this.prompt = params[PromptedQuestionConstants.Param_Prompt] || '';
      this.required = params[PromptedQuestionConstants.Param_Required] || false;
    }
  }
}

export class PromptedNumberQuestion extends PromptedQuestion {
  minValue: number;
  maxValue: number;
  pattern: string;

  constructor(params?: Map<string, any>) {
    super(params);
    this.dataType = PromptedQuestionType.Number;
    if (params) {
      this.defaultValue = +params[PromptedQuestionConstants.Param_DefaultValue] || 0;
      this.minValue = params[PromptedQuestionConstants.Param_Number_Min] || 0;
      this.maxValue = params[PromptedQuestionConstants.Param_Number_Max] || 10000000;
      // this.pattern = params[PromptedQuestionConstants.Param_String_Pattern] || '';
    }
  }
}

export class PromptedStringQuestion extends PromptedQuestion {
  maxLength: number;
  pattern: string;

  constructor(params?: Map<string, any>) {
    super(params);
    this.dataType = PromptedQuestionType.String;
    if (params) {
      this.defaultValue = params[PromptedQuestionConstants.Param_DefaultValue] || '';
      this.maxLength = params[PromptedQuestionConstants.Param_String_MaxLength] || 100;
      // this.pattern = params[PromptedQuestionConstants.Param_String_Pattern] || '';
    }
  }
}

export class PromptedTextQuestion extends PromptedQuestion {
  maxLines: number;
  // pattern: string;

  constructor(params?: Map<string, any>) {
    super(params);
    this.dataType = PromptedQuestionType.Text;
    if (params) {
      this.defaultValue = params[PromptedQuestionConstants.Param_DefaultValue] || '';
      // this.maxLines = params[PromptedQuestionConstants.Param_Text_MaxLines] || 3;
      // this.pattern = params[PromptedQuestionConstants.Param_String_Pattern] || '';
    }
  }
}

export class PromptedBoolQuestion extends PromptedQuestion {
  constructor(params?: Map<string, any>) {
    super(params);
    this.dataType = PromptedQuestionType.Bool;
    if (params) {
      this.defaultValue = params[PromptedQuestionConstants.Param_DefaultValue] || false;
    }
  }
}

export class PromptedDateTimeQuestion extends PromptedQuestion {
  promptDate: boolean;
  promptTime: boolean;
  min: Date;
  max: Date;

  constructor(params?: Map<string, any>) {
    super(params);
    this.dataType = PromptedQuestionType.Date;
    if (params) {
      this.defaultValue = params[PromptedQuestionConstants.Param_DefaultValue] || 0;
      this.promptDate = true;
      this.promptTime = false;
    }
  }
}

export class PromptedChoiceQuestion extends PromptedQuestion {
  items: Array<string>;
  useSelect: boolean = false;

  constructor(params?: Map<string, any>) {
    super(params);
    this.dataType = PromptedQuestionType.Choice;
    if (params) {
      this.defaultValue = params[PromptedQuestionConstants.Param_DefaultValue] || '';
      this.items = (params[PromptedQuestionConstants.Param_Choice_Items] || '').split('\n');
      // this.items = this.items.map(item => item.trim().length > 0 ? item.trim() : '[blank]');
      this.useSelect = params[PromptedQuestionConstants.Param_Choice_UseSelect] || false;
    }
  }
}

export class PromptedListQuestion extends PromptedQuestion {
  queryId: string;
  displayColumn: string;
  valueColumn: string;

  constructor(params?: Map<string, any>) {
    super(params);
    this.dataType = PromptedQuestionType.List;
    if (params) {
      this.defaultValue = params[PromptedQuestionConstants.Param_DefaultValue] || '';
      this.queryId = params[PromptedQuestionConstants.Param_List_QueryID] || null;
      this.valueColumn = params[PromptedQuestionConstants.Param_List_ValueColumn] || '';
      this.displayColumn = params[PromptedQuestionConstants.Param_List_DisplayColumn] || '';
    }
  }
}
