<!-- <app-bganimation tagName="body" className="animated"></app-bganimation> -->
<div class="page">
  <main class="notools nopad">
    @if (isAuthenticated) {
      <app-home-start [isOrgActivate]="orgIsActivated"></app-home-start>
    } @else {
      <div class="featuresections" fxLayout="column" fxLayoutAlign="start center" fxLayoutAlign.lt-sm="center start"
        fxLayoutGap="2em">
        <div class="featuresection" fxLayout="row" fxLayoutGap="1em">
          <div class="graphic" fxHide.lt-sm><img src="/assets/home/barxui-cloud.png" width="250px"/></div>
          <div>
            <h2>Cloud label design & printing solutions</h2>
            <p>Designed for retail, manufacturing, logistics and more</p>
          </div>
        </div>
        <div class="featuresection" fxLayout="row" fxLayoutGap="1em">
          <a fxHide.lt-sm id="features"></a>
          <div>
            <h2>Feature rich cloud based software</h2>
            <p>Fast, flexible & affordable online tools for all your labelling needs</p>
            <ul class="options">
              <li>
                <span class="icon"><img src="/assets/home/template-designer.svg"/></span>
                <a>easy to use template designer</a>
              </li>
              <li>
                <li>
                  <span class="icon"><img src="/assets/home/connect.svg"/></span>
                  <a>integrate with enterprise and cloud system</a>
                </li>
                <li>
                  <span class="icon"><img src="/assets/home/printer.svg"/></span>
                  <a>fast printing to local printers</a>
                </li>
                <li>
                  <span class="icon"><img src="/assets/home/team.svg"/></span>
                  <a>remote & distributed staff access</a>
                </li>
                <li>
                  <span class="icon"><img src="/assets/home/pricing.svg"/></span>
                  <a>flexible pricing plans</a>
                </li>
              </ul>
            </div>
            <div class="graphic" fxHide.lt-sm><img src="/assets/home/tick.svg" width="150"/></div>
          </div>
          <div class="featuresection" fxLayout="row" fxLayoutGap="1em">
            <a fxHide.lt-sm id="business"></a>
            <div class="graphic" fxHide.lt-sm><img src="/assets/home/package.svg" width="150"/></div>
            <div>
              <h2>The right tool for your business</h2>
              <p>Labels for all aspects of production, distribution, retail and more</p>
              <ul class="options">
                <li>
                  <span class="icon"><img src="/assets/home/noun_Factory_583073.svg"/></span>
                  <a>manufacturing</a>
                  <!--
                  [mdePopoverTriggerFor]="manPopover" mdePopoverTriggerOn="hover"
                  #popoverTrigger="mdePopoverTrigger"
                  <mde-popover #manPopover="mdePopover"
                    [mdePopoverOverlapTrigger]="false">
                    <mat-card>
                      <mat-card-content>
                        <mat-card-title>barxui for Manufacturing</mat-card-title>
                        <p>barxui is used by in manufactors for:</p>
                        <ul>
                          <li>Product / Item Labels</li>
                          <li>Carton / Logistic Labels</li>
                        </section>
                        <li>SSCC Pallet Labels</li>
                        <li>GS1 Barcodes</li>
                      </ul>
                    </mat-card-content>
                  </mat-card>
                </mde-popover> -->
              </li>
              <li>
                <span class="icon"><img src="/assets/home/noun_Retail_3407139.svg"/></span>
                <a>retail &amp; wholesale</a>
                <!--
                [mdePopoverTriggerFor]="retPopover" mdePopoverTriggerOn="hover"
                #popoverTrigger="mdePopoverTrigger"
                <mde-popover #retPopover="mdePopover"
                  [mdePopoverOverlapTrigger]="false">
                  <mat-card>
                    <mat-card-content>
                      <mat-card-title>barxui for Retail & Wholesale</mat-card-title>
                      <p>barxui is used by retail and wholesale organisations for:</p>
                      <ul>
                        <li>Signs and Posters</li>
                        <li>Pricing Labels</li>
                        <li>Shelf Labels</li>
                        <li>GS1 Barcodes</li>
                      </ul>
                    </mat-card-content>
                  </mat-card>
                </mde-popover> -->
              </li>
              <li>
                <span class="icon"><img src="/assets/home/noun_warehouse_980180.svg"/></span>
                <a>warehousing &amp; Logistics</a>
                <!-- [mdePopoverTriggerFor]="retPopover" mdePopoverTriggerOn="hover"
                #popoverTrigger="mdePopoverTrigger"
                <mde-popover #logPopover="mdePopover" [mdePopoverOverlapTrigger]="false">
                  <mat-card>
                    <mat-card-content>
                      <mat-card-title>barxui for Warehousing &amp; Logistics</mat-card-title>
                      <p>barxui is used by warehouses for:</p>
                      <ul>
                        <li>Pick Lists</li>
                        <li>Racking Labels</li>
                      </section>
                      <li>SSCC Pallet Labels</li>
                      <li>GS1 Barcodes</li>
                      <li>Packing Slips / Address Labels</li>
                    </ul>
                  </mat-card-content>
                </mat-card>
              </mde-popover> -->
            </li>
            <li>
              <span class="icon"><img src="/assets/home/noun_medical_2139883.svg"/></span>
              <a>medical</a>
              <!-- [mdePopoverTriggerFor]="medPopover" mdePopoverTriggerOn="hover"
              #popoverTrigger="mdePopoverTrigger"
              <mde-popover #medPopover="mdePopover" [mdePopoverOverlapTrigger]="false">
                <mat-card>
                  <mat-card-content>
                    <mat-card-title>barxui for Medical</mat-card-title>
                    <p>barxui can be used in medical applications for:</p>
                    <ul>
                      <li>Specimen Labels</li>
                      <li>Product Labels</li>
                      <li>QR Codes</li>
                      <li>GS1 Barcodes</li>
                    </ul>
                  </mat-card-content>
                </mat-card>
              </mde-popover> -->
            </li>
          </ul>
          <!-- <p align="center">
          <a href="#" class="button">Case Studies</a>
        </p> -->
      </div>
    </div>
    <div class="featuresection" fxLayout="row" fxLayoutGap="1em">
      <a fxHide.lt-sm id="pricing"></a>
      <div>
        <h2>Pricing plans to fit your usage</h2>
        <p>Try all features for free then choose which plan best suits your needs.
          <p>
            <p>Plans can be expanded or reduced at any time.</p>
          </div>
          <div class="graphic" fxHide.lt-sm><img src="/assets/home/pricing-plans.svg" width="150"/></div>
        </div>
        <div class="featuresection" fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="center start" style="width: 100%;">
          <app-home-pricing></app-home-pricing>
        </div>
      </div>
    }
  </main>
</div>
