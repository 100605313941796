import {
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
  Renderer2
} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {DomSanitizer, SafeStyle} from '@angular/platform-browser';
import {ExternalFile, ExternalFileTypeEnum} from "../models/server/ExternalFile";

@Directive({
    selector: '[fileDND]',
    standalone: true
})
export class FileDNDDirective
  implements OnInit {

  // @HostBinding('style.background')
  // public background = '#eee';

  @HostBinding("style.background")
  background: SafeStyle;

  @Input()
  maxFileSize: number = 3; // 2 mb

  @Input()
  multiple: boolean = false;

  @Input()
  fileType: ExternalFileTypeEnum;

  @Input()
  fileInputId: string;

  @Output()
  private filesDropped: EventEmitter<DragEvent> = new EventEmitter();

  @Output()
  private filesError: EventEmitter<string> = new EventEmitter();

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private domSanitizer: DomSanitizer,
    @Inject(DOCUMENT) private document) {

    this.background = domSanitizer.bypassSecurityTrustStyle('#eee');

  }

  ngOnInit() {
    // const child = document.createElement('div');
    // this.renderer.appendChild(this.elementRef.nativeElement, child);
    // let ele = this.elementRef.nativeElement;
  }

  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = this.domSanitizer.bypassSecurityTrustStyle('#bbb');
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = this.domSanitizer.bypassSecurityTrustStyle('#eee');
  }

  @HostListener('drop', ['$event']) public onDrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    const files = evt.dataTransfer.files;

    this.background = this.domSanitizer.bypassSecurityTrustStyle('#eee');

    // make sure this is really a file and not a var or shape with an image on the button that looks like a file
    if(evt.dataTransfer.getData('barxui-varname') || evt.dataTransfer.getData('barxui-shapetype')) {
      return;
    }

    if (files.length > 0) {
      this.background = this.domSanitizer.bypassSecurityTrustStyle('#999');
      if (this.checkFiles(files)) {
        this.filesDropped.emit(evt);
      }
    }
  }

  private checkFiles(files: any): boolean {
    if (files && (files.length === 1 || this.multiple)) {
      // const ext = files[0].name.split('.').pop().toLowerCase();
      //
      // let fileTypes = [this.fileType];
      // if(this.fileType === ExternalFileTypeEnum.user ) {
      //   fileTypes = ExternalFile.UserFileTypes;
      // }
      //
      //
      //
      // switch (this.fileType) {
      //
      //   case ExternalFileTypeEnum.image:
      //     if (ext !== 'png' && ext !== 'jpg' && ext !== 'gif' && ext !== 'jpeg' && ext !== 'bmp') {
      //       this.filesError.emit('Only image files can be uploaded');
      //       return false;
      //     }
      //     break;
      //
      //   case ExternalFileTypeEnum.pdf:
      //     if (ext !== 'pdf') {
      //       this.filesError.emit('Unknown file type or not a PDF');
      //       return false;
      //     }
      //     break;
      //
      //
      //   case 'data':
      //     if (ext !== 'csv' && ext !== 'xls' && ext !== 'xlsx') {
      //       this.filesError.emit('Only data files can be uploaded');
      //       return false;
      //     }
      //     break;
      //
      //   case 'zip':
      //     if (files[0].type !== 'application/zip' && files[0].type !== 'application/x-zip-compressed') {
      //       this.filesError.emit('Only zip files can be uploaded');
      //       return false;
      //     }
      //     break;
      //
      //   default:
      //     this.filesError.emit('Unknown file type requested');
      //     return false;
      // }

      if (files[0].size > this.maxFileSize * 1024 * 1024) {
        this.filesError.emit(`The maximum file size is ${this.maxFileSize}MB`);
        return false;
      }

    } else {
      if (!this.multiple) {
        this.filesError.emit('Select a single file');
      } else {
        this.filesError.emit('Select one or more files');
      }
      return false;
    }

    return true;

  }
}


