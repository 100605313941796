import {Component, OnDestroy, OnInit} from '@angular/core';
import {MessageService} from "../../services/message.service";
import {Subscription} from "rxjs";
import {Message} from "../../models/server/Messages/Messages";
import {MatSnackBar} from "@angular/material/snack-bar";
import {PrintResult} from "../../models/server/PrintResult";
import {PrintResultDialogComponent} from "../../print/print-result-dialog/print-result-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {ErrorHandlerComponent} from '../../errorhandler.component';
import {HTMLSnackbarContentComponent} from "../htmlsnackbar-content/htmlsnackbar-content.component";

@Component({
    selector: 'app-notifications',
    template: '',
    standalone: true
})
export class NotificationsComponent implements OnInit, OnDestroy {

  msgServiceSub: Subscription;

  constructor(
    private snackBar: MatSnackBar,
    private msgService: MessageService,
    private dialog: MatDialog
  ) {
    if (this.snackBar == null) {
      console.log("feck");
    }
  }

  ngOnInit(): void {
    this.msgService.connectionChange.subscribe(connected => {
      if (connected) {
        this.msgServiceSub = this.msgService.messages.subscribe(msg => this.processMessage(msg));
      }
    });

  }

  ngOnDestroy() {
    this.msgServiceSub?.unsubscribe();
    this.msgServiceSub = null;
  }

  private processMessage(msg: Message<any>): void {
    switch (msg.type) {
      case 'printresult': {
        let pr = new PrintResult(msg.data);
        let config = pr.error ? ErrorHandlerComponent.snackConfigErr : {duration: 10000};

        if (pr.status.LabelComplete) {
          let sbRef = this.snackBar.openFromComponent(HTMLSnackbarContentComponent, {
            ...config,
            data: {
              html: pr.summary(),
              label: 'View Results'
            }
          });
          sbRef.onAction().subscribe(_ => {
            // show full results dialog
            this.dialog.open(PrintResultDialogComponent, {data: pr});
          });
        } else {
          this.snackBar.open(pr.summary(), '', config);
        }
      }
    }

  }

}
