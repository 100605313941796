import {HttpClient, HttpEvent, HttpParams} from '@angular/common/http';
import {EventEmitter, Injectable} from '@angular/core';

import {of, Subject} from 'rxjs';
import {Observable, Subscriber} from 'rxjs';
import {catchError, switchMap, tap} from 'rxjs/operators';

import {environment} from '../../environments/environment';
import {
  ExternalFile,
  ExternalFileRequest,
  ExternalFileResponse,
  ExternalFileTypeEnum
} from '../models/server/ExternalFile';
import {PagedResponse} from '../models/server/PagedResponse';
import {ImageShape} from '../models/server/Shapes/ImageShape';
import {ServiceBase} from './serviceBase';
import {UnitConversionService} from './unitConversion.service';
import {FileRenameResult} from "../models/server/FileRenameResult";
import {registerLicense} from '@syncfusion/ej2-base';
import {Cacheable} from "ts-cacheable";

export enum FileSearchOrderEnum {
  // MUST match server enum
  name,
  updated
}

export interface FileSearchRequest {
  types: ExternalFileTypeEnum[];
  searchText: string;
  searchID: string;
  page: number;
  pageSize: number;
  orderBy: FileSearchOrderEnum;
  descending: boolean;
}

const imageSubject = new Subject<void>();

@Injectable({
  providedIn: 'root'
})
export class FileService
  extends ServiceBase {

  onProcessing: EventEmitter<boolean> = new EventEmitter<boolean>();

  private keySet = false;

  constructor(
    httpClient: HttpClient,
    private convertor: UnitConversionService) {
    super(httpClient);
  }

  setSyncfusionKey(): void {
    const headers = this.getHeaders();

    if (!this.keySet)
      this.httpClient.get<string>(
        environment.fileApiUrl + '/pdf/key',
        {
          headers: headers,
        }).subscribe(key => {
        if (key) {
          this.keySet = true;
          registerLicense(key);
        }
      });
  }

  getFile(id: string): Observable<ExternalFile> {
    const headers = this.getHeaders();
    const parms = new HttpParams()
      .set('id', id);

    return this.httpClient.get<ExternalFile>(
      environment.fileApiUrl,
      {
        headers: headers,
        params: parms
      })
      .pipe(
        switchMap(x => {
          return of(new ExternalFile(x));
        })
      );
  }

  getFileList(request: FileSearchRequest): Observable<PagedResponse<ExternalFile>> {
    const headers = this.getHeaders();

    return this.httpClient.post<PagedResponse<ExternalFile>>(
      environment.fileApiUrl + '/list',
      request,
      {
        headers: headers,
      })
      .pipe(
        tap(pr => {
          pr.values = pr.values.map(x => new ExternalFile(x));
        })
      );
  }

  findPage(type: ExternalFileTypeEnum, id: string, pageSize: number): Observable<number> {
    const headers = this.getHeaders();

    const request = {
      searchID: id,
      pageSize,
      type
    }

    return this.httpClient.post<number>(
      environment.fileApiUrl + '/findpage',
      request,
      {
        headers: headers
      });
  }


  uploadFile(file: File, relativePath: string, fileType: ExternalFileTypeEnum, convertTo: ExternalFileTypeEnum | null): Observable<HttpEvent<ExternalFileResponse[]>> {

    const type = file.name.split('.').pop() || 'unknown';
    // use file extension for type

    const formData = new FormData();
    formData.append('file', file, relativePath);
    formData.append('externalFileType', fileType.toString());
    formData.append('subType', type);
    if (convertTo !== null) {
      formData.append('convertTo', convertTo.toString());
    }

    return this.httpClient.post<ExternalFileResponse[]>(
      environment.fileApiUrl + '/upload',
      formData,
      {
        observe: 'events',
        reportProgress: true,
        responseType: 'json'
      });
  }

  replaceFile(id: string, file: Blob, newName: string = null): Observable<HttpEvent<ExternalFileResponse[]>> {

    const formData = new FormData();
    formData.append('id', id);
    formData.append('file', file);
    if (newName) {
      formData.append('newName', newName);
    }

    return this.httpClient.post<ExternalFileResponse[]>(
      environment.fileApiUrl + '/upload',
      formData,
      {
        observe: 'events',
        reportProgress: true,
        responseType: 'json'
      });
  }

  copyFile(id: string, newName: string): Observable<ExternalFileResponse> {

    return this.httpClient.post<ExternalFileResponse>(
      environment.fileApiUrl + '/copy',
      {id: id, newName: newName},
      {
        responseType: 'json'
      });
  }

  deleteFile(id: string): Observable<any> {
    const headers = this.getHeaders();
    const parms = new HttpParams()
      .set('id', id);

    return this.httpClient.delete<any>(
      environment.fileApiUrl + '/delete',
      {
        headers: headers,
        params: parms
      });
  }

  deleteFiles(ids: string[]): Observable<any> {
    const headers = this.getHeaders();

    return this.httpClient.post<any>(
      environment.fileApiUrl + '/delete',
      ids,
      {
        headers: headers
      });
  }


  get uploadUrl(): string {
    return environment.fileApiUrl + '/upload';
  }

  // getImage(id: string, size?: Extent, keepProportional?: boolean): Observable<Blob> {
  //   const imageUrl = environment.fileApiUrl + '/image';
  //   let parms = new HttpParams()
  //     .set('id', id);
  //   if (size) {
  //     parms = parms.set('width', size.width.toString())
  //       .set('height', size.height.toString())
  //       .set('maintainAspectRatio', keepProportional.toString());
  //   }

  //   this.onProcessing.emit(true);

  //   return this.httpClient
  //     .get(imageUrl, {
  //       params: parms,
  //       responseType: 'blob'
  //     }).pipe(
  //       catchError((err, ob) => of(null)),
  //       tap(x => {
  //         this.onProcessing.emit(false);
  //         return x;
  //       })
  //     );
  // }

  processImage(image: ImageShape, useThumbnail: boolean, dpi?: number): Observable<Blob> {
    const imageUrl = environment.fileApiUrl + '/image/process';
    let parms = new HttpParams()
      .set('thumb', useThumbnail.toString());

    if (dpi) {
      parms = parms.set('dpi', dpi.toString());
    }

    this.onProcessing.emit(true);

    return this.httpClient
      .post(imageUrl,
        image,
        {
          params: parms,
          responseType: 'blob'
        }).pipe(
        catchError((err, ob) => of(null)),
        tap(x => {
          this.onProcessing.emit(false);
          return x;
        })
      );
  }

  getFileBlob(id: string, useThumbnail: boolean): Observable<Blob> {
    const imageUrl = environment.fileApiUrl + '/image';
    const parms = new HttpParams()
      .set('id', id)
      .set('thumb', useThumbnail.toString());

    this.onProcessing.emit(true);

    return this.httpClient
      .get(imageUrl,
        {
          params: parms,
          responseType: 'blob'
        }).pipe(
        catchError((err, ob) => of(null)),
        tap(x => {
          this.onProcessing.emit(false);
          return x;
        })
      );
  }

  getFileZipBlob(ids: string[]) {
    const url = environment.fileApiUrl + '/zip';

    this.onProcessing.emit(true);

    return this.httpClient
      .post(url,
        ids,
        {
          responseType: 'blob'
        }).pipe(
        catchError((err, ob) => of(null)),
        tap(x => {
          this.onProcessing.emit(false);
          return x;
        })
      );
  }

  getThumbnailUrl(id: string): string {
    return environment.fileApiUrl + `/image?id=${id}&thumb=true`;
  }

  @Cacheable({
    cacheBusterObserver: imageSubject
  })
  getImgBlobUrl(url: string): Observable<string> {
    return new Observable<string>((observer: Subscriber<string>) => {
      let objectUrl: string = null;

      this.httpClient
        .get(url, {
          // headers: new HttpHeaders(),
          responseType: 'blob'
        })
        .subscribe(m => {
          objectUrl = null;
          if (m) {
            objectUrl = URL.createObjectURL(m);
            observer.next(objectUrl);
          }
        });

      return () => {
        if (objectUrl) {
          // URL.revokeObjectURL(objectUrl);
          objectUrl = null;
        }
      };
    });
  }

  renameFile(id: string, fileName: string): Observable<FileRenameResult> {
    const imageUrl = environment.fileApiUrl + '/rename/' + id;
    let parms = new HttpParams()
      .set('id', id);

    return this.httpClient
      .post<FileRenameResult>(imageUrl,
        {
          fileName: fileName
        })
      .pipe(
        tap(x => {
          return x;
        })
      );
  }

  updateFile(file: ExternalFile): Observable<ExternalFile> {
    const headers = this.getHeaders();

    return this.httpClient.post<ExternalFile>(
      environment.fileApiUrl,
      file,
      {
        headers: headers,
      })
      .pipe(
        tap(x => {
          return new ExternalFile(x);
        })
      );
  }
}
