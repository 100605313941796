import { Limitation } from './server/Limitation';

export class LimitExceededEvent {


  confirmed = false;

  constructor(public exceeded: Limitation[]) {
  }

}
