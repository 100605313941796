<app-dialog-title title="Print Results" icon="error">
</app-dialog-title>
<div mat-dialog-content class='dlg'>
  <div fxLayout="column" fxLayoutGap="0.25rem">
    <div class="mat-body">
      {{data.labelsPrinted === 0 ? 'No' : data.labelsPrinted}} label{{data.labelsPrinted > 1 ? 's were' : ' was'}}
      printed.
    </div>
    @if (data.partialResult) {
      <div class="mat-body">
        <span style="font-weight:bold">Not all labels have been printed! Check the errors or warnings below.</span>
      </div>
    }
    @if (warnings) {
      <div class="mat-body" style="font-weight:bold">
        Labels may not include the correct values!
      </div>
    }
    @if (error) {
      <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="0.25rem" class="mat-body" style="font-weight:bold">
        <mat-icon [style.color]="'red'">error</mat-icon>
        <span>{{error}}</span>
      </div>
    }
    @if (data.shouldNotify()) {
      @if (messages) {
        <mat-table [dataSource]="messages">
          <ng-container matColumnDef="icon">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let element">
              <mat-icon [style.color]="element.icon === 'error' ? 'red' : 'orange'">{{element.icon}}</mat-icon>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="message">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.message}} </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
      }
    } @else {
      <div class="mat-body">
        There were no errors or warnings generating the labels.
      </div>
    }
  </div>
</div>
<div mat-dialog-actions align="end">
  <button type="button" color="primary" mat-raised-button mat-dialog-close>
    <mat-icon>done</mat-icon>
    OK
  </button>
</div>
