export class PagedResponse<T>   {
  public values: T[];
  public page: number;
  public totalPages: number;
  public totalItems: number;
  public pageSize: number;

  constructor(pr?: PagedResponse<T>, itemType?) {
    if (pr) {
      this.page = pr.page;
      this.totalItems = pr.totalItems;
      this.totalPages = pr.totalPages;
      this.pageSize = pr.pageSize;

      this.values = new Array<T>();
      pr.values.forEach(v => {
        this.values.push(new itemType(v));
      });
    }
  }

}
