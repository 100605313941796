<style>
  .toolbutton {
  width: 160px;
}
.shadowLogo {
width:300px;
/*box-shadow: 0 0 12pt rgba(0, 0, 0, 0.25);*/
/*border: 1px solid #ccc;*/
}
</style>
<div class="page">
  <main class="notools nopad">
    @if (organisation?.isTrialExpired ) {
      <div fxLayout="row" fxLayoutGap="0.25em"
        fxLayoutAlign="center center" class="panelwarn">
        <div>Your trial period expired {{organisation.trialEnds | date}}. To continue using barxui, setup a <a routerLink="/settings/organisation">paid subscription</a> or contact support if you would like an extension.</div>
      </div>
    }
    <div fxLayout="row" fxLayoutAlign="center stretch" fxLayout.lt-lg="column" fxLayoutAlign.lt-lg="stretch stretch"
      fxLayoutGap="2em" style="margin:3em;">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-evenly start" fxLayoutGap="1rem">
        <div fxFlex="25">
          <!--          <img src="./assets/barxuinewblack.png">-->
          <img alt="barxui" src="/assets/barxuilogo3.png" title="bar-zoo-eee" class="shadowLogo">
          @if (!organisation?.isActivated) {
            <h2>Lets get started!</h2>
            <div>
              To begin using barxui, please enter your organisation details and start your trial.
            </div>
            <div>
              <button data-cy="home-button" mat-raised-button color="primary" class="btn"
                routerLink="/settings/organisation">
                <mat-icon>business</mat-icon>
                Settings
              </button>
            </div>
          } @else {
            @if (user.isSubscriber || user.isAdmin) {
              <h2>Getting Started</h2>
              <p>Its easy to get started printing great looking labels with barxui. Just click one of the buttons below
                or select from the Recent Activity.
              </p>
              <div fxLayout="column" fxLayoutGap="0.5rem" fxLayoutAlign="start start">
                <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="0.5rem" fxLayoutAlign="space-between center"
                  fxLayoutAlign.lt-lg="start center">
                  <div>Add sample labels and data</div>
                  <div>
                    <button mat-raised-button (click)="addSamples()" class="toolbutton">Add
                      Samples
                    </button>
                  </div>
                </div>
                <!-- <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="0.25" fxLayoutAlign="space-between start"
                fxLayoutAlign.lt-lg="start center">
                <div>Coming soon, create labels from your data</div>
                <div><button mat-stroked-button [disabled]="true" class="toolbutton">Label
                Wizard</button></div>
              </div> -->
            </div>
          } @else {
            <!--              Nothing to display here for operators etc.-->
          }
          <h2 style="margin-top:1rem">Recent Activity</h2>
          @if (!!recentActivity.length) {
            <ul style="list-style-type: none;">
              @for (ra of recentActivity; track ra) {
                <li>
                  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.25rem">
                    <mat-icon>{{getIcon(ra.kind)}}</mat-icon>
                    <a [routerLink]="ra.url" [queryParams]="ra.params">{{ra.label}}</a>
                  </div>
                </li>
              }
            </ul>
            <button mat-raised-button routerLink="/design">View All Labels</button>
          } @else {
            <h4>Your recent activity will show here.</h4>
          }
          <div fxLayoutGap="0.25em" fxLayoutAlign="start center" style="margin-top:2em">
            <label for="showStartup">Show Welcome page on Startup</label>
            <mat-checkbox id="showStartup" [checked]="showAtStartup" (change)="toggleShowAtStartup()"></mat-checkbox>
          </div>
        }
      </div>
      <div fxFlex="50" [innerHtml]="newsContent | async | safe:'html' ">
      </div>
    </div>
  </div>
</main>
</div>
