import { Extent, Point } from '../Dimensions';
import { Shape } from './Shape';

export enum IMAGE_STYLE {
  Normal
}

export class ImageShape
  extends Shape<IMAGE_STYLE> {

  static ShapeType = 'image';

  // brightness: number;
  // saturation: number;
  // hue: any;
  colorMode: string;
  colorThreshold: number;
  // contrast: number;
  // effect: string;
  flipHorizontal: boolean;
  flipVertical: boolean;
  imageFileID: string;
  imageFileName: string;
  invertColors: boolean;
  // opacity: number;
  // processMode: string;

  constructor(image?: ImageShape) {
    super(image);
    this.shapeName = 'Image';
    this.style = IMAGE_STYLE.Normal;
    // this.opacity = 100;

    if (image) {
      this.update(image);
      //this.style = image.style;
    }
  }

  update(image: ImageShape): void {
    if (image) {
      // Object.assign(this, image);

      this.origin = new Point(image.origin.x, image.origin.y);
      this.extent = new Extent(image.extent.width, image.extent.height);

      this.imageFileName = image.imageFileName;
      this.imageFileID = image.imageFileID;
      this.keepProportional = image.keepProportional;
      this.flipHorizontal = image.flipHorizontal;
      this.flipVertical = image.flipVertical;
      this.colorMode = image.colorMode;
      this.colorThreshold = image.colorThreshold;
      this.invertColors = image.invertColors;
      // this.contrast = image.contrast;
      // this.brightness = image.brightness;
      // this.saturation = image.saturation;
      // this.hue = image.hue;
      // this.effect = image.effect;
      // this.opacity = image.opacity;
      // this.processMode = image.processMode;
    }
  }

  isNaturallyVisible() {
    return (!!this.imageFileID) || super.isNaturallyVisible();
  }
}
