
export class Tier {
  flat_amount?: number;
  unit_amount?: number;
  up_to?: number;

  constructor(t: Tier) {
    if (t) {
      this.flat_amount = t.flat_amount;
      this.unit_amount = t.unit_amount;
      this.up_to = t.up_to;
    }
  }
}


export class ProductPricingPlan {
  id: string;
  object: string;
  active: boolean;
  aggregate_usage: string;
  amount: number;
  billing_scheme: string;
  created: Date;
  currency: string;
  interval: string;
  interval_count: number;
  livemode: boolean;
  metadata: any;
  nickname: string;
  tiers: Array<Tier>;
  tiers_mode: string;
  trial_period_days?: number;
  usage_type: string;
  userSelectable: boolean;

  constructor(ppp: ProductPricingPlan) {
    if (ppp) {
      this.id = ppp.id;
      this.object = ppp.id;
      this.active = ppp.active;
      this.aggregate_usage = ppp.aggregate_usage;
      this.amount = ppp.amount;
      this.billing_scheme = ppp.billing_scheme;
      this.created = ppp.created;
      this.currency = ppp.currency;
      this.interval = ppp.interval;
      this.interval_count = ppp.interval_count;
      this.livemode = ppp.livemode;
      this.metadata = ppp.metadata;
      this.nickname = ppp.nickname;
      if (ppp.tiers) {
        this.tiers = ppp.tiers.map(t => new Tier(t));
      }
      this.tiers_mode = ppp.tiers_mode;
      this.trial_period_days = ppp.trial_period_days;
      this.usage_type = ppp.usage_type;
      this.userSelectable = ppp.userSelectable;
    }
  }
}

