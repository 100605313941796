import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { interval, Subscription } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import * as v from '../../../version';
import { ApplicationService } from '../../services/application.service';
import { UpdateNotificationDialogComponent } from '../update-notification-dialog/update-notification-dialog.component';
import { environment } from './../../../environments/environment';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';


@Component({
    selector: 'app-update-notification',
    templateUrl: './update-notification.component.html',
    standalone: true,
    imports: [MatButtonModule, MatIconModule]
})
export class UpdateNotificationComponent implements OnInit, OnDestroy {

  @Input()
  pollingInterval = 300000;

  private updateSub: Subscription;
  private shakeSub: Subscription;

  // Only show if in production
  public isProduction: boolean;

  withShake = false;

  showReload: boolean;

  constructor(
    private dialog: MatDialog,
    private applicationService: ApplicationService) {

    this.isProduction = environment.production;
  }

  ngOnInit() {
    // if (!this.isProduction) return;

    this.updateSub =
      interval(this.pollingInterval)
        .pipe(
          startWith(0),
          switchMap(() => {
            return this.applicationService.getLatestVersion();
          })
        )
        .subscribe(res => this.checkVersion(res));
  }

  ngOnDestroy(): void {
    if (this.updateSub) {
      this.updateSub.unsubscribe();
      this.updateSub = null;
    }
  }

  reload(): void {
    this.dialog.open(UpdateNotificationDialogComponent, {});
  }

  private checkVersion(latest: string): void {

    this.showReload = (latest !== v.version.version);

    if (this.showReload) {
      this.startShaking();
    } else {
      this.stopShaking();
    }
  }

  private startShaking() {
    if (!this.shakeSub) {
      this.shakeSub =
        interval(1500)
          .subscribe(x => {
            this.withShake = !this.withShake;
          });
    }
  }

  private stopShaking() {
    if (this.shakeSub) {
      this.shakeSub.remove(this.shakeSub);
      this.shakeSub.unsubscribe();
      this.shakeSub = null;
    }
  }

}
