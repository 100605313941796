export enum ExternalFileTypeEnum {
  image,
  font,
  tempFile,
  imageThumb,
  pdf,
  xls,
  word,
  zip,
  csv,
  data = 997,
  user = 998,
  any = 999,
  unknown = 9999
}

export class ExternalFile {

  static defaultImageId = '00000000-0000-0000-0000-000000000001';
  static defaultImageThumbId = '00000000-0000-0000-0000-000000000002';

  // needs to match the types in backend FileSearchRequest.UserFileTypes
  static UserFileTypes: ExternalFileTypeEnum[] = [
    ExternalFileTypeEnum.image,
    ExternalFileTypeEnum.word,
    ExternalFileTypeEnum.pdf,
    ExternalFileTypeEnum.xls,
    ExternalFileTypeEnum.csv
  ];

  static DataFileTypes : ExternalFileTypeEnum[] = [
    ExternalFileTypeEnum.csv,
    ExternalFileTypeEnum.xls
  ];

  static pdfMimeTypes = [
    'application/pdf'
  ];

  static wordMimeTypes = [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ];

  static xlsMimeTypes = [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ];

  static imageMimeTypes = [
    'image/png',
    'image/jpeg',
    'image/jpg',
    'image/gif',
    'image/bmp',
  ];

  static zipMimeTypes = [
    'application/zip'
  ];

  static csvMimeTypes = [
    'text/csv'
  ];

  static dataMimeTypes = [
    ...this.xlsMimeTypes,
    ...this.csvMimeTypes
  ];

  // needs to match the types in backend FileSearchRequest.UserFileTypes
  static userMimeTypes = [
    ...this.pdfMimeTypes,
    ...this.wordMimeTypes,
    ...this.xlsMimeTypes,
    ...this.imageMimeTypes,
    ...this.csvMimeTypes
  ];

  static ExternalFileMimeTypes: Map<ExternalFileTypeEnum, string[]> = new Map([
    [ExternalFileTypeEnum.pdf, this.pdfMimeTypes],
    [ExternalFileTypeEnum.word, this.wordMimeTypes],
    [ExternalFileTypeEnum.xls, this.xlsMimeTypes],
    [ExternalFileTypeEnum.image, this.imageMimeTypes],
    [ExternalFileTypeEnum.zip, this.zipMimeTypes],
    [ExternalFileTypeEnum.csv, this.csvMimeTypes],
    [ExternalFileTypeEnum.user, this.userMimeTypes],
    [ExternalFileTypeEnum.data, this.dataMimeTypes],
  ]);

  static GetTypeFromMimeType(mimeType: string): ExternalFileTypeEnum {

    let found: ExternalFileTypeEnum = ExternalFileTypeEnum.unknown;

    for (let key of this.ExternalFileMimeTypes.keys()) {
      let xt = this.ExternalFileMimeTypes.get(key);
      for (let mt of xt) {
        if (mt === mimeType) {
          found = key;
        }
      }
      ;
      if (found != ExternalFileTypeEnum.unknown)
        break;
    }

    return found;
  }

  public id: string;
  public organisationID: string;
  public ownerID: string;
  public authorID: string;
  public fileName: string;
  public name: string;
  public note: string;
  public externalFileType: ExternalFileTypeEnum;
  public subType: string;
  public size: number;
  public thumbID: string;
  public mimeType: string;
  public created: Date;
  public lastUpdated?: Date;
  public lastUsed?: Date;

  public fileType: string;

  constructor(exFile?: ExternalFile) {
    if (exFile) {
      this.id = exFile.id;
      this.organisationID = exFile.organisationID;
      this.ownerID = exFile.ownerID;
      this.authorID = exFile.authorID;
      this.fileName = exFile.fileName;
      this.note = exFile.note;
      this.externalFileType = exFile.externalFileType;
      this.subType = exFile.subType;
      this.size = exFile.size;
      this.thumbID = exFile.thumbID;
      this.mimeType = exFile.mimeType;
      this.created = exFile.created;
      this.lastUpdated = exFile.lastUpdated;
      this.lastUsed = exFile.lastUsed;

      this.fileType = this.getFileType();
    }
  }

  private getFileType(): string {
    switch (this.externalFileType) {
      case ExternalFileTypeEnum.image:
        return 'Image';
      case ExternalFileTypeEnum.font:
        return 'Font';
      case ExternalFileTypeEnum.tempFile:
        return 'Temporary';
      case ExternalFileTypeEnum.pdf:
        return 'PDF';
      case ExternalFileTypeEnum.imageThumb:
        return 'Thumbnail Image';
      case ExternalFileTypeEnum.xls:
        return 'Excel Document';
      case ExternalFileTypeEnum.word:
        return 'Word Document';
      case ExternalFileTypeEnum.csv:
        return 'CSV Document';
      case ExternalFileTypeEnum.zip:
        return 'ZIP Archive';
    }
    return 'Unknown';
  }
}

export class ExternalFileRequest {
  public externalFileType: ExternalFileTypeEnum;
  public subType: string;
  public thumbnail: boolean;
}

export class ExternalFileResponse {
  public id: string;
  public fileName: string;
  public success: boolean;
  public error: string;

  constructor(f: ExternalFileResponse = null) {
    if (f) {
      Object.assign(this, f);
    }
  }
}
