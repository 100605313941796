import { IDataViewModel, ITableQueryPredicate } from '../../data/services/query';
import { DataTypeEnum } from './DataType';
import { IColumn } from './Table';

export class QueryColumnFilter implements IColumn {

  // IColumn
  name: string;
  id?: string;
  dataType: DataTypeEnum;
  format: string;
  isChild: boolean;

  index: number;
  filter?: string;
  sortOrder: number;
  visible: boolean;
  tableColumnID: string;

  constructor(qcf?: QueryColumnFilter) {
    if (qcf) {
      Object.assign(this, qcf);
    }
  }
}

export class Query {
  id: string;
  name: string;
  tableID: string;
  columns: Array<QueryColumnFilter>;
  isSystem: boolean;
  parameters: { [key: string]: string };
  allowOperator: boolean;
  joinChildField: string;
  joinMustMatch: boolean;
  joinName: string;
  joinParentField: string;
  joinToQueryID: string;

  allowUpdate = false;

  get isDefault(): boolean {
    return this.id === this.tableID;
  }


  constructor(query?: Query) {
    if (query) {
      Object.assign(this, query);

      this.columns = new Array<QueryColumnFilter>();
      query.columns
        .sort((c1, c2) => (c1.index + (c1.isChild ? c1.index + query.columns.length : 0)) - (c2.index + (c2.isChild ? c2.index + query.columns.length : 0)))
        .forEach(c => this.columns.push(new QueryColumnFilter(c)));

      this.addSystemColumns(this.columns);
    }
  }

  private addSystemColumns(columns: QueryColumnFilter[]): void {
    if (columns.findIndex(qcf => qcf.name === '__created') < 0) {
      columns.push(new QueryColumnFilter({
        name: '__created', index: 0, filter: '', sortOrder: 0, visible: true,
        dataType: DataTypeEnum.DateTime, tableColumnID: null, format: null, isChild: false
      }));
    }
    if (columns.findIndex(qcf => qcf.name === '__updatedbylogin') < 0) {
      columns.push(new QueryColumnFilter({
        name: '__updatedbylogin', index: 0, filter: '', sortOrder: 0, visible: true,
        dataType: DataTypeEnum.String, tableColumnID: null, format: null, isChild: false
      }));
    }

    // __updatedById  ??
  }

  /**
   * Converts a query model object to one which provides necessary properties
   * to present on the UI in order to manipulate and execute it.
   */
  get params(): IDataViewModel<Query> {
    const predicates = (this.columns || []).filter(
      column => !!column.filter
    ).map(column => {
      const isImmutable = !column.filter.startsWith('?');
      const value = isImmutable ? column.filter : ((column.filter + ' ').slice(1).trim());
      return <ITableQueryPredicate>{
        key: column.name,
        value: { current: value, new: value },
        isImmutable
      };
    });

    return {
      name: this.name,
      id: this.id,
      isEditing: false,
      isRunning: false,
      active: false,
      predicates,
      isSystem: this.isSystem,
      isDefault: this.id === this.tableID,
      isValid: predicates.every(p => p.isImmutable),
      allowUpdate: this.allowUpdate,
      allowOperator: this.allowOperator,
      dataSource: this
    };
  }

  /**
   * Takes values from a view model and assigns them to the respective properties
   * on the Query model object.
   */
  set params(params: IDataViewModel<Query>) {
    this.name = params.name;
    this.allowOperator = params.allowOperator;

    this.joinName = params.dataSource.joinName;
    this.joinToQueryID = params.dataSource.joinToQueryID;
    this.joinParentField = params.dataSource.joinParentField;
    this.joinChildField = params.dataSource.joinChildField;
    this.joinMustMatch = params.dataSource.joinMustMatch;

    // Define the list of columns from the view model _as well_ as those from
    // the existing Query model object.
    this.columns = params.predicates.reduce(
      (columns, predicate, index) => {
        // Within the columns, find the filter with the same name as the predicate value
        // from the view model.
        const existing = columns.find(q => q.name === predicate.key);

        // If it was a mutable parameter (i.e. defined at runtime), then replace the content
        // of the filter with a question mark.
        const predicateValue = predicate.value.current || predicate.value.new || '';
        const value = predicate.isImmutable ? predicateValue : '?';

        // If the column with the correct key already exists in the Query object, just overwrite
        // its existing `.filter` value, else add a new column.
        if (existing) {
          existing.filter = value;
        } else {
          // TODO: This will fail ! Maybe it's never executed??
          columns.push(
            new QueryColumnFilter({
              name: predicate.key,
              filter: value,
              index,
              sortOrder: 0,
              visible: true,
              dataType: existing.dataType || DataTypeEnum.String,  // existing will be null/undefined here so ... wtf?
              tableColumnID: existing.tableColumnID,
              format: existing.format,
              isChild: existing.isChild
            }));
        }
        return columns;
      },
      // This means that the columns defined will be in addition to those which already existed.
      this.columns || []
    ).map(column => {
      // Every column needs to have its `.filter` property set, or else the API will 501.
      // If it wasn't defined in the view model, just set it to an empty string.
      if (!params.predicates.map(p => p.key).includes(column.name)) {
        column.filter = '';
      }
      return column;
    });

    // Convert from a list of predicate values to just a key-value map:
    // [ { key: 'a', value: { current: 'b' } }... ] => { 'a': 'b' }
    this.parameters = params.predicates.reduce(
      (dict, pred) => Object.assign(dict, { [pred.key]: pred.value.current || pred.value.new }), {}
    );
  }
}
