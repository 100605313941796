import { ShapeFactory } from './ShapeFactory';
import { ShapeBase, IShape } from './Shapes/Shape';
import { ShapeGroup } from './Shapes/ShapeGroup';
import { Point, Extent } from './Dimensions';

export class Layer {

  public zOrder: number;
  public shapes: ShapeBase[];

  constructor(layer?: Layer) {
    if (layer != null) {
      this.shapes = new Array<ShapeBase>();
      layer.shapes.forEach(shape => {
        if (shape) {
          const newShape = ShapeFactory.cloneShape(shape);
          if (newShape) {
            this.shapes.push(newShape);
          }
        }
      });
      this.zOrder = layer.zOrder;
    } else {
      this.shapes = new Array<ShapeBase>();
      this.zOrder = 0;
    }
  }

  /// Add some shapes to a new group and adjst co-ords
  groupify(shapes: IShape[]): ShapeGroup {

    const shapeGroup = new ShapeGroup();

    // figure out the co-ordinates and size of the group using the contained shapes
    let x1 = 9999999999;
    let y1 = 9999999999;
    let x2 = 0;
    let y2 = 0;
    // get top left and bottom right
    for (const s of shapes) {
      x1 = Math.min(x1, s.origin.x);
      y1 = Math.min(y1, s.origin.y);

      if (s.scaleShapeWidthMM !== undefined && s.scaleShapeWidthMM !== 0) {
        x2 = Math.max(x2, s.origin.x + s.scaleShapeWidthMM);
      } else {
        x2 = Math.max(x2, s.origin.x + s.extent.width);
      }

      if (s.scaleShapeHeightMM !== undefined && s.scaleShapeHeightMM !== 0) {
        y2 = Math.max(y2, s.origin.y + s.scaleShapeHeightMM);
      } else {
        y2 = Math.max(y2, s.origin.y + s.extent.height);
      }
    }

    // set the group position and size
    shapeGroup.origin = new Point(x1, y1);
    shapeGroup.extent = new Extent(x2 - x1, y2 - y1);


    // adjust co-ordinates of contained shapes to be relative to the group
    for (const s of shapes) {

      s.origin.x -= x1;
      s.origin.y -= y1;

      shapeGroup.shapes.push(s);

      // remove from the layer
      const i = this.shapes.findIndex(ss => ss === s);
      if (i >= 0) {
        this.shapes.splice(i, 1);
      }

    }

    this.shapes.push(shapeGroup);

    return shapeGroup;

  }

  ungroupify(shapeGroup: ShapeGroup): IShape[] {

    const shapes = new Array<IShape>();

    for (const shape of shapeGroup.shapes) {
      shape.origin = new Point(shape.origin.x + shapeGroup.origin.x, shape.origin.y + shapeGroup.origin.y);
      shapes.push(shape);
      this.shapes.push(shape);
    }

    const i = this.shapes.findIndex(s => s === shapeGroup);
    if (i >= 0) {
      this.shapes.splice(i, 1);
    }

    return shapes;
  }

}
