import { Query } from '../server/Query';
import { Table } from '../server/Table';

export class DataSourceItem {
  label: string;

  constructor(public table: Table, public query: Query) {
    if (table == null) {
      this.label = query.name;
    } else {
      if (query.id === table.defaultQuery) {
        this.label = table.name;
      } else {
        this.label = ` ${table.name} / ${query.name}`;
      }
    }
  }
}
