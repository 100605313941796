import { FormattedExpression } from '../FormattedExpression';
import { PropertyExpression } from '../PropertyExpression';
import { Shape } from './Shape';

// KEEP THIS IN SYNC WITH THE BACKEND
export enum PRICE_STYLE {
  PRICESTYLE_1,  // 1,"Plain with Signs", "~F\"%s\"%c%s%c%s~F~Z\"30\"%s~Z", 1 },
  PRICESTYLE_2,  // 1,"Plain no Signs", "~F\"%s\"%s%c%s~F~Z\"30\"%s~Z", 0},
  PRICESTYLE_3,  // 1,"Small Sign", "~F\"%s\"~Z\"50\"~1%c~3~Z%s%c%s~F~Z\"30\"%s~Z", 1 },
  PRICESTYLE_4,  // 1,"Standard", "~F\"%s\"~Z\"40\"~1%c~3~Z%s~3%c~Z\"50\"~1~<%s~Z~3~F~Z\"30\"~<%s~Z", 1},
  PRICESTYLE_5,  // 1,"No Dollar Sign", "~F\"%s\"~Z\"40\"~Z%s~3%c~Z\"50\"~1~<%s~Z~3~F~Z\"30\"~<%s~Z", 9},
  PRICESTYLE_6,  // 1,"No Dollar Sign, Hi Decimal", "~F\"%s\"~Z\"40\"~Z%s~Z\"50\"~1%c%s~Z~3~F~Z\"30\"~<%s~Z", 9},
  PRICESTYLE_7,  // 1,"No Dollar Sign, No Decimal", "~F\"%s\"~Z\"40\"~Z%s~Z\"50\"~1%s~Z~3~F~Z\"30\"~<%s~Z", 10},
  PRICESTYLE_8,  // 1,"Underline Decimal", "~F\"%s\"~Z\"40\"~1%c~3~Z%s~3%c~1~Z\"50\"~<~U%s~U~Z~3~F~Z\"30\"~<%s~Z",1 },
  PRICESTYLE_9,  // 1,"Big Dollar", "~F\"%s\"~Z\"90\"~1%c~3~Z%s~3%c~Z\"50\"~<~1%s~Z~3~F~Z\"30\"~<%s~Z",1 },
  PRICESTYLE_10, // 1,"Hi Decimal", "~F\"%s\"~Z\"40\"~1%c~3~Z%s~Z\"50\"~1%c%s~Z~3~F~Z\"30\"~<%s~Z",1 },
  PRICESTYLE_11, // 1,"Hi Decimal Smaller Cents", "~F\"%s\"~Z\"35\"~1%c~3~Z%s~Z\"35\"~1%c%s~Z~3~F~Z\"30\"~<%s~Z",1 },
  PRICESTYLE_12, // 1,"Hi Decimal Larger Cents", "~F\"%s\"~Z\"40\"~1%c~3~Z%s~Z\"65\"~1%c%s~Z~3~F~Z\"20\"~<%s~Z",1 },
  PRICESTYLE_13, // 1,"Hi Decimal Underline", "~F\"%s\"~Z\"40\"~1%c~3~Z%s~Z\"50\"~1~U%c%s~U~Z~3~F~Z\"30\"~<%s~Z", 1},
  PRICESTYLE_14, // 1,"No Decimal", "~F\"%s\"~Z\"40\"~1%c~3~Z%s~Z\"50\"~1~U%s~U~Z~3~F~Z\"30\"~<%s~Z", 2},
  PRICESTYLE_15, // 1,"No Decimal & Underline", "~F\"%s\"~Z\"40\"~1%c~3~Z%s~Z\"50\"~1%s~Z~3~F~Z\"30\"~<%s~Z",2 },
  PRICESTYLE_16, // 1,"Bottom Align Decimals", "~F\"%s\"~Z\"40\"~1%c~3~Z%s~Z\"60\"~3%c%s~Z~3~F~Z\"30\"%s~Z",1 },
  PRICESTYLE_17, // 1,"No Cents - Round Dollars", "~F\"%s\"~Z\"40\"~1%c~3~Z%s~Z\"50\"~Z~F~Z\"30\"%s~Z",3 },
  PRICESTYLE_18, // 1,"Percentage","~F\"%s\"%s~Z\"50\"~1%%~Z~F~3~Z\"60\"%s~Z",4},
  PRICESTYLE_19, // 1,"Fraction","~F\"%s\"%s~B~Z\"40\"~1%i~Z~B~Z\"80\"/~Z~B~Z\"40\"~3%i~Z~B~F~Z\"40\"~1%s~Z",5},
  PRICESTYLE_20, // 1,"Big Number & Sub Text","~F\"%s\" %s \r\n~Z\"50\" ~Z~Z\"30\"~1%s~Z~Z\"50\" ~Z~3",8},// font, number, new line text
  PRICESTYLE_21, // 1,"No Dollar Sign, Big  Decimal and Cents", "~F\"%s\"~Z\"40\"~Z%s~3%c~Z\"80\"~1%s~Z~3~F~Z\"20\"~<%s  ~Z", 9},
  PRICESTYLE_22, // 1,"Big Number & Sub Text Same Line","~F\"%s\"    %s ~Z\"99\"%s    ~Z~Z\"50\" ~Z~3",8},// font, number, new line text
  PRICESTYLE_23, // 1,"Plain Text","~F\"%s\"      %s     ",12},// font, number, new line text
  PRICESTYLE_24, // 2,"Small Cents","~F\"%s\"%s~Z\"30\"~1%c~3~<~F%s~Z", 6 },		// font, cents, cent sign...
  PRICESTYLE_25, // 2,"Large Cents","~F\"%s\"%s~Z\"60\"~1%c~Z~Z\"30\"~3~F~<%s~Z", 6 },		// font, cents, cent sign...
  PRICESTYLE_26, // 2,"Zero Dollars","~F\"%s\"%c0.%s~Z\"30\"~1%c~3~F~<%s~Z", 7 },		// font, dollar sign cents, cent sign...
  PRICESTYLE_27, // 2,"Large C Cents","~F\"%s\"  %s~Z\"60\"~2%c   ~Z~Z\"20\"~3~F~<%s  ~Z", 11 },		// font, dollar sign cents, cent sign...
  PRICESTYLE_28, // 2,"Plain Text","~F\"%s\"      %s     ",12},// font, number, new line text
  PRICESTYLE_29, // 1,"No Dollar Sign, Hi Cents", "~F\"%s\"~Z\"40\"~Z%s~3~Z\"60\"%c~Z~Z\"50\"~1~<%s~Z~3~F~Z\"30\"~<%s~Z", 9},
  PRICESTYLE_30, // 1,"Foodstuffs SI Dollars", "~F\"%s\"~Z\"40\"~Z%s~Z\"50\"~1%s~Z~3~F~Z\"30\"~<%s~Z", 10},
  PRICESTYLE_31, // 1,"Foodstuffs SI Prefix & Dollars", "~F\"%s\"%s~Z\"40\"~Z%s~Z\"50\"~1%s~Z~3~F~Z\"30\"~<%s~Z", 30},
  PRICESTYLE_32, // 2,"Foodstuffs SI Cents","~F\"%s\"%s~Z\"50\"~1%c~3~<~F%s~Z", 13 },		// font, cents, cent sign...
  PRICESTYLE_33, // 2,"Foodstuffs SI Prefix & Cents","~F\"%s\"%s%s~Z\"50\"~1%c~3~<~F%s~Z", 31 },		// font, cents, cent sign...
  PRICESTYLE_34, // 1,"Foodstuffs SI Unit Pricing"," ~F\"%s\"%s%c%s~F %s ", 0},// font, number, new line text
  PRICESTYLE_35, // 2,"Foodstuffs SI Unit Pricing"," ~F\"%s\"%s%c%s~F %s ", 0},// font, number, new line text
  PRICESTYLE_36, // 1,"N For Price","~F\"%s\"~Z\"40\"~1%s~Z~Z\"80\"~Z~Z\"40\"~<~3%s~Z~Z\"40\"~4$~1~Z%s~4~Z\"40\"%s~Z~Z\"20\"~Z",14},// font, number, new line text
  PRICESTYLE_37, // 1,"Small Cents and ea","~F\"%s\"~Z\"40\"~4$~Z~1%s~4~Z\"40\"%s~Z~Z\"20\"ea~Z",10},// font, number, new line text
  PRICESTYLE_38, // 1,"N For Price No Dollar","~F\"%s\"~Z\"40\"~1%s~Z~Z\"80\"~Z~Z\"40\"~<~3%s~Z~Z\"40\"~4~1~Z%s~4~Z\"40\"%s~Z~Z\"20\"~Z",14},// font, number, new line text
  PRICESTYLE_39, // 1,"Small Cents and ea No Dollars","~F\"%s\"~Z\"40\"~4~Z~1%s~4~Z\"40\"%s~Z~Z\"20\"ea~Z",10},// font, number, new line text
  PRICESTYLE_40  // 1,"N For","~F\"%s\"%s for",15},// font, number, new line text

}


export class PriceShape extends Shape<PRICE_STYLE> {

  static ShapeType = 'price';

  currencySymbol: string;
  amount: number;
  units: string;

  constructor(price?: PriceShape) {
    super(price);
    this.shapeName = 'Price';

    this.style = PRICE_STYLE.PRICESTYLE_4;
    this.currencySymbol = '$';
    this.amount = 1.99;
    this.units = 'each';

    if (price) {
      this.style = price.style;
      this.currencySymbol = price.currencySymbol;
      this.amount = price.amount;
      this.units = price.units;
    }
  }

  updateWithVar(variable: FormattedExpression): void {

    let pex = this.expressions.find(fex => fex.propertyName === 'amount');

    if (!pex) {
      pex = new PropertyExpression();
      pex.propertyName = 'amount';
      this.expressions.push(pex);
    }
    pex.variableName = variable.name;
  };
}


