import { Shape } from './Shape';

export enum LIST_STYLE {
    NO_BULLET = 0,
    BULLETED = 1,
    NUMBERED = 2,
    LETTERED = 3,
    TICKED = 4,
    HORIZONTAL = 5
}

export class ListShape extends Shape<LIST_STYLE> {
    static ShapeType = 'list';

    sorted: boolean;
    items: Array<string>;
    bulletChar: string;

    constructor(list?: ListShape) {
        super(list);
        this.shapeName = 'List';
        this.style = LIST_STYLE.NO_BULLET;
        this.sorted = true;
        this.items = new Array<string>();
        this.keepProportional = false;

        if (list) {
            this.style = <LIST_STYLE>list.style;
            this.sorted = list.sorted;
            this.bulletChar = list.bulletChar;
            if (list.items) {
                list.items.forEach(i => this.items.push(i));
            }
        }
    }

    isNaturallyVisible() {
        return (this.items !== null && this.items.length > 0) || super.isNaturallyVisible();
    }
}


