<div class="app">
  <header>
    <div fxLayout="row" fxLayoutAlign="space-between stretch" class="header">
      <div class="brand" fxLayout="row" fxLayoutAlign="start center">
        <button mat-button [matMenuTriggerFor]="mainMenu" fxHide.gt-sm="true">
          <mat-icon style="color: white;margin-right:1em;width:30px">menu</mat-icon>
        </button>

        <button mat-button routerLink="/" [disabled]="consoleOnly" fxHide.lt-md="true" class="logoButton">
          <img src="/assets/icons/barxuilogo.png" alt="barxui Logo">
        </button>
      </div>

      <mat-toolbar color="primary">

        @if (authService.isAuthenticated | async) {
          @if (!loading && !!organisation) {
            @if (organisation.isActivated) {
              <div fxShow="true" fxHide.lt-md="true">
                <button data-cy="home-label-button" mat-button class="btn" routerLink="/design"
                        require="DesignView" routerLinkActive="selected">
                  <mat-icon>receipt</mat-icon>
                  Labels
                </button>
                <button data-cy="home-dataManager-button" mat-button routerLink="/data"
                        require="DatamanView" routerLinkActive="selected">
                  <mat-icon>view_list</mat-icon>
                  Data Manager
                </button>
                <button data-cy="home-console-button" mat-button routerLink="/library"
                        require="LibraryView" routerLinkActive="selected">
                  <mat-icon>library_books</mat-icon>
                  Library
                </button>
                <button data-cy="home-console-button" mat-button routerLink="/console" require="ConsoleUse"
                        activeorg routerLinkActive="selected">
                  <mat-icon>dashboard</mat-icon>
                  Console
                </button>
              </div>
            } @else {
              <button data-cy="home-button" mat-button class="btn" routerLink="/">
                <mat-icon>start</mat-icon>
                Get Started!
              </button>
              <button mat-button data-cy="settings-orgdetails-button" routerLink="/settings/organisation">
                <mat-icon>business</mat-icon>
                Settings
              </button>
            }
          } @else {
            <simple-progress [diameter]="20" message="Starting Up"/>
          }
        } @else {
          <div class='featurebtns' fxHide.lt-md>
            <button data-cy="home-features-button" mat-raised-button color="primary"
                    (click)="goto('/#features')">
              Features
            </button>
            <button data-cy="home-businessuses-button" mat-raised-button color="primary"
                    (click)="goto('/#business')">
              Business Uses
            </button>
            <button data-cy="home-prices-button" mat-raised-button color="primary"
                    (click)="goto('/#pricing')">
              Pricing Plans
            </button>
          </div>
        }

        <span class="fill-space"></span>

        @if ((authService.isAuthenticated | async) === false) {
          <app-login-buttons [showRegister]="true" [showLogin]="true"></app-login-buttons>
        } @else {

          <app-invitecheck></app-invitecheck>
          <app-limit-alert></app-limit-alert>
          <app-update-notification></app-update-notification>
          @if (organisation && userRecord) {
            <app-plan-expiry [organisation]="organisation" [user]="userRecord"/>
          }

          @if (!consoleOnly) {
            <button data-cy="home-profile-button" mat-button [matMenuTriggerFor]="userMenu"
                    title="{{userRecord?.name}}, {{organisation?.name}}">
            <span fxLayout="row" fxLayoutAlign="start center">
              <mat-icon>person</mat-icon>
              <span fxShow="true" fxHide.lt-sm="true">{{ userRecord?.name }}</span>
              <mat-icon>arrow_drop_down</mat-icon>
            </span>
            </button>
          } @else {
            <button data-cy="home-signout-button" mat-button (click)="authService.logout()">
              <span fxLayout="row" fxLayoutAlign="start center">
                <mat-icon>logout</mat-icon>
                <span>Sign Out</span>
              </span>
            </button>
          }

          @if (organisation?.isActivated && !consoleOnly) {
            <button data-cy="home-settings-button" mat-button
                    [matMenuTriggerFor]="settingsMenu"
                    fxHide.lt-md="true">
              <span fxLayout="row" fxLayoutAlign="start center">
                <mat-icon>settings</mat-icon>
                <span>Settings</span>
                <mat-icon>arrow_drop_down</mat-icon>
              </span>
            </button>
          }

          @if (!consoleOnly) {
            <button data-cy="home-help-button" mat-button [matMenuTriggerFor]="helpMenu"
                    fxHide.lt-md="true">
            <span fxLayout="row" fxLayoutAlign="start center">
              <mat-icon>help</mat-icon>
              <span>Help</span>
              <mat-icon>arrow_drop_down</mat-icon>
            </span>
            </button>
          }
        }
      </mat-toolbar>

    </div>

    @if (busy) {
      <div fxLayout="row" fxLayoutGap="0.5em" fxLayoutAlign="start center" class="progress">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
    }
  </header>

  <main>
    <router-outlet></router-outlet>
  </main>

  <footer class="mainfooter">
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">

      <div>
        barxui (c) is a product of <a href="https://hexcom.com" target="_blank">HEXCOM Ltd</a>, based in
        beautiful New
        Zealand.
      </div>

      <div fxLayout="row" fxHide.lt-sm fxLayoutAlign="space-between center" fxLayoutGap="0.25em">
        <div><a href="https://www.barxui.com/support" target="_blank">Contact & Support</a> |</div>
        <div><a href="https://www.barxui.com/terms-of-service/" target="_blank">Terms of Use</a> |</div>
        <div><a href="https://www.barxui.com/privacy" target="_blank">Privacy</a></div>
      </div>

      <div fxLayout="column" fxHide.gt-xs fxLayoutAlign="start center" fxLayoutGap="0.1em">
        <div><a href="https://www.barxui.com/support" target="_blank">Contact & Support</a></div>
        <div><a href="https://www.barxui.com/terms-of-service/" target="_blank">Terms of Use</a></div>
        <div><a href="https://www.barxui.com/privacy" target="_blank">Privacy</a></div>
      </div>

    </div>
  </footer>
</div>

<mat-menu #mainMenu="matMenu">
  <button mat-menu-item class="btn" routerLink="/" fxLayout="row" fxLayoutAlign="start center">
    <mat-icon>home</mat-icon>
    <img src="/assets/barxuinewblack.png" style="height: 24px" alt="barxui Logo">
  </button>
  @if (organisation?.isActivated) {
    <button mat-menu-item class="btn" routerLink="/design" require="DesignView">
      <mat-icon>receipt</mat-icon>
      Labels
    </button>
    <button mat-menu-item routerLink="/data" require="DatamanView">
      <mat-icon>view_list</mat-icon>
      Data Manager
    </button>
    <button mat-menu-item routerLink="/library" require="DesignView">
      <mat-icon>library_books</mat-icon>
      Library
    </button>
    <button mat-menu-item routerLink="/console" require="ConsoleUse">
      <mat-icon>dashboard</mat-icon>
      Console
    </button>
  }
  <mat-divider></mat-divider>
  @if (organisation?.isActivated) {
    <button mat-menu-item data-cy="home-settings-menuitem" [matMenuTriggerFor]="settingsMenu">
      <mat-icon>settings</mat-icon>
      Settings
    </button>
    <mat-divider></mat-divider>
  }
  <button mat-menu-item data-cy="home-help-menuitem" [matMenuTriggerFor]="helpMenu">
    <mat-icon>help</mat-icon>
    Help
  </button>
</mat-menu>
<mat-menu #userMenu="matMenu" yPosition="below">
  <button data-cy="profile-button" mat-menu-item (click)="showUserProfile()">
    <mat-icon>person</mat-icon>
    Profile
  </button>
  <mat-divider></mat-divider>
  <button data-cy="home-signout-button" mat-menu-item (click)="authService.logout()">
    <mat-icon>logout</mat-icon>
    Sign Out
  </button>
</mat-menu>
<mat-menu #settingsMenu="matMenu" yPosition="below">
  <!--  (click)="showOrganisationSettings()"-->
  <button data-cy="settings-orgdetails-button" mat-menu-item require="OrganisationView"
          routerLink="/settings/organisation">
    <mat-icon>business</mat-icon>
    Organisation
  </button>
  <!--  (click)="showOrganisationFonts()"-->
  <button data-cy="settings-fonts-button" mat-menu-item require="OrganisationFonts" activeorg
          routerLink="/settings/fonts">
    <mat-icon>font_download</mat-icon>
    Fonts
  </button>
  <button data-cy="settings-agents-button" mat-menu-item require="ManageAgents" activeorg
          routerLink="/settings/agents">
    <mat-icon>radio</mat-icon>
    Agents
  </button>
  <!--  (click)="showPrinters()"-->
  <button data-cy="settings-printers-button" mat-menu-item require="ManagePrinters" activeorg
          routerLink="/settings/printers">
    <mat-icon>print</mat-icon>
    Printers
  </button>
  <!--  (click)="showSerials()"-->
  <button data-cy="settings-serials-button" mat-menu-item require="DesignUpdate" activeorg
          routerLink="/settings/serials">
    <mat-icon>123</mat-icon>
    Serial Numbers
  </button>
  <!--  (click)="showVariables()"-->
  <button data-cy="settings-vars-button" mat-menu-item require="DesignUpdate" activeorg
          routerLink="/settings/variables">
    <mat-icon>functions</mat-icon>
    Variables
  </button>
  <!--  (click)="showAddons()"-->
  <button data-cy="settings-addons-button" mat-menu-item require="ManageAddOns" activeorg
          routerLink="/settings/addons">
    <!-- hasFeature="AddOns" -->
    <mat-icon>extension</mat-icon>
    AddOns
  </button>
</mat-menu>
<mat-menu #helpMenu="matMenu" yPosition="below">
  <button mat-menu-item (click)="showHelpPage('index')">
    <mat-icon>help_outline</mat-icon>
    Index
  </button>
  <mat-divider style="width:100%"></mat-divider>
  <button mat-menu-item (click)="feedback()">
    <mat-icon>support_agent</mat-icon>
    Send Feedback
  </button>
  <button mat-menu-item (click)="about()">
    <mat-icon>info_outline</mat-icon>
    About barxui
  </button>
</mat-menu>

<error-handler></error-handler>
<app-notifications></app-notifications>
<app-help-panel></app-help-panel>
<app-event-hub></app-event-hub>
