import {Injectable} from '@angular/core';
import {Subject} from "rxjs";
import Guid from '../shared/guid';

export interface LoadingState {
  id: Guid;
  isLoading: boolean;
  message: string;
  owner: string;
}

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  change = new Subject<LoadingState>();

  private _stacks = new Map<string, Array<LoadingState>>();

  constructor() {
  }

  add(owner: string, message: string): LoadingState {
    const item = {id: Guid.create(), owner: owner, isLoading: true, message: message};
    let stack = this._stacks.get(owner);
    if (!stack) {
      stack = new Array<LoadingState>();
      this._stacks.set(owner, stack);
    }
    stack.push(item);
    this.change.next(item);
    return item;
  }

  remove(item: LoadingState): void {
    const stack = this._stacks.get(item.owner);
    const index = !!stack ? stack.findIndex(ls => ls.owner === item.owner &&  ls.id.equals(item.id)) : -1;

    if (index !== -1) {
      item = stack[index];
      stack.splice(index, 1);
    }

    if (stack.length === 0) {
      item.isLoading = false;
      this._stacks.delete(item.owner);
    } else {
      item = stack[stack.length - 1];
      item.isLoading = true;
    }

    this.change.next(item);
  }

  isBusy(owner: string): boolean {
    const stack = this._stacks.get(owner);
    return stack?.length > 0;
  }

  currentActivity(owner: string): string {
    const stack = this._stacks.get(owner);
    return stack?.length ? stack[stack.length - 1].message: null;
  }

  clear(owner: string) {
    const stack = this._stacks.get(owner);
    if(stack) {
      this._stacks.delete(owner);
    }
  }
}
