import { Pipe, PipeTransform } from '@angular/core';
import { Query } from '../../../models/server/Query';
import { IDataViewModel } from '../../services/query';

interface IQueryValidity {
  canRun: boolean;
  error?: {
    reason: 'valuesMissing' | 'isRunning';
    message?: string;
  }
}

@Pipe({
    name: 'isQueryValid',
    pure: false,
    standalone: true
})
export class IsQueryValidPipe implements PipeTransform {

  /**
   * An error raised when a query is already running and can therefore only be
   * refreshed (rather than run).
   */
  static ALREADY_RUNNING_ERROR: IQueryValidity = {
    canRun: false, error: { reason: 'isRunning' }
  };

  /**
   * Determines if a query can be run. If it has already been executed, then permit
   * re-running it only if something about it has changed - i.e. variable filter
   * values or its definition.
   * @param query The query to check for its ability to be run
   * @param active The query that has already been executed.
   */
  transform(query: IDataViewModel<Query>, active: Query): IQueryValidity {

    // Do initial checks to see whether the query is valid, regardless of whether it is
    // currently active.
    if (query.predicates.every(p => p.isImmutable)) {
      query.isValid = true;
    } else {
      query.isValid = true; // query.predicates.every(pred => !!pred.value.new); // Any or no value is valid
    }

    // If the query isn't yet defined, or it is running, or not valid, then it can't be run
    if (!query || query.isRunning) {
      return { canRun: false };
    } else if (!query.isValid) {
      // Determine what filter values that aren't set which need to be, and convert the list
      // of columns to a human-readable message
      const columns = query.predicates.filter(
        // If the column is mutable and doesn't have a value set, then it's stopping the query
        // from being able to run
        col => !col.isImmutable && !col.value.new
      ).map(requiredCol => requiredCol.key).join(', ');

      return {
        canRun: false,
        error: {
          reason: 'valuesMissing',
          message: `Provide filter values or enter an asterisk (*) for the following columns: ${columns}`
        }
      }
    }

    // If the active query isn't loaded, or points to a different query, or
    // there are a different number of filter parameters, then it can always
    // be run.
    if (!active || active.id !== query.id ||
        query.predicates.length !== active.params.predicates.length) {
      return { canRun: true };
    }

    // Otherwise, if there are variable filter predicates, then they must be not
    // the same as the previously-executed query in order to be run. If there aren't
    // any filters then this query has already been executed unchanged and so
    // shouldn't run again without any changes.
    if (query.predicates.some(p => !p.isImmutable)) {
      const differentParams = !query.predicates.map(
        pred => pred.value.new
      ).every(
        value => Object.values(active.parameters).includes(value)
      );
      return differentParams ? { canRun: true } : IsQueryValidPipe.ALREADY_RUNNING_ERROR;
    } else {
      return IsQueryValidPipe.ALREADY_RUNNING_ERROR;
    }

  }

}
