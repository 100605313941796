import { Limit } from './Limit';

export class Action {
    public actionCode: string;
    public limit: Limit;

    constructor(a: Action = null) {
        if (a) {
            this.actionCode = a.actionCode;
            this.limit = new Limit(a.limit);
        }
    }
}
