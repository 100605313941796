export class Boundary {
  public top: number = 0;
  public right: number = 0;
  public bottom: number = 0;
  public left: number = 0;

  constructor(boundary?: Boundary) {
    if (boundary) {
      this.top = boundary.top;
      this.left = boundary.left;
      this.bottom = boundary.bottom;
      this.right = boundary.right;
    }
  }

  public get width(): number {
    return this.right - this.left;
  }

  public get height(): number {
    return this.bottom - this.top;
  }

  toString() {
    return `l: ${this.left} t: ${this.top} r: ${this.right} b: ${this.bottom}`;
  }
}

export class Point {

  public x: number;
  public y: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

  offSet(offset: number): void {
    this.shift(offset, offset);
  }

  shift(x: number, y: number): void {
    this.x += x;
    this.y += y;
  }

  getAdjustedOrigin(containerOrigin: Point): Point {
    if (containerOrigin) {
      return new Point(this.x + containerOrigin.x, this.y + containerOrigin.y);
    } else {
      return this;
    }
  }

  snapToGrid(gridSize: number): Point {
    return new Point(
    Math.trunc(Math.round(this.x / gridSize)) * gridSize,
    Math.trunc(Math.round(this.y / gridSize)) * gridSize);
  }
}

export class Extent {
  public height: number;
  public width: number;

  constructor(width: number, height: number) {
    this.width = width;
    this.height = height;
  }

}

