import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogContent, MatDialogActions, MatDialogClose } from "@angular/material/dialog";
import { PrintResult, PrintResultStatus } from "../../models/server/PrintResult";
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { FlexModule } from '@angular/flex-layout/flex';
import { DialogTitleComponent } from '../../comps/dialog-title/dialog-title.component';

interface ErrorMessage {
  icon: string;
  message: string;
}

@Component({
    selector: 'app-print-result-dialog',
    templateUrl: './print-result-dialog.component.html',
    styleUrls: ['./print-result-dialog.component.scss'],
    standalone: true,
    imports: [DialogTitleComponent, MatDialogContent, FlexModule, MatIconModule, MatTableModule, MatDialogActions, MatButtonModule, MatDialogClose]
})
export class PrintResultDialogComponent implements OnInit {

  displayedColumns: string[] = ['icon', 'message']; //, 'count'
  messages: ErrorMessage[];
  errors: PrintResultStatus[];
  warnings: PrintResultStatus[];
  error: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: PrintResult
  ) { }

  ngOnInit(): void {

    this.errors = this.data.status.ExpressionError;
    this.warnings = this.data.status.ExpressionWarning;
    let fs = this.data.status.FileSizeExceeded;

    this.messages = new Array<ErrorMessage>();
    if (this.errors) {
      this.errors.forEach(em => this.messages.push({
        icon: 'error', message: em.message
      }));
    }

    if(fs) {
      this.messages.push({
        icon: 'error', message: fs[0].message
      });
    }

    if (this.warnings) {
      this.warnings.forEach(em => this.messages.push({
        icon: 'warning', message: em.message
      }));
    }

    if(this.messages.length == 0) {
      this.error = this.data.error;
    }
  }

}
