import { Shape } from './Shape';

// KEEP THESE IN SYNC WITH THE BACK END
export enum NUTRITIONAL_STYLE {
  LIST_2COLUMN,
  LIST_3COLUMN,
  STANDARD,
  STANDARD_3COLUMNRDI,
  STANDARD_3COLUMNRDIMIDDLE,
  US_OPTION1
}

export class NutritionPanelItemCode {
  public code: string;
  public description: string;
  public section: number;

  constructor(item: NutritionPanelItemCode) {
    if (item) {
      Object.assign(this, item);
    }

  }
}

export class NutritionPanelItem {
  public standardAmount: number;


  constructor(
    public id: number,
    public description: string,
    public amount: string,
    public expression: string,
    public units: string,
    public amount2: string,
    public expression2: string,
  ) { }
}

export class NutritionPanelShape extends Shape<NUTRITIONAL_STYLE> {

  static ShapeType = 'nutrition';

  public serves: number;
  public serveSize: number;
  public units: string;
  public decimalplaces: number;
  public inverseHeading: boolean;
  public showHeading: boolean;
  public showLines: boolean;
  public showColumns: boolean;

  public heading: string;
  public servesPerPackHeading: string;
  public serveSizeHeading: string;
  public perServeHeading: string;
  public perStandardSizeHeading: string;
  public standardServeSize: number;

  public items: Array<NutritionPanelItem>;

  constructor(nutritionpanel?: NutritionPanelShape) {
    super(nutritionpanel);
    this.shapeName = 'Nutrition';
    this.style = NUTRITIONAL_STYLE.STANDARD;
    this.inverseHeading = true;
    this.showHeading = true;
//    this.border.cornerRadius = 1;
 //   this.border.lineWidth = 0.1;
    if (nutritionpanel) {
      this.serves = nutritionpanel.serves;
      this.serveSize = nutritionpanel.serveSize;
      this.units = nutritionpanel.units;
      this.decimalplaces = nutritionpanel.decimalplaces;
      this.inverseHeading = nutritionpanel.inverseHeading;
      this.showHeading = nutritionpanel.showHeading;
      this.showLines = nutritionpanel.showLines;
      this.showColumns = nutritionpanel.showColumns;
      this.style = nutritionpanel.style;
      this.heading = nutritionpanel.heading;
      this.serveSizeHeading = nutritionpanel.serveSizeHeading;
      this.servesPerPackHeading = nutritionpanel.servesPerPackHeading;
      this.perServeHeading = nutritionpanel.perServeHeading;
      this.perStandardSizeHeading = nutritionpanel.perStandardSizeHeading;
      this.items = new Array<NutritionPanelItem>();
      if (nutritionpanel.items) {
        nutritionpanel.items.forEach(i => this.items.push(new NutritionPanelItem(i.id, i.description, i.amount, i.expression, i.units, i.amount2, i.expression2)));
      }

    }
  }

  isNaturallyVisible() {
    return (this.items !== null && this.items.length > 0) || super.isNaturallyVisible();
  }
}
