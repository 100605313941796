import { Feature } from './Feature';
import { environment } from '../../../environments/environment';

export class FeatureSet {
  public name: string;
  public description: string;
  public features: Array<Feature>;
  public productID: string;
  public plans: Array<FeatureSetPlan>;
  public userSelectable: boolean;
  public isFree: boolean;

  get isExpansionPack() : boolean {
    return this.productID === environment.expansionPackProductID;
  }

  constructor(fs: FeatureSet = null) {
    if (fs) {
      this.name = fs.name;
      this.description = fs.description;
      this.features = [...fs.features];
      this.plans = [...fs.plans];
      this.userSelectable = fs.userSelectable;
      this.isFree = fs.isFree;

      for (let f = 0; f < this.features.length; f++) {
        this.features[f] = new Feature(fs.features[f]);
      }
    }
  }

  hasPlan(id: string): boolean {
    return this.plans.findIndex(p => p.planID === id) >= 0;
  }
}

export class FeatureSetPlan {
  countryCode: string;
  planID: string;
  expansionIncluded: number;
  expansionMaximum: number;
  expansionPlanID: string;
  priceHint: string;
}
