import { ServiceBase } from './serviceBase';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ApplicationService extends ServiceBase {


    constructor(
        httpClient: HttpClient) {
        super(httpClient);
    }


    getLatestVersion(): Observable<string> {

        const headers = this.getHeaders();

        return this.httpClient.get<any>(environment.apiVersionUrl)
            .pipe(
                map(v => v.version)
            );

    }

}

