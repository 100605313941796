<style>
  section {
    margin: 20px;
  }

  .main {
    max-width: 300px,
  }

  .padded {
    padding: 15px;
  }
</style>

<section>
  <div class="main" fxLayout="row" fxLayoutAlign="center">
    <mat-card fxFlex="30%">
      <mat-card-header>
        <mat-card-title>
          {{title}}
        </mat-card-title>
        <mat-card-subtitle>Perhaps you should check the url you requested.</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content class="padded">
        <img src="{{image}}" mat-card-image />
      </mat-card-content>
    </mat-card>
  </div>
</section>
