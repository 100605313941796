export class UserInvite {
  organisationID: string;
  email: string;
  code: string;
  message: string;
  role: string;
  sent: Date;
  accepted?: Date;
  organisationName: string;

  constructor(ui?: UserInvite) {
    if (ui) {
      Object.assign(this, ui);
    }
  }
}
