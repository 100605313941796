import {Injectable} from '@angular/core';
import {Extent, Point} from '../models/server/Dimensions';

const MM_Per_INCH = 25.4;
const Fractions_MM = 0.01;
const Fraction_IN = 1 / 32;

export type Unit_Of_Measure = 'mm' | 'in' | 'pixel';

@Injectable({
  providedIn: 'root'
})
export class UnitConversionService {


  // normal dpi on the screen
  // can vary but thats just tough for those few users
  // if 120 or whatever then everything on the screen will similarly effected.
  private DPI = 96;

  private Pixles_Per_Point = 72 / this.DPI;


  PointsToPixels(size: number): number {
    return size / this.Pixles_Per_Point;
  }

  ToReal(pixels: number, measure: string): number {
    switch (measure) {
      case 'mm':
        return this.RoundReal((pixels / this.DPI) * MM_Per_INCH, measure);
      case 'in':
        return this.RoundReal(pixels / this.DPI, measure);
    }
    return 0;
  }

  RoundReal(realMeasurement: number, measure: Unit_Of_Measure): number {
    switch (measure) {
      case 'mm':
        // yes, this looks rediculous, but in JS if we just divide by Fractions_MM we get stupid values like 0.000000000004
        // inverting the fraction seems to work fine
        const multi = 1 / Fractions_MM;
        return Math.trunc(realMeasurement * multi) / multi;
      case 'in':
        let partInches = realMeasurement - Math.trunc(realMeasurement);
        partInches = partInches - (partInches % Fraction_IN);
        return Math.trunc(realMeasurement) + partInches;
    }
    return 0;
  }

  ToPixelExtent(extent: Extent, measure: Unit_Of_Measure): Extent {
    return new Extent(this.ToPixels(extent.width, measure), this.ToPixels(extent.height, measure));
  }

  ToPixelPoint(point: Point, measure: Unit_Of_Measure): Point {
    return new Point(this.ToPixels(point.x, measure), this.ToPixels(point.y, measure));
  }

  ToPixels(units: number, measure: Unit_Of_Measure): number {
    switch (measure) {
      case 'mm':
        return ((units / MM_Per_INCH) * this.DPI);
      case 'in':
        return (units * this.DPI);
    }
    return 0;
  }

  // pixels to measurement units...
  // ToMeasure(unit: number, measure: Unit_Of_Measure): number {
  //   switch (measure) {
  //     case 'mm':
  //       return (unit / this.DPI) * MM_Per_INCH;
  //     case 'in':
  //       return (unit * this.DPI);
  //   }
  // }


  ToMM(units: number, measure: Unit_Of_Measure): number {
    switch (measure) {
      case 'pixel':
        return (units / (this.DPI / MM_Per_INCH));
    }
    return 0;
  }


  // UnitFraction(measure: Unit_Of_Measure): number {
  //   switch (measure) {
  //     case 'mm':
  //       return Fractions_MM;
  //     case 'in':
  //       return Fraction_IN;
  //   }
  //   return 0;
  // }
}
