@if (!!caption) {
  @if (raised) {
    <button mat-raised-button (click)="handleClick($event)" [title]="title" [disabled]="disabled" [color]="color"
            class="tbButton" [matMenuTriggerFor]="menu"
            [style]="extraStyle">
      <span fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.25rem">
        @if (iconIsSvg) {
          <mat-icon [svgIcon]="icon"></mat-icon>
        } @else {
          <mat-icon>{{ icon }}</mat-icon>
        }
        <span fxShow="true" fxHide.lt-lg="true">{{ caption }}</span>
        @if (menu) {
          <mat-icon style="font-size:24px;cursor:pointer">arrow_drop_down</mat-icon>
        }
      </span>
    </button>

  } @else {

    <button mat-button (click)="handleClick($event)" [title]="title" [disabled]="disabled" [color]="color"
            class="tbButton" [matMenuTriggerFor]="menu"
            [style]="extraStyle">
        <span fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.25rem">
          @if (iconIsSvg) {
            <mat-icon [svgIcon]="icon"></mat-icon>
          } @else {
            <mat-icon>{{ icon }}</mat-icon>
          }
          <span fxShow="true" fxHide.lt-lg="true">{{ caption }}</span>
          @if (menu) {
            <mat-icon style="font-size:24px;cursor:pointer">arrow_drop_down</mat-icon>
          }
        </span>
    </button>
  }
} @else {

  <button (click)="handleClick($event)" [title]="title" [disabled]="disabled" class="tbIcon" [matMenuTriggerFor]="menu"
          [style]="extraStyle">
    <span fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.1rem">
      @if (iconIsSvg) {
        <mat-icon [svgIcon]="icon"></mat-icon>
      } @else {
        <mat-icon>{{ icon }}</mat-icon>
      }
      @if (menu) {
        <mat-icon style="font-size:24px;cursor:pointer">arrow_drop_down</mat-icon>
      }
    </span>
  </button>
}
