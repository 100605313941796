export class CountryCode {
  public code: string;
  public name: string;

  constructor(cc: CountryCode = null) {
    if (cc) {
      Object.assign(this, cc);
    }
  }
}
