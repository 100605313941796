
export type ColorPair = { name: string, color: string };

export class ColorList {
  static colors = new Map([

    ["Transparent", "rgba(0,0,0,0)"],
    ["Black", "rgb(0,0,0)"],
    ["Off Black", "rgb(30,30,30)"],
    ["Dark Gray", "rgb(51,51,51)"],
    ["Mid Gray", "rgb(75,75,75)"],
    ["Gray 60%", "rgb(100,100,100)"],
    ["Gray 40%", "rgb(125,125,125)"],
    ["Gray 30%", "rgb(150,150,150)"],
    ["Gray", "rgb(192,192,192)"],
    ["Light Gray", "rgb(210,210,210)"],
    ["Gray 10%", "rgb(235,235,235)"],
    ["White", "rgb(255,255,255)"],
    ["Dark Red", "rgb(128,0,0)"],
    ["Red", "rgb(255,0,0)"],
    ["Light Red", "rgb(255,128,128)"],
    ["Rose", "rgb(255,153,204)"],
    ["Dark Brown", "rgb(64,0,0)"],
    ["Brown", "rgb(128,0,64)"],
    ["Light Brown", "rgb(255,128,64)"],
    ["Tan", "rgb(255,204,153)"],
    ["Dark Magenta", "rgb(128,0,128)"],
    ["Magenta", "rgb(255,0,255)"],
    ["Light Magenta", "rgb(255,128,255)"],
    ["Light Lavender", "rgb(255,200,255)"],
    ["Dark Blue", "rgb(0,0,128)"],
    ["Blue", "rgb(0,0,255)"],
    ["Light Blue", "rgb(128,128,255)"],
    ["Sky Blue", "rgb(0,204,255)"],
    ["Dark Teal", "rgb(0,51,102)"],
    ["Teal", "rgb(0,128,128)"],
    ["Aqua", "rgb(51,204,204)"],
    ["Peppermint", "rgb(204,255,204)"],
    ["Olive Green", "rgb(51,51,0)"],
    ["Dark Green", "rgb(0,128,0)"],
    ["Green", "rgb(0,255,0)"],
    ["Light Green", "rgb(128,255,128)"],
    ["Dark Yellow", "rgb(128,128,0)"],
    ["Yellow", "rgb(255,255,0)"],
    ["Light Yellow", "rgb(255,255,128)"],
    ["Gold", "rgb(255,204,0)"],
    ["Orange", "rgb(255,128,0)"],
    ["Purple", "rgb(128,0,255)"],
    ["Creme", "rgb(255,244,205)"],
    ["Strawberry Milk", "rgb(249,211,202)"]
  ]
  );

  static contrastColors = new Map([
    ["rgba(0,0,0,0)", "rgb(75, 75, 75)"],
    ["rgb(0,0,0)", "rgb(255,255,255)"],
    ["rgb(30,30,30)", "rgb(255,255,255)"],
    ["rgb(51,51,51)", "rgb(255,255,255)"],
    ["rgb(75,75,75)", "rgb(255,255,255)"],
    ["rgb(100,100,100)", "rgb(0,0,0)"],
    ["rgb(125,125,125)", "rgb(0,0,0)"],
    ["rgb(150,150,150)", "rgb(0,0,0)"],
    ["rgb(192,192,192)", "rgb(255,255,255)"],
    ["rgb(210,210,210)", "rgb(51,51,51)"],
    ["rgb(235,235,235)", "rgb(51,51,51)"],
    ["rgb(255,255,255)", "rgb(51,51,51)"],
    ["rgb(128,0,0)", "rgb(255,255,255)"],
    ["rgb(255,0,0)", "rgb(255,255,255)"],
    ["rgb(255,128,128)", "rgb(255,255,255)"],
    ["rgb(255,153,204)", "rgb(255,255,255)"],
    ["rgb(64,0,0)", "rgb(255,255,255)"],
    ["rgb(128,0,64)", "rgb(255,255,255)"],
    ["rgb(255,128,64)", "rgb(0,0,0)"],
    ["rgb(255,204,153)", "rgb(0,0,0)"],
    ["rgb(128,0,128)", "rgb(255,255,255)"],
    ["rgb(255,0,255)", "rgb(75,75,75)"],
    ["rgb(255,128,255)", "rgb(75,75,75)"],
    ["rgb(255,200,255)", "rgb(0,0,0)"],
    ["rgb(0,0,128)", "rgb(255,255,255)"],
    ["rgb(0,0,255)", "rgb(255,255,255)"],
    ["rgb(128,128,255)", "rgb(75,75,75)"],
    ["rgb(0,204,255)", "rgb(0,0,0)"],
    ["rgb(0,51,102)", "rgb(255,255,255)"],
    ["rgb(0,128,128)", "rgb(255,255,255)"],
    ["rgb(51,204,204)", "rgb(0,0,0)"],
    ["rgb(204,255,204)", "rgb(0,0,0)"],
    ["rgb(51,51,0)", "rgb(75,75,75)"],
    ["rgb(0,128,0)", "rgb(255,255,255)"],
    ["rgb(0,255,0)", "rgb(255,255,255)"],
    ["rgb(128,255,128)", "rgb(0,0,0)"],
    ["rgb(128,128,0)", "rgb(0,0,0)"],
    ["rgb(255,255,0)", "rgb(0,0,0)"],
    ["rgb(255,255,128)", "rgb(0,0,0)"],
    ["rgb(255,204,0)", "rgb(0,0,0)"],
    ["rgb(255,128,0)", "rgb(255,255,255)"],
    ["rgb(128,0,255)", "rgb(255,255,255)"],
    ["rgb(255,244,205)", "rgb(0,0,0)"],
    ["rgb(249,211,202)", "rgb(0,0,0)"]
  ]
  );

  static colorArray = ColorList.convertToArray();

  static rgbStringToHex(richText) {
    return richText.replace(/rgb\((.+?)\)/ig, (_, rgb) => {
      return '#' + rgb.split(',')
        .map(str => parseInt(str, 10).toString(16).padStart(2, '0'))
        .join('')
    });
  }

  static invertColor(hex: string, bw: boolean) {
    if (hex.indexOf('#') === 0) {
      hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      throw new Error('Invalid HEX color.');
    }
    var r = parseInt(hex.slice(0, 2), 16),
      g = parseInt(hex.slice(2, 4), 16),
      b = parseInt(hex.slice(4, 6), 16);
    if (bw) {
      // https://stackoverflow.com/a/3943023/112731
      return (r * 0.299 + g * 0.587 + b * 0.114) > 186
        ? '#000000'
        : '#FFFFFF';
    }
    // invert color components
    let rs = (255 - r).toString(16);
    let gs = (255 - g).toString(16);
    let bs = (255 - b).toString(16);
    // pad each with zeros and return
    return "#" + ColorList.padZero(rs) + ColorList.padZero(gs) + ColorList.padZero(bs);
  }

  static getColorByValue(rgbval: string): string {
    for (let [key, value] of ColorList.colors.entries()) {
      if (value === rgbval) {
        return key;
      }
    }
    return '';
  }

  private static convertToArray(): Array<ColorPair> {
    const res = new Array<ColorPair>();
    for (let [key, value] of this.colors.entries()) {
      res.push({ name: key, color: value });
    }
    return res;
  }

  private static padZero(str: string, len: number = 2) {
    var zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
  }
}
