import {  Extent } from '../Dimensions';
import { Shape } from './Shape';

export enum LINE_STYLE {
    Normal
}

export class LineShape extends Shape<LINE_STYLE> {
    static ShapeType = 'line';

    length: number;

    constructor(line?: LineShape) {
        super(line);
        this.shapeName = 'Line';
        this.style = LINE_STYLE.Normal;
        this.extent = new Extent(0, 0);
        this.length = 0;

        if (line) {
            this.style = line.style;
            this.length = line.length;
        }
    }

}
