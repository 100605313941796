import { Layer } from './Layer';
import { Media } from './Media';
import { Settings } from './Settings';
import { FormattedExpression } from './FormattedExpression';

export class LabelHeader {
  public id: string;
  public organisationID: string;
  public name: string;
  public description: string;
  public authorId: string;
  public created: Date;
  public lastUpdated: Date;
  public queryID: string;
  public addOns: Array<string>;
  public cultureCode: string;
  public mediaTemplateID: string;

  constructor(labelHeader?: LabelHeader) {
    this.update(labelHeader);
  }

  update(labelHeader: LabelHeader) {
    if (labelHeader) {
      this.id = labelHeader.id;
      this.organisationID = labelHeader.organisationID;
      this.name = labelHeader.name;
      this.description = labelHeader.description;
      this.authorId = labelHeader.authorId;
      this.created = labelHeader.created;
      this.lastUpdated = labelHeader.lastUpdated;
      this.queryID = labelHeader.queryID;
      this.addOns = labelHeader.addOns;
      this.cultureCode = labelHeader.cultureCode;
      this.mediaTemplateID = labelHeader.mediaTemplateID;
    }
  }

}

export class Label extends LabelHeader {
  get topLayer(): Layer {
    let top: Layer;
    if (!this.layers || this.layers.length === 0) {
      this.layers = new Array<Layer>();
      top = new Layer();
      this.layers.push(top);
    } else {
      top = this.layers[this.layers.length - 1];
    }
    return top;
  }

  public settings: Settings;
  public media: Media;
  public layers: Layer[];
  public variables: Array<FormattedExpression>;

  constructor(label?: Label) {
    super(label);
    this.update(label);
  }

  update(label: Label) {
    this.settings = new Settings();
    this.media = new Media();
    this.layers = new Array<Layer>();
    this.variables = new Array<FormattedExpression>();
    if (label) {
      super.update(label);
      this.settings = new Settings(label.settings);
      this.media = new Media(label.media);

      if (label.layers) {
        label.layers.forEach(layer => {
          this.layers.push(new Layer(layer));
        });
      }

      if (label.variables) {
        label.variables.forEach(v => {
          this.variables.push(new FormattedExpression(v));
        });
      }
    }
  }

}

export class LabelPreview extends LabelHeader {
  svg: string;
  queryName: string;
  mediaName: string;
}
