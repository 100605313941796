import {
  Directive,
  ElementRef,
  Optional,
  Self,
  OnInit,
  AfterViewInit,
  Input,
  OnChanges,
  SimpleChanges, inject
} from '@angular/core';
import {OrganisationService} from "./organisation.service";
import {AuthenticationService} from "../authentication/authentication.service";
import {of} from "rxjs";
import {switchMap} from "rxjs/operators";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatSelect} from "@angular/material/select";
import {ToolbarIconButtonComponent} from "../comps/toolbar-icon-button/toolbar-icon-button.component";
import {MatExpansionPanel} from "@angular/material/expansion";
import {MatChipListbox} from "@angular/material/chips";
import {UploadFileComponent} from "../comps/upload-file/upload-file.component";
import {Organisation} from "../models/server/Organisation";

@Directive({
    selector: '[activeorg]',
    standalone: true
})
export class OrgNotDeactivatedDirective implements AfterViewInit, OnChanges {


  // default disabled value
  @Input()
  disable: boolean = false;

  private org: Organisation = null;

  private orgService = inject(OrganisationService);
  private authService = inject(AuthenticationService);

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() private readonly input: HTMLInputElement,
    @Optional() @Self() private readonly button: MatButton,
    @Optional() @Self() private readonly appToolbarButton: ToolbarIconButtonComponent,
    @Optional() @Self() private readonly upload: UploadFileComponent,
    @Optional() @Self() private readonly iconButton: MatIconButton,
    @Optional() @Self() private readonly expPanel: MatExpansionPanel,
    @Optional() @Self() private readonly chipListbox: MatChipListbox,
    @Optional() @Self() private readonly select: MatSelect) {
  }

  ngAfterViewInit() {

    if (!this.org) {

      this.authService.isAuthenticated.pipe(
        switchMap(isAuthenticated => {
          if (isAuthenticated) {
            return this.orgService.getOrganisation();
          } else {
            return of(null);
          }
        })
      ).subscribe(org => {
        this.org = org;
        this.update();
      });
    } else {
      this.update();
    }
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes['disable'] && !changes['disable'].firstChange) {
      this.update();
    }
  }

  private update(): void {
    if (this.org) {
      let dis = this.org.isTrialExpired || this.org.isDeactivated || this.disable;

      if (this.button) {
        this.button.disabled = dis;
      } else if (this.iconButton) {
        this.iconButton.disabled = dis;
      } else if (this.select) {
        this.select.disabled = dis;
      } else if (this.appToolbarButton) {
        this.appToolbarButton.disabled = dis;
      } else if (this.upload) {
        this.upload.disabled = dis;
      } else if (this.expPanel) {
        this.expPanel.disabled = dis;
      } else if (this.chipListbox) {
        this.chipListbox.disabled = dis;
      } else if (this.input) {
        this.input.disabled = dis;
      } else if (
        this.elementRef.nativeElement &&
        "disabled" in this.elementRef.nativeElement
      ) {
        this.elementRef.nativeElement.disabled = dis;
      }
    }
  }
}
