import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { Organisation } from '../../models/server/Organisation';
import { SampleOrg } from '../../models/server/SampleOrg';
import { ErrorHandlerService } from '../../services/errorhandler.service';
import { LoadingService, LoadingState } from '../../services/loading.service';
import { SampleService } from '../../services/sample.service';
import { AsyncPipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexModule } from '@angular/flex-layout/flex';
import { DialogTitleComponent } from '../dialog-title/dialog-title.component';

@Component({
    selector: 'app-add-samples-dialog',
    templateUrl: './add-samples-dialog.component.html',
    styleUrls: ['./add-samples-dialog.component.scss'],
    standalone: true,
    imports: [DialogTitleComponent, MatDialogContent, FormsModule, ReactiveFormsModule, FlexModule, MatFormFieldModule, MatSelectModule, MatOptionModule, MatDialogActions, MatButtonModule, MatIconModule, MatDialogClose, AsyncPipe]
})
export class AddSamplesDialogComponent {

  form: FormGroup;
  samples: Observable<SampleOrg[]>;

  constructor(
    private sampleService: SampleService,
    private fb: FormBuilder,
    private loadingService: LoadingService,
    private errorHandler: ErrorHandlerService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public organisation: Organisation ) {
    this.createForm();
    this.samples = sampleService.getSamples();
  }

  goAhead(): void {

    let ls: LoadingState = null;
    let selectedSample = this.form.controls.sample.value as SampleOrg;

    ls = this.loadingService.add('app', `Adding Samples from ${selectedSample.title}...`);
    this.sampleService.addSamples(selectedSample.organisationId)
      .subscribe({
        next: res => {
          if (res)
            this.snackBar.open('Samples added successfully');
        },
        complete: () => {
          this.loadingService.remove(ls);
        },
        error: async err => {
          await this.errorHandler.handleErr(err);
        }
      });
  }


  private createForm(): void {
    this.form = this.fb.group({
      sample: new FormControl<SampleOrg>(null)
    });
  }
}
