import {Component, EventEmitter, Input, Output, OnInit} from '@angular/core';
import { MatMenu, MatMenuModule } from "@angular/material/menu";
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatIconModule } from '@angular/material/icon';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-toolbar-icon-button',
    templateUrl: './toolbar-icon-button.component.html',
    styleUrls: ['./toolbar-icon-button.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatMenuModule, FlexModule, MatIconModule, ExtendedModule]
})
export class ToolbarIconButtonComponent implements OnInit {

  @Input()
  title: string = '';

  @Input()
  caption: string;

  @Input()
  icon: string;

  @Input()
  iconIsSvg: boolean;

  @Output()
  clickHandler = new EventEmitter<any>();

  @Input()
  disabled: boolean;

  @Input()
  color: string = '';

  @Input()
  menu: MatMenu;

  @Input()
  raised = false;

  extraStyle: string = '';

  ngOnInit() {
    if (!this.caption) {
      this.extraStyle += 'width:42px;';
    }
  }


  handleClick($event: any): void {
    this.clickHandler.emit($event);
  }
}
